import { ReactNode } from 'react';

import styles from './index.module.scss';

import { Model } from '../model';

interface IProps {
  color: string;
  text: string;
  type: 'button' | 'submit' | 'reset';
  minWidth?: number;
  onClick?: () => void;
  children?: ReactNode;
}

export const Button = ({ color, text, type, minWidth, onClick, children }: IProps) => {
  const { isDisabled } = Model();

  return (
    <button
      className={styles['btn']}
      style={{
        backgroundColor: color,
      }}
      type={type}
      onClick={onClick}
      disabled={isDisabled}
    >
      {children && <div className={styles['btn__icon']}>{children}</div>}
      <p className={styles['btn__text']} style={{ minWidth: `${minWidth}px` }}>
        {text}
      </p>
    </button>
  );
};
