const IconNewspaper = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
    <path
      d='M6.5625 3.125C6.04297 3.125 5.625 3.54297 5.625 4.0625V15.9375C5.625 16.2656 5.57031 16.582 5.46484 16.875H17.1875C17.707 16.875 18.125 16.457 18.125 15.9375V4.0625C18.125 3.54297 17.707 3.125 17.1875 3.125H6.5625ZM2.8125 18.75C1.25781 18.75 0 17.4922 0 15.9375V4.375C0 3.85547 0.417969 3.4375 0.9375 3.4375C1.45703 3.4375 1.875 3.85547 1.875 4.375V15.9375C1.875 16.457 2.29297 16.875 2.8125 16.875C3.33203 16.875 3.75 16.457 3.75 15.9375V4.0625C3.75 2.50781 5.00781 1.25 6.5625 1.25H17.1875C18.7422 1.25 20 2.50781 20 4.0625V15.9375C20 17.4922 18.7422 18.75 17.1875 18.75H2.8125ZM6.875 5.3125C6.875 4.79297 7.29297 4.375 7.8125 4.375H11.5625C12.082 4.375 12.5 4.79297 12.5 5.3125V8.4375C12.5 8.95703 12.082 9.375 11.5625 9.375H7.8125C7.29297 9.375 6.875 8.95703 6.875 8.4375V5.3125ZM14.6875 4.375H15.9375C16.457 4.375 16.875 4.79297 16.875 5.3125C16.875 5.83203 16.457 6.25 15.9375 6.25H14.6875C14.168 6.25 13.75 5.83203 13.75 5.3125C13.75 4.79297 14.168 4.375 14.6875 4.375ZM14.6875 7.5H15.9375C16.457 7.5 16.875 7.91797 16.875 8.4375C16.875 8.95703 16.457 9.375 15.9375 9.375H14.6875C14.168 9.375 13.75 8.95703 13.75 8.4375C13.75 7.91797 14.168 7.5 14.6875 7.5ZM7.8125 10.625H15.9375C16.457 10.625 16.875 11.043 16.875 11.5625C16.875 12.082 16.457 12.5 15.9375 12.5H7.8125C7.29297 12.5 6.875 12.082 6.875 11.5625C6.875 11.043 7.29297 10.625 7.8125 10.625ZM7.8125 13.75H15.9375C16.457 13.75 16.875 14.168 16.875 14.6875C16.875 15.207 16.457 15.625 15.9375 15.625H7.8125C7.29297 15.625 6.875 15.207 6.875 14.6875C6.875 14.168 7.29297 13.75 7.8125 13.75Z'
      fill='inherit'
    />
  </svg>
);

export default IconNewspaper;
