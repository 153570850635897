import { ReactNode } from 'react';

import IconAngleSlice from '@shared/assets/icons/icon-angle-slice';

import styles from './index.module.scss';

import { Model } from '../model';

interface IProps {
  children: ReactNode;
}

export const SidebarMenu = ({ children }: IProps) => {
  const { color, isAuth } = Model();

  return (
    <div
      className={`${styles['menu']} ${isAuth && styles['menu_visible']}`}
      style={{ backgroundColor: color }}
    >
      {children}
      <div className={styles['menu__angle']} style={{ fill: color }}>
        <IconAngleSlice />
      </div>
    </div>
  );
};
