import { Transition } from 'react-transition-group';
import AnimateHeight from 'react-animate-height';

import { IOverviewInvoiceChartColumnProps } from '@shared/types/global';

import { monthList } from '@shared/utils/contants';

import styles from './index.module.scss';

import { Model } from '../model';

export const OverviewInvoicePeriodChartColumn = ({
  chartRef,
  data,
  maxSum,
}: IOverviewInvoiceChartColumnProps) => {
  const {
    columnRef,
    detailRef,
    isDetailVisible,
    detailPosition,
    height,
    columnPartList,
    handleDetailVisible,
  } = Model({
    chartRef,
    data,
    maxSum,
  });

  return (
    <div className={styles['item']}>
      <div className={styles['item__container']}>
        <Transition nodeRef={columnRef} in appear mountOnEnter unmountOnExit timeout={400}>
          {(state) => (
            <AnimateHeight
              ref={columnRef}
              height={columnRef.current ? height : 0}
              className={`${styles['item__column']} ${styles[`item__column-${state}`]} ${
                columnPartList.length && styles['item__column_cursor_pointer']
              }`}
              duration={400}
              delay={200}
              onClick={columnPartList.length ? handleDetailVisible : undefined}
            >
              {columnPartList.map((item, index) => (
                <div
                  key={index}
                  className={styles['item__column-part']}
                  style={{ ...item, height: `${item.height}px` }}
                ></div>
              ))}
            </AnimateHeight>
          )}
        </Transition>
        {columnPartList.length ? (
          <div
            ref={detailRef}
            className={`${styles['item__detail']} ${
              isDetailVisible && styles['item__detail_visible']
            }`}
            style={detailPosition}
          >
            {columnPartList.map((item, index) => (
              <div key={index} className={styles['item__detail-item']}>
                <div
                  className={styles['item__detail-circle']}
                  style={{ backgroundColor: item.backgroundColor }}
                ></div>
                <p className={styles['item__detail-sum']}>{item.sum}</p>
              </div>
            ))}
          </div>
        ) : null}
      </div>
      <p
        className={`${styles['item__title']} ${
          isDetailVisible && styles['item__title_color_black']
        } ${columnPartList.length && styles['item__title_cursor_pointer']}`}
        onClick={columnPartList.length ? handleDetailVisible : undefined}
      >
        {monthList[data.month]}
      </p>
    </div>
  );
};
