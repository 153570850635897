import { ReactNode } from 'react';
import { TransitionGroup } from 'react-transition-group';

import styles from './index.module.scss';

interface IProps {
  children: ReactNode;
}

export const NotificationContainer = ({ children }: IProps) => (
  <TransitionGroup className={styles['container']}>{children}</TransitionGroup>
);
