import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import MainEntity from '@entities/main';
import CopyRight from '@entities/copyright';

import { Model } from '../model';

interface IProps {
  children: ReactNode;
}

export const Main = ({ children }: IProps) => {
  const location = useLocation();

  const { isAuth } = Model();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location.pathname]);

  return (
    <MainEntity isFullScreen={!isAuth}>
      {children}
      <CopyRight />
    </MainEntity>
  );
};
