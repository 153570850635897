import { ReactNode } from 'react';

import CircularLoaderMui from '@shared/ui/circularLoaderMui';

import styles from './index.module.scss';

interface IProps {
  isLoading: boolean;
  children: ReactNode;
}

export const OverviewInvoicePeriodListSection = ({ isLoading, children }: IProps) => (
  <div className={styles['section']}>
    {children}
    <div
      className={`${styles['section__loader']} ${isLoading && styles['section__loader_visible']}`}
    >
      <CircularLoaderMui size={60} />
    </div>
  </div>
);
