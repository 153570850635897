const IconAngleSlice = () => (
  <svg viewBox='0 0 5 5' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M-0.000114918 1.43051e-05C-0.000122547 4.99992 -0.000114441 1.66893e-05 -0.00012207 5.00005C-0.00012207 2.23862 2.23846 0 4.99988 0C-0.000116348 1.43051e-05 -0.000114918 1.43051e-05 -0.000114918 1.43051e-05Z'
      fill='inherit'
    />
  </svg>
);

export default IconAngleSlice;
