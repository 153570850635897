import { ReactNode } from 'react';

import styles from './index.module.scss';

interface IProps {
  isBackground?: boolean;
  children: ReactNode;
}

export const section = ({ isBackground, children }: IProps) => (
  <section className={`${styles.section} ${isBackground && styles['section_type_background']}`}>
    {children}
  </section>
);
