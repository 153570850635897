import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface IProps {
  children: ReactNode;
}

const KeyboardApp = ({ children }: IProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    const clickEsc = (evt: KeyboardEvent) => {
      if (evt.code === 'Escape') {
        navigate(-1);
      }
    };

    window.addEventListener('keydown', clickEsc);

    return () => {
      window.removeEventListener('keydown', clickEsc);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
};

export default KeyboardApp;
