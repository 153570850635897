import { useMemo, useState } from 'react';
import Decimal from 'decimal.js';

import { IInvoicePeriodIListItem } from '@shared/types/interfaces/invoice.interface';

interface IYearPeriod {
  year: number;
  data: {
    month: number;
    data: IInvoicePeriodIListItem['data'];
  }[];
}

interface IProps {
  periodList: IInvoicePeriodIListItem[];
}

export const Model = ({ periodList }: IProps) => {
  const [isDetailVisible, setIsDetailVisible] = useState(false);

  const handleIsDatailVisible = () => {
    setIsDetailVisible(!isDetailVisible);
  };

  const [data, maxSum, details]: [IYearPeriod[], number, string[]] = useMemo(() => {
    const result: IYearPeriod[] = [];
    let maxSum = new Decimal(0);

    periodList.forEach((item) => {
      const date = item.date.split('-');
      let sum = new Decimal(0);

      const year = Number(date[0]);
      const month = Number(date[1]);

      const yearPeriod = result.findIndex((item) => Number(item.year) === year);

      item.data.forEach((item) => {
        sum = sum.plus(item.sum);
      });

      if (maxSum.lessThan(sum)) {
        maxSum = sum;
      }

      if (yearPeriod !== -1) {
        result[yearPeriod].data.push({ month, data: item.data });

        return;
      }

      result.push({ year, data: [{ month, data: item.data }] });
    });

    const step = maxSum.dividedBy(4);

    const details = [
      maxSum,
      maxSum.minus(step),
      maxSum.minus(step.mul(2)),
      maxSum.minus(step.mul(3)),
    ].map((item) =>
      new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        minimumFractionDigits: 2,
        currency: 'RUB',
      })
        .format(item.toNumber())
        .toString(),
    );

    return [
      result
        .map((item) => Object({ ...item, data: item.data.sort((a, b) => a.month - b.month) }))
        .sort((a, b) => a.year - b.year),
      maxSum.toNumber(),
      details,
    ];
  }, [periodList]);

  return {
    isDetailVisible,
    data,
    maxSum,
    details,
    handleIsDatailVisible,
  };
};
