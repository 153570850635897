const IconContractors = () => (
  <svg viewBox='0 0 22 22' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.83331 19.25V2.75H10.7708V6.53125H20.1666V19.25H1.83331ZM3.20831 17.875H5.61456V15.4688H3.20831V17.875ZM3.20831 14.0938H5.61456V11.6875H3.20831V14.0938ZM3.20831 10.3125H5.61456V7.90625H3.20831V10.3125ZM3.20831 6.53125H5.61456V4.125H3.20831V6.53125ZM6.98956 17.875H9.39581V15.4688H6.98956V17.875ZM6.98956 14.0938H9.39581V11.6875H6.98956V14.0938ZM6.98956 10.3125H9.39581V7.90625H6.98956V10.3125ZM6.98956 6.53125H9.39581V4.125H6.98956V6.53125ZM10.7708 17.875H18.7916V7.90625H10.7708V10.3125H12.6041V11.6875H10.7708V14.0938H12.6041V15.4688H10.7708V17.875ZM15.0104 11.6875V10.3125H16.3854V11.6875H15.0104ZM15.0104 15.4688V14.0938H16.3854V15.4688H15.0104Z'
      fill='inherit'
    />
  </svg>
);

export default IconContractors;
