import styles from './index.module.scss';

interface IProps {
  backgroundColor: string;
  logoUrl: string | null;
  onClick: () => void;
}

export const HeaderLogoBtn = ({ backgroundColor, logoUrl, onClick }: IProps) => (
  <button
    className={styles['btn']}
    type='button'
    onClick={onClick}
    style={{ background: backgroundColor }}
  >
    <img className={styles['btn__img']} src={logoUrl || ''} alt='Логотип' crossOrigin='anonymous' />
  </button>
);
