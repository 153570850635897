import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Decimal from 'decimal.js';

import { IInvoiceDept } from '@shared/types/interfaces/invoice.interface';

interface IDetail extends Omit<IInvoiceDept, 'dept'> {
  dept: string;
}

interface IProps {
  data: IInvoiceDept[];
}

export const Model = ({ data }: IProps) => {
  const [isDetailVisible, setIsDetailVisible] = useState<boolean>(false);

  const detailRef = useRef<HTMLDivElement | null>(null);

  const dept = useMemo(() => {
    let sum: Decimal = new Decimal(0);

    data.forEach((item) => {
      sum = sum.plus(item.dept);
    });

    if (sum.isZero()) {
      return null;
    }

    return new Intl.NumberFormat('ru-RU', {
      style: 'currency',
      minimumFractionDigits: 2,
      currency: 'RUB',
    })
      .format(sum.toNumber())
      .toString();
  }, [data]);

  const detail: IDetail[] = useMemo(
    () =>
      data
        .filter((item) => item.dept && (item._id === 1 || item._id === 2))
        .map((item) =>
          Object({
            _id: item._id,
            dept: new Intl.NumberFormat('ru-RU', {
              style: 'currency',
              minimumFractionDigits: 2,
              currency: 'RUB',
            })
              .format(item.dept)
              .toString(),
          }),
        )
        .sort((a, b) => a._id - b._id),
    [data],
  );

  const handleDetailVisible = () => {
    setIsDetailVisible(true);
  };

  const handleCloseOutside = useCallback((evt: MouseEvent) => {
    if (!detailRef.current?.contains(evt.target as Node)) {
      setIsDetailVisible(false);
    }
  }, []);

  useEffect(() => {
    if (isDetailVisible) {
      document.addEventListener('click', handleCloseOutside, true);
    } else {
      document.removeEventListener('click', handleCloseOutside, true);
    }

    return () => {
      document.removeEventListener('click', handleCloseOutside, true);
    };
  }, [isDetailVisible, handleCloseOutside]);

  return {
    isDetailVisible,
    detailRef,
    dept,
    detail,
    handleDetailVisible,
  };
};
