import { LocalizationProvider, DateCalendar, ruRU } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DateTime } from 'luxon';

import colors from 'shared/assets/colors';

interface IProps {
  value: string;
  onClick: (data: string) => void;
}

export const CalendarMui = ({ value, onClick }: IProps) => {
  const theme = createTheme(
    {
      palette: {
        primary: {
          main: colors.purple,
          contrastText: colors.white,
        },
        secondary: {
          main: colors.purple,
          contrastText: colors.white,
        },
      },
    },
    ruRU,
  );

  const onChange = (data: DateTime | null) => {
    const date = data?.toISO();
    onClick(date || '');
  };

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='ru'>
      <ThemeProvider theme={theme}>
        <DateCalendar
          showDaysOutsideCurrentMonth
          value={value ? DateTime.fromISO(value) : null}
          onChange={onChange}
        />
      </ThemeProvider>
    </LocalizationProvider>
  );
};
