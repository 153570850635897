import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import server from '@shared/utils/server';

import { handleApiError } from '@shared/helpers';

import { AxiosResponse } from 'axios';
import { TSearchParams, TApiError } from '@shared/types/global';
import {
  IInvoiceDept,
  IInvoicePeriodIListItem,
  IInvoicePeriodListFilter,
} from '@shared/types/interfaces/invoice.interface';

interface IInvoicePeriodListSlider {
  step: number;
  isBlock: {
    left: boolean;
    right: boolean;
  };
  limit: undefined | number;
}

interface IInvoicePeriodList {
  data: IInvoiceDept[];
  periodList: IInvoicePeriodIListItem[];
  filter: IInvoicePeriodListFilter;
  slider: IInvoicePeriodListSlider;
  isLoading: boolean;
  isLoadingPeriod: boolean;
  error: TApiError | null;
}

type TInvoicePeriodListDataResponse = [
  AxiosResponse<IInvoicePeriodList['data']>,
  AxiosResponse<IInvoicePeriodList['periodList']>,
];

type TInvoicePeriodListPeriodResponse = AxiosResponse<IInvoicePeriodList['periodList']>;

export const initialInvoicePeriodListFilter: IInvoicePeriodListFilter = {
  contractor: { _id: '', name: '' },
  startDateAt: '',
  endDateAt: '',
  limit: 0,
};

const initialState: IInvoicePeriodList = {
  data: [],
  periodList: [],
  filter: initialInvoicePeriodListFilter,
  slider: {
    step: 0,
    isBlock: {
      left: true,
      right: true,
    },
    limit: undefined,
  },
  isLoading: true,
  isLoadingPeriod: false,
  error: null,
};

export const getInvoicePeriodListData = createAsyncThunk<
  TInvoicePeriodListDataResponse,
  TSearchParams,
  { rejectValue: TApiError }
>('invoicePeriodList/getInvoicePeriodListDate', async (params, { rejectWithValue }) => {
  try {
    const getData = server.get('/invoice/dept');
    const getPeriodList = server.get('/invoice/period/list', { params });

    const res = await Promise.all([getData, getPeriodList]);

    return res;
  } catch (err) {
    return rejectWithValue(handleApiError(err));
  }
});

export const getInvoicePeriodListPeriod = createAsyncThunk<
  TInvoicePeriodListPeriodResponse,
  TSearchParams,
  { rejectValue: TApiError }
>('invoicePeriodList/getInvoicePeriodListPeriod', async (params, { rejectWithValue }) => {
  try {
    const res = await server.get('/invoice/period/list', { params });

    return res;
  } catch (err) {
    return rejectWithValue(handleApiError(err));
  }
});

const invoicePeriodListAdapter = createEntityAdapter();

const invoicePeriodListSlice = createSlice({
  name: 'invoicePeriodList',
  initialState: invoicePeriodListAdapter.getInitialState<IInvoicePeriodList>(initialState),
  reducers: {
    changeFilter: (state, action: PayloadAction<IInvoicePeriodList['filter']>) => {
      state.filter = { ...state.filter, ...action.payload };
    },
    resetFilter: (state) => {
      state.filter = initialInvoicePeriodListFilter;
    },
    changeSlider: (state, action: PayloadAction<IInvoicePeriodList['slider']>) => {
      state.slider = { ...state.slider, ...action.payload };
    },
    resetPeriodListData: (state) => {
      state.data = initialState.data;
      state.periodList = initialState.periodList;
      state.filter = initialState.filter;
      state.slider = initialState.slider;
      state.isLoading = initialState.isLoading;
      state.isLoadingPeriod = initialState.isLoadingPeriod;
      state.error = initialState.error;
    },
    resetPeriodList: (state) => {
      state.periodList = [];
      state.slider = {
        step: 0,
        isBlock: {
          left: true,
          right: true,
        },
        limit: undefined,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInvoicePeriodListData.pending, (state) => {
        state.slider = {
          step: 0,
          isBlock: {
            left: true,
            right: true,
          },
          limit: undefined,
        };
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getInvoicePeriodListData.fulfilled, (state, action) => {
        const [data, periodList] = action.payload;

        state.isLoading = false;
        state.data = data.data;
        state.periodList = periodList.data;

        if (periodList.data.length < state.filter.limit) {
          state.slider = {
            step: periodList.data.length,
            isBlock: {
              left: true,
              right: true,
            },
            limit: periodList.data.length,
          };
        } else {
          state.slider = {
            step: periodList.data.length,
            isBlock: {
              left: false,
              right: true,
            },
            limit: undefined,
          };
        }
      })
      .addCase(getInvoicePeriodListData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload ?? null;
      })
      .addCase(getInvoicePeriodListPeriod.pending, (state) => {
        state.isLoadingPeriod = true;
        state.error = null;

        state.slider = {
          ...state.slider,
          isBlock: {
            left: true,
            right: true,
          },
        };
      })
      .addCase(getInvoicePeriodListPeriod.fulfilled, (state, action) => {
        const periodList = action.payload.data;

        state.isLoadingPeriod = false;
        state.periodList = [...state.periodList, ...periodList];

        if (periodList.length < state.filter.limit) {
          state.slider = {
            step: state.slider.step + periodList.length,
            isBlock: {
              left: true,
              right: state.periodList.length <= state.filter.limit,
            },
            limit: state.slider.step + periodList.length,
          };
        } else {
          state.slider = {
            step: state.slider.step + periodList.length,
            isBlock: {
              left: false,
              right: state.periodList.length <= state.filter.limit,
            },
            limit: undefined,
          };
        }
      })
      .addCase(getInvoicePeriodListPeriod.rejected, (state, action) => {
        state.isLoadingPeriod = false;
        state.error = action.payload ?? null;
      });
  },
});

export const invoicePeriodListSelector = {
  getState: (state: { invoicePeriodList: IInvoicePeriodList }) => state.invoicePeriodList,
  getData: (state: { invoicePeriodList: IInvoicePeriodList }) => state.invoicePeriodList.data,
  getFilter: (state: { invoicePeriodList: IInvoicePeriodList }) => state.invoicePeriodList.filter,
  getSlider: (state: { invoicePeriodList: IInvoicePeriodList }) => state.invoicePeriodList.slider,
};

export const { changeFilter, resetFilter, changeSlider, resetPeriodListData, resetPeriodList } =
  invoicePeriodListSlice.actions;

export type {
  TInvoicePeriodListDataResponse,
  TInvoicePeriodListPeriodResponse,
  IInvoicePeriodListSlider,
};

export default invoicePeriodListSlice.reducer;
