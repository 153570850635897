import { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TThunkDispatch } from '@shared/store';

import { checkToken, userSelector } from '@shared/store/slices/userSlice';

interface IProps {
  children: ReactNode;
}

const TokenApp = ({ children }: IProps) => {
  const dispatch = useDispatch<TThunkDispatch>();

  const { isAuth } = useSelector(userSelector.getState);

  useEffect(() => {
    dispatch(checkToken());
  }, [dispatch]);

  return isAuth !== undefined ? children : null;
};

export default TokenApp;
