import { useSelector } from 'react-redux';

import { userSelector } from '@shared/store/slices/userSlice';

const gridTemplateColumnsDefault: string =
  'minmax(150px, 1fr) minmax(130px, 160px) minmax(100px, 120px) 150px 150px';

const gridTemplateColumnsRenter: string = 'minmax(160px, 1fr) minmax(100px, 150px) 150px 150px';

export const Model = () => {
  const roleCode = useSelector(userSelector.getRoleCode);

  const getGridTemplateColumns = () =>
    roleCode !== 'renter' ? gridTemplateColumnsDefault : gridTemplateColumnsRenter;

  return {
    gridTemplateColumns: getGridTemplateColumns(),
  };
};
