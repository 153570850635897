const IconAuxiliary = () => (
  <svg viewBox='0 0 20 16' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_159_172)'>
      <path
        d='M1.15 6H18.85C19.4844 6 20 5.48438 20 4.85C20 4.62187 19.9312 4.4 19.8062 4.2125L17.4438 0.66875C17.1656 0.25 16.7 0 16.1969 0H3.80312C3.30312 0 2.83438 0.25 2.55625 0.66875L0.19375 4.20937C0.06875 4.4 0 4.62188 0 4.84688C0 5.48438 0.515625 6 1.15 6ZM2 7V12V14.5C2 15.3281 2.67188 16 3.5 16H10.5C11.3281 16 12 15.3281 12 14.5V12V7H10V12H4V7H2ZM16 7V15C16 15.5531 16.4469 16 17 16C17.5531 16 18 15.5531 18 15V7H16Z'
        fill='inherit'
      />
    </g>
    <defs>
      <clipPath id='clip0_159_172'>
        <rect width='20' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconAuxiliary;
