import Btn from '@entities/header/logoBtn';

import { Model } from '../model';

export const LinkLogo = () => {
  const { logoUrl, logoBackgroundColor, navigateToMainPage } = Model();

  return (
    <Btn backgroundColor={logoBackgroundColor} logoUrl={logoUrl} onClick={navigateToMainPage} />
  );
};
