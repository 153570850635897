import { ReactNode } from 'react';

import styles from './index.module.scss';

interface IProps {
  children: ReactNode;
}

export const OverviewInvoicePeriodHeader = ({ children }: IProps) => (
  <div className={styles['header']}>{children}</div>
);
