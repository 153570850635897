const IconHouse = () => (
  <svg viewBox='0 0 20 16' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_598_5464)'>
      <path
        d='M18.8819 8.9875C19.4722 8.9875 19.9931 8.55 19.9931 7.98438C20.0278 7.70312 19.8889 7.45312 19.6111 7.23438L17.7778 5.78125V2C17.7778 1.44687 17.2813 1 16.6667 1H15.5556C14.941 1 14.4444 1.44687 14.4444 2V3.14687L10.7465 0.21875C10.5382 0.0625 10.2604 0 10.0174 0C9.77431 0 9.49653 0.03125 9.25347 0.25L0.347222 7.23438C0.104167 7.45312 0 7.70312 0 7.98438C0 8.54688 0.486111 8.9875 1.11111 8.9875H2.22222V11.1656C2.21875 11.1937 2.21875 11.2219 2.21875 11.2531V14.75C2.21875 15.4406 2.84028 16 3.60764 16H4.16319C4.20486 16 4.24653 15.9969 4.28819 15.9937C4.34028 15.9969 4.39236 16 4.44444 16H5.55556H6.38889C7.15625 16 7.77778 15.4406 7.77778 14.75V14V12C7.77778 11.4469 8.27431 11 8.88889 11H11.1111C11.7257 11 12.2222 11.4469 12.2222 12V14V14.75C12.2222 15.4406 12.8438 16 13.6111 16H14.4444H15.5729C15.6215 16 15.6701 16 15.7188 15.9969C15.7569 16 15.7951 16 15.8333 16H16.3889C17.1563 16 17.7778 15.4406 17.7778 14.75V14.2437C17.7882 14.1625 17.7951 14.0781 17.7951 13.9906L17.7708 8.98438H18.8819V8.9875Z'
        fill='inherit'
      />
    </g>
    <defs>
      <clipPath id='clip0_598_5464'>
        <rect width='20' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconHouse;
