import { useSelector } from 'react-redux';

import { userSelector } from '@shared/store/slices/userSlice';

export const Model = () => {
  const contractor = useSelector(userSelector.getContractor);

  const contractorId = contractor?._id || '';

  const urlList = {
    placementListUrl: `/placement-list?contractor=${contractorId}&isFree=true&page=1&count=20`,
    appealAnsweredListUrl: `/appeal-list?contractor=${contractorId}&status=2&page=1&count=20`,
    appealUnansweredListUrl: `/appeal-list?contractor=${contractorId}&status=1&page=1&count=20`,
    invoiceListUrl: `/invoice-list?contractor=${contractorId}&status=1&page=1&count=20`,
  };

  return {
    urlList,
  };
};
