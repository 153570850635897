import { TQueryParam } from '@shared/types/global';

type TBooleanStatusList = ({
  trueValue,
  falseValue,
}: {
  trueValue: string;
  falseValue: string;
}) => {
  list: {
    _id: string;
    name: string;
  }[];
  getStatusQuery: (query: TQueryParam | undefined) => { _id: string; name: string };
};

export const booleanStatusList: TBooleanStatusList = ({ trueValue, falseValue }) => {
  const list = [
    { _id: 'true', name: trueValue },
    {
      _id: 'false',
      name: falseValue,
    },
  ];

  const getStatusQuery = (query: TQueryParam | undefined) => {
    if (query === 'true') {
      return list[0];
    }

    if (query === 'false') {
      return list[1];
    }

    return { _id: '', name: '' };
  };

  return {
    list,
    getStatusQuery,
  };
};
