import { UseFormRegisterReturn } from 'react-hook-form';

import colors from '@shared/assets/colors';

import styles from './index.module.scss';

import { Model } from '../model';

interface IProps {
  id: string;
  register: UseFormRegisterReturn;
  minLength?: number;
  maxLength?: number;
  isError?: boolean;
}

export const AuthenticationPassword = ({ id, register, minLength, maxLength, isError }: IProps) => {
  const { borderColor, isHidden, handleChangeIsHidden } = Model();

  return (
    <div className={styles['password']}>
      <input
        className={styles['password__input']}
        type={isHidden ? 'password' : 'text'}
        id={id}
        {...register}
        placeholder='Введите пароль'
        style={{ borderColor: !isError ? borderColor : colors.red }}
        minLength={minLength}
        maxLength={maxLength}
      ></input>
      <button
        className={`${styles['password__show-btn']} ${
          isHidden && styles['password__show-btn_visible']
        }`}
        type='button'
        onClick={handleChangeIsHidden}
      ></button>
    </div>
  );
};
