import { io } from 'socket.io-client';

import { serverLocalUrl } from './contants';

const isProduction = process.env.NODE_ENV === 'production';

const socket = io(isProduction ? '/' : serverLocalUrl, {
  withCredentials: true,
  autoConnect: false,
  transports: ['websocket'],
  secure: true,
  reconnectionDelay: 5000,
  reconnectionDelayMax: 10000,
});

export default socket;
