import { RefObject } from 'react';

import IconProfile from '@shared/assets/icons/icon-profile';
import IconProfieLink from '@shared/assets/icons/icon-profile-link';
import IconNewspaper from '@shared/assets/icons/icon-newspapar';
import IconExit from '@shared/assets/icons/icon-exit-profile';

import styles from './index.module.scss';

import { Model } from '../model';

interface IProps {
  refCard: RefObject<HTMLDivElement>;
  isOpen: boolean;
  changeIsOpen: () => void;
  onExit: () => void;
}

export const HeaderProfileCard = ({ refCard, isOpen, changeIsOpen, onExit }: IProps) => {
  const {
    name,
    position,
    iconColor,
    isShowContractorBtn,
    navigateToProfilePage,
    navigateToContractor,
  } = Model({ changeIsOpen });

  return (
    <div ref={refCard} className={`${styles['card']} ${isOpen && styles['card_visible']}`}>
      <div className={styles['card__header']}>
        <div className={styles['card__icon']} style={{ fill: iconColor }}>
          <IconProfile />
        </div>
        <div className={styles['card__info']}>
          <p className={styles['card__name']} title={name}>
            {name}
          </p>
          {position ? (
            <p className={styles['card__name']} title={position}>
              {position}
            </p>
          ) : null}
        </div>
      </div>
      <div className={styles['card__menu']}>
        <button className={styles['card__btn']} type='button' onClick={navigateToProfilePage}>
          <div className={styles['card__menu-icon']}>
            <IconProfieLink />
          </div>
          <p className={styles['card__menu-text']}>Профиль</p>
        </button>
        {isShowContractorBtn && (
          <button className={styles['card__btn']} type='button' onClick={navigateToContractor}>
            <div className={styles['card__menu-icon']}>
              <IconNewspaper />
            </div>
            <p className={styles['card__menu-text']}>Компания</p>
          </button>
        )}
        <button className={styles['card__btn']} type='button' onClick={onExit}>
          <div className={styles['card__menu-icon']}>
            <IconExit />
          </div>
          <p className={styles['card__menu-text']}>Выход</p>
        </button>
      </div>
    </div>
  );
};
