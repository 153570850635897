import { ComponentType, MutableRefObject } from 'react';

import CircularLoaderMui from '@shared/ui/circularLoaderMui';

import { IOverviewInvoiceChartColumnProps } from '@shared/types/global';
import { IInvoicePeriodIListItem } from '@shared/types/interfaces/invoice.interface';

import styles from './index.module.scss';

import { Model } from '../model';

interface IProps {
  chartRef: MutableRefObject<HTMLDivElement | null>;
  isLoading: boolean;
  periodList: IInvoicePeriodIListItem[];
  translateStep: number;
  ChartColumn: ComponentType<IOverviewInvoiceChartColumnProps>;
}

export const OverviewInvoicePeriodChart = ({
  chartRef,
  isLoading,
  periodList,
  translateStep,
  ChartColumn,
}: IProps) => {
  const { isDetailVisible, data, maxSum, details, handleIsDatailVisible } = Model({
    periodList,
  });

  return (
    <div ref={chartRef} className={styles['chart']}>
      <div className={styles['chart__background']}>
        <div className={styles['chart__line']}></div>
        <div className={styles['chart__line']}></div>
        <div className={styles['chart__line']}></div>
        <div className={styles['chart__line']}></div>
        <div className={styles['chart__line']}></div>
        <div className={styles['chart__line']}></div>
      </div>
      <div className={styles['chart__slider']}>
        <div
          className={styles['chart__slider-road']}
          style={{ transform: `translateX(calc(-100% + ${translateStep * 120}px))` }}
        >
          {data.map((item, index) => (
            <div key={index} className={styles['chart__group']}>
              <div className={styles['chart__group-header']}>
                <p className={styles['chart__group-title']}>{item.year}</p>
              </div>
              <div className={styles['chart__group-body']}>
                {item.data.map((monthItem, index) => (
                  <ChartColumn key={index} chartRef={chartRef} data={monthItem} maxSum={maxSum} />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <button type='button' className={styles['chart__btn-legend']} onClick={handleIsDatailVisible}>
        ₽
      </button>
      <div
        className={`${styles['chart__details']} ${
          isDetailVisible && styles['chart__details_visible']
        }`}
      >
        {details.map((item, index) => (
          <p key={index} className={styles['chart__detail']}>
            {item}
          </p>
        ))}
      </div>
      <div className={`${styles['chart__loader']} ${isLoading && styles['chart__loader_load']}`}>
        <CircularLoaderMui size={50} />
      </div>
      <div
        className={`${styles['chart__plug']} ${
          !periodList.length && styles['chart__plug_visible']
        }`}
      >
        <p className={styles['chart__plug-title']}>Данные по счётам отсутствуют</p>
      </div>
    </div>
  );
};
