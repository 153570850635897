import styles from './index.module.scss';

interface IProps {
  id: string;
  title: string;
}

export const AuthenticationLabel = ({ id, title }: IProps) => (
  <label className={styles['label']} htmlFor={id}>
    {title}
  </label>
);
