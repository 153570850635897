import { useSelector } from 'react-redux';

import { userSelector } from '@shared/store/slices/userSlice';

interface IProps {
  sum: number;
}

const gridTemplateColumnsDefault: string =
  'minmax(150px, 1fr) minmax(130px, 160px) minmax(100px, 120px) 150px 150px';

const gridTemplateColumnsRenter: string = 'minmax(160px, 1fr) minmax(100px, 150px) 150px 150px';

export const Model = ({ sum }: IProps) => {
  const roleCode = useSelector(userSelector.getRoleCode);

  const getCurrency = () => {
    if (!sum) {
      return '-';
    }

    return new Intl.NumberFormat('ru-RU', {
      style: 'currency',
      minimumFractionDigits: 2,
      currency: 'RUB',
    })
      .format(sum)
      .toString();
  };

  const getGridTemplateColumns = () =>
    roleCode !== 'renter' ? gridTemplateColumnsDefault : gridTemplateColumnsRenter;

  return {
    currency: getCurrency(),
    gridTemplateColumns: getGridTemplateColumns(),
  };
};
