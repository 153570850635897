import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { configAppSelector } from '@shared/store/slices/configAppSlice';

import { serverUrl } from '@shared/utils/server';

export const Model = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const configApp = useSelector(configAppSelector.getConfig);

  const navigateToMainPage = () => {
    if (location.pathname !== '/') {
      navigate('/');
    }
  };

  return {
    logoUrl: configApp.logo ? serverUrl + configApp.logo.url : '',
    logoBackgroundColor: configApp.logoBackgroundColor,
    navigateToMainPage,
  };
};
