import IconMenu from '@shared/assets/icons/icon-menu';

import { IInvoiceDept } from '@shared/types/interfaces/invoice.interface';

import styles from './index.module.scss';

import { Model } from '../model';

interface IProps {
  data: IInvoiceDept[];
}

export const OverviewInvoicePeriodDept = ({ data }: IProps) => {
  const { isDetailVisible, detailRef, dept, detail, handleDetailVisible } = Model({ data });

  return (
    <div className={styles['dept']}>
      {dept ? (
        <div className={styles['dept__positive']}>
          <button type='button' className={styles['dept__btn']} onClick={handleDetailVisible}>
            <div className={styles['dept__icon']}>
              <IconMenu />
            </div>
            <p className={styles['dept__btn-text']}>
              Общая задолженность: <span className={styles['dept__span-sum']}>{dept}</span>
            </p>
          </button>
          <div
            ref={detailRef}
            className={`${styles['dept__frame']} ${
              isDetailVisible && styles['dept__frame_visible']
            }`}
          >
            {detail.map((item) => (
              <div
                key={item._id}
                className={`${styles['dept__detail']} ${
                  item._id !== 1 && styles['dept__detail_background_orange']
                }`}
              >
                <p className={styles['dept__title']}>
                  {item._id === 1 ? 'Неоплаченные счёта' : 'Неполностью оплаченные счёта'}
                </p>
                <p className={styles['dept__subtitle']}>{item.dept}</p>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <p className={styles['dept__negative']}>Все счёта оплачены!</p>
      )}
    </div>
  );
};
