const IconShop = () => (
  <svg viewBox='0 0 20 16' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_159_227)'>
      <path
        d='M19.0139 3.24375L17.0243 0.409375C16.8472 0.15625 16.5313 0 16.1945 0H3.80557C3.46877 0 3.1528 0.15625 2.97571 0.409375L0.982657 3.24375C-0.0451208 4.70625 0.864601 6.74062 2.78474 6.975C2.92363 6.99062 3.06599 7 3.20488 7C4.11113 7 4.91669 6.64375 5.46877 6.09375C6.02085 6.64375 6.82641 7 7.73266 7C8.63891 7 9.44446 6.64375 9.99655 6.09375C10.5486 6.64375 11.3542 7 12.2604 7C13.1702 7 13.9722 6.64375 14.5243 6.09375C15.0799 6.64375 15.882 7 16.7882 7C16.9306 7 17.0695 6.99062 17.2084 6.975C19.1354 6.74375 20.0486 4.70938 19.0174 3.24375H19.0139ZM17.3507 7.96562H17.3472C17.1632 7.9875 16.9757 8 16.7847 8C16.3542 8 15.941 7.94062 15.5556 7.83437V12H4.44446V7.83125C4.05557 7.94063 3.63891 8 3.20835 8C3.01738 8 2.82641 7.9875 2.64238 7.96562H2.63891C2.49655 7.94687 2.35766 7.925 2.22224 7.89375V12V14C2.22224 15.1031 3.21877 16 4.44446 16H15.5556C16.7813 16 17.7778 15.1031 17.7778 14V12V7.89375C17.6389 7.925 17.5 7.95 17.3507 7.96562Z'
        fill='inherit'
      />
    </g>
    <defs>
      <clipPath id='clip0_159_227'>
        <rect width='20' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconShop;
