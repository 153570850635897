import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import server from 'shared/utils/server';

import { handleApiError } from '@shared/helpers';

import { AxiosResponse } from 'axios';
import { IStatus, IStatusText, TApiError } from '@shared/types/global';

interface ISendingAncillary {
  typeList: IStatusText[];
  methodList: IStatusText[];
  statusList: IStatus[];
  isLoading: boolean;
  error: TApiError | null;
}

const initialState: ISendingAncillary = {
  typeList: [],
  methodList: [],
  statusList: [],
  isLoading: false,
  error: null,
};

export const getSendingAncillary = createAsyncThunk<
  AxiosResponse<Pick<ISendingAncillary, 'typeList' | 'methodList' | 'statusList'>>,
  undefined,
  { rejectValue: TApiError }
>('sendingAncillary/getSendingAncillary', async (params, { rejectWithValue }) => {
  try {
    const res = await server.get<Pick<ISendingAncillary, 'typeList' | 'methodList' | 'statusList'>>(
      '/sending/ancillary',
      {
        params,
      },
    );
    return res;
  } catch (err) {
    return rejectWithValue(handleApiError(err));
  }
});

const sendingAncillaryAdapter = createEntityAdapter();

const sendingAncillarySlice = createSlice({
  name: 'sendingAncillary',
  initialState: sendingAncillaryAdapter.getInitialState<ISendingAncillary>(initialState),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSendingAncillary.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getSendingAncillary.fulfilled, (state, action) => {
        const { typeList, methodList, statusList } = action.payload.data;

        state.isLoading = false;
        state.typeList = typeList;
        state.methodList = methodList;
        state.statusList = statusList;
      })
      .addCase(getSendingAncillary.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload ?? null;
      });
  },
});

export const sendingAncillarySelector = {
  getState: (state: { sendingAncillary: ISendingAncillary }) => state.sendingAncillary,
};

export default sendingAncillarySlice.reducer;
