interface IProps {
  type: 'required' | 'minLenght' | 'maxLenght' | 'text' | 'number' | 'phone';
  minLenght?: number;
  maxLenght?: number;
}

type TGetInputError = ({ type, minLenght, maxLenght }: IProps) => string;

export const getInputError: TGetInputError = ({ type, minLenght, maxLenght }) => {
  const validationError = {
    required: 'Поле обязательно к заполнению.',
    minLenght: `Минимальное количество символов ${minLenght}.`,
    maxLenght: `Максимальное количество символов ${maxLenght}.`,
    text: 'Допустимые символы (A-z, А-я, _).',
    number: 'Поле может содержать только цифры.',
    phone: 'Допустимые символы (0-9, -, +).',
  };

  return validationError[type] || '';
};
