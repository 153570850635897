import { Transition } from 'react-transition-group';
import { useRef } from 'react';
import AnimateHeight from 'react-animate-height';

import { TApiError } from '@shared/types/global';

import styles from './index.module.scss';

interface IProps {
  error: TApiError | null;
}

export const ApiError = ({ error }: IProps) => {
  const errorRef = useRef<HTMLParagraphElement | null>(null);

  return (
    <Transition nodeRef={errorRef} appear in={!!error} timeout={400}>
      {(state) => (
        <AnimateHeight height={error !== null ? 'auto' : 0}>
          <p ref={errorRef} className={`${styles['error']} ${styles[`error-${state}`]}`}>
            {error?.message}
          </p>
        </AnimateHeight>
      )}
    </Transition>
  );
};
