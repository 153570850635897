import { useSelector } from 'react-redux';

import { userSelector } from '@shared/store/slices/userSlice';

export const Model = () => {
  const { isAuth } = useSelector(userSelector.getState);

  return {
    isAuth,
  };
};
