const IconShelf = () => (
  <svg viewBox='0 0 20 16' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_159_213)'>
      <path
        d='M0 15.25V5.35317C0 4.53442 0.496875 3.80005 1.25625 3.49692L9.62813 0.150049C9.86563 0.0531738 10.1312 0.0531738 10.3719 0.150049L18.7437 3.49692C19.5031 3.80005 20 4.53755 20 5.35317V15.25C20 15.6657 19.6656 16 19.25 16H17.75C17.3344 16 17 15.6657 17 15.25V7.00005C17 6.44692 16.5531 6.00005 16 6.00005H4C3.44688 6.00005 3 6.44692 3 7.00005V15.25C3 15.6657 2.66563 16 2.25 16H0.75C0.334375 16 0 15.6657 0 15.25ZM15.25 16H4.75C4.33437 16 4 15.6657 4 15.25V13.5H16V15.25C16 15.6657 15.6656 16 15.25 16ZM4 12.5V10.5H16V12.5H4ZM4 9.50005V7.00005H16V9.50005H4Z'
        fill='inherit'
      />
    </g>
    <defs>
      <clipPath id='clip0_159_213'>
        <rect width='20' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconShelf;
