const IconDocumentAlert = () => (
  <svg viewBox='0 0 22 22' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M18.3333 15.5835H20.1666V13.7502H18.3333V15.5835ZM18.3333 6.41683V11.9168H20.1666V6.41683H18.3333ZM10.0833 8.25016H15.125L10.0833 3.2085V8.25016ZM3.66665 1.8335H11L16.5 7.3335V18.3335C16.5 19.351 15.6841 20.1668 14.6666 20.1668H3.66665C2.64915 20.1668 1.83331 19.3418 1.83331 18.3335V3.66683C1.83331 2.64933 2.64915 1.8335 3.66665 1.8335ZM11.9166 16.5002V14.6668H3.66665V16.5002H11.9166ZM14.6666 12.8335V11.0002H3.66665V12.8335H14.6666Z'
      fill='inherit'
    />
  </svg>
);

export default IconDocumentAlert;
