import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import server from '@shared/utils/server';

import { handleApiError } from '@shared/helpers';

import { AxiosResponse } from 'axios';
import { TApiError } from '@shared/types/global';

interface IAppealCount {
  count: number;
  error: TApiError | null;
}

interface IGetAppealCountResponse {
  count: number;
}

const initialState: IAppealCount = {
  count: 0,
  error: null,
};

export const getAppealCount = createAsyncThunk<
  AxiosResponse<IGetAppealCountResponse>,
  undefined,
  { rejectValue: TApiError }
>('appealCount/getAppealCount', async (params, { rejectWithValue }) => {
  try {
    const res = await server.get('/appeal/count-by-status', {
      params: { status: 1, limit: 1000 },
    });
    return res;
  } catch (err) {
    return rejectWithValue(handleApiError(err));
  }
});

const appealCountAdapter = createEntityAdapter();

const appealCountSlice = createSlice({
  name: 'appealCount',
  initialState: appealCountAdapter.getInitialState<IAppealCount>(initialState),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAppealCount.pending, (state) => {
        state.error = null;
      })
      .addCase(getAppealCount.fulfilled, (state, action) => {
        state.count = action.payload.data.count;
      })
      .addCase(getAppealCount.rejected, (state, action) => {
        state.count = 0;
        state.error = action.payload ?? null;
      });
  },
});

export const appealCountSelector = {
  getState: (state: { appealCount: IAppealCount }) => state.appealCount,
};

export default appealCountSlice.reducer;
