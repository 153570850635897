import { ReactNode } from 'react';

import IconTime from '@shared/assets/icons/icon-time';

import styles from './index.module.scss';

interface IProps {
  title: string;
  isHidden?: boolean;
  children: ReactNode;
}

export const OverviewSection = ({ title, isHidden, children }: IProps) => (
  <div className={styles['section']}>
    {!isHidden ? (
      <>
        <h2 className={styles['section__title']}>{title}</h2>
        {children}
      </>
    ) : (
      <div className={styles['section__plug']}>
        <div className={styles['section__plug-icon']}>
          <IconTime />
        </div>
      </div>
    )}
  </div>
);
