import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { removeNotification } from '@shared/store/slices/notificationListSlice';

import { INotificationListItem } from '@shared/types/interfaces/notification.interface';

interface IProps extends Pick<INotificationListItem, 'id' | 'type' | 'link'> {}

export const Model = ({ id, type, link }: IProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isVisible, setIsVisible] = useState<boolean>(false);

  const navigateTo = () => (link ? navigate(link) : undefined);

  useEffect(() => {
    if (type === 'loader') {
      return;
    }

    const timer = setTimeout(() => dispatch(removeNotification(id)), 3500);

    return () => clearTimeout(timer);
  }, [id, type, dispatch]);

  // Delay for loader notification.
  useEffect(() => {
    if (type !== 'loader') {
      setIsVisible(true);

      return;
    }

    setTimeout(() => {
      setIsVisible(true);
    }, 1000);
  }, [type]);

  return {
    isVisible,
    navigateTo,
  };
};
