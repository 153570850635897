import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { TThunkDispatch } from '@shared/store';
import { useEffect } from 'react';

import {
  getInvoicePeriodListPeriod,
  initialInvoicePeriodListFilter,
  invoicePeriodListSelector,
  changeSlider,
  changeFilter,
  resetPeriodList,
} from '@shared/store/slices/invoicePeriodListSlice';

import { filterSearchParams } from '@shared/helpers';

import { IInvoicePeriodListFilter } from '@shared/types/interfaces/invoice.interface';

export const Model = () => {
  const dispatch = useDispatch<TThunkDispatch>();

  const { filter, slider, periodList } = useSelector(invoicePeriodListSelector.getState);

  const { setValue, watch } = useForm<IInvoicePeriodListFilter>({
    defaultValues: initialInvoicePeriodListFilter,
  });

  const startDateAtWatch = watch('startDateAt');
  const endDateAtWatch = watch('endDateAt');

  const saveStartDateAt = (data: string) => {
    const params = filterSearchParams({
      ...filter,
      startDateAt: data,
    });

    dispatch(resetPeriodList());

    dispatch(getInvoicePeriodListPeriod(params)).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        setValue('startDateAt', data);

        dispatch(changeFilter({ ...filter, startDateAt: data }));
      }
    });
  };

  const saveEndDateAt = (data: string) => {
    const params = filterSearchParams({
      ...filter,
      endDateAt: data,
    });

    dispatch(resetPeriodList());

    dispatch(getInvoicePeriodListPeriod(params)).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        setValue('endDateAt', data);

        dispatch(changeFilter({ ...filter, endDateAt: data }));
      }
    });
  };

  const resetDates = () => {
    const params = filterSearchParams({
      ...filter,
      startDateAt: '',
      endDateAt: '',
    });

    dispatch(resetPeriodList());

    dispatch(getInvoicePeriodListPeriod(params)).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        setValue('startDateAt', '');
        setValue('endDateAt', '');

        dispatch(changeFilter({ ...filter, startDateAt: '', endDateAt: '' }));
      }
    });
  };

  const handleChartLeft = () => {
    const forwardStep = slider.step + filter.limit;

    // INFO: Use a last step less than the limit.
    if (slider.limit !== undefined && slider.limit - slider.step <= filter.limit) {
      dispatch(
        changeSlider({
          ...slider,
          step: slider.limit,
          isBlock: {
            left: true,
            right: false,
          },
        }),
      );

      return;
    }

    // INFO: Use the last step equal to the limit.
    if (forwardStep === slider.limit) {
      dispatch(
        changeSlider({
          ...slider,
          step: forwardStep,
          isBlock: {
            left: true,
            right: false,
          },
        }),
      );

      return;
    }

    // INFO: Use a step without a request.
    if (forwardStep <= periodList.length) {
      dispatch(
        changeSlider({
          ...slider,
          step: forwardStep,
          isBlock: {
            left: false,
            right: false,
          },
        }),
      );

      return;
    }

    const lastPeriod = periodList.at(-1);

    if (!lastPeriod) {
      return;
    }

    const endDateAt = new Date(lastPeriod.date).setDate(-1);

    const params = filterSearchParams({
      ...filter,
      endDateAt: new Date(endDateAt).toISOString(),
    });

    dispatch(getInvoicePeriodListPeriod(params));
  };

  const handleChartRight = () => {
    const backStep = slider.step - filter.limit;

    const lastStep = slider.step % filter.limit;

    dispatch(
      changeSlider({
        ...slider,
        step: lastStep ? slider.step - lastStep : backStep,
        isBlock: {
          left: false,
          right: backStep <= filter.limit,
        },
      }),
    );
  };

  useEffect(() => {
    setValue('startDateAt', filter.startDateAt);
    setValue('endDateAt', filter.endDateAt);
  }, []);

  return {
    slider,
    startDateAtWatch,
    endDateAtWatch,
    saveStartDateAt,
    saveEndDateAt,
    resetDates,
    handleChartLeft,
    handleChartRight,
  };
};
