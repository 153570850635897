import { useCallback, useEffect, useRef, useState, MouseEventHandler } from 'react';

interface IProps {
  selectStart: (data: string) => void;
  selectEnd: (data: string) => void;
}

export const Model = ({ selectStart, selectEnd }: IProps) => {
  const [isCalendarVisible, setIsCalendarVisible] = useState<boolean>(false);
  const [type, setType] = useState<'start' | 'end'>('start');

  const frameRef = useRef<HTMLDivElement | null>(null);
  const inputStartRef = useRef<HTMLDivElement | null>(null);
  const inputEndRef = useRef<HTMLDivElement | null>(null);

  const handleChangeVisible: MouseEventHandler<HTMLDivElement> = (evt) => {
    if (inputStartRef.current?.contains(evt.target as Node)) {
      setType('start');
    } else {
      setType('end');
    }

    setIsCalendarVisible(!isCalendarVisible);
  };

  const onSelect = (data: string) => {
    if (type === 'start') {
      selectStart(data);
    } else {
      selectEnd(data);
    }

    setIsCalendarVisible(false);
  };

  const handleCloseOutside = useCallback((evt: MouseEvent) => {
    if (!frameRef.current?.contains(evt.target as Node)) {
      setIsCalendarVisible(false);
    }
  }, []);

  useEffect(() => {
    if (isCalendarVisible) {
      document.addEventListener('click', handleCloseOutside, true);
    } else {
      document.removeEventListener('click', handleCloseOutside, true);
    }
  }, [isCalendarVisible, handleCloseOutside]);

  return {
    isCalendarVisible,
    frameRef,
    inputStartRef,
    inputEndRef,
    type,
    handleChangeVisible,
    onSelect,
  };
};
