import { useSelector } from 'react-redux';

import { notificationListSelector } from '@shared/store/slices/notificationListSlice';

export const Model = () => {
  const notificationList = useSelector(notificationListSelector.getState);

  return {
    notificationList,
  };
};
