import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import server from '@shared/utils/server';

import { handleApiError } from '@shared/helpers';

import { AxiosResponse } from 'axios';
import { IQueryPage, TSearchParams, TApiError, IEntity } from '@shared/types/global';
import { IUserListItem, TUserListFilter } from '@shared/types/interfaces/user.interface';

interface IUserList {
  data: IUserListItem[];
  isOpenFilter: boolean;
  filter: TUserListFilter;
  page: number;
  count: number;
  total: number;
  totalPage: number;
  isLoading: boolean;
  error: TApiError | null;
}

interface IUserListResponse extends IQueryPage {
  data: IUserListItem[];
  total: number;
}

type TUserListResponse = AxiosResponse<IUserListResponse>;

export const initialUserListFilter: TUserListFilter = {
  _id: '',
  isActive: { _id: '', name: '' },
  name: '',
  position: '',
  role: { _id: '', name: '' },
  contractor: { _id: '', name: '' },
  page: 1,
  count: 30,
};

const initialState: IUserList = {
  data: [],
  isOpenFilter: false,
  filter: initialUserListFilter,
  page: 1,
  totalPage: 1,
  count: 0,
  total: 0,
  isLoading: true,
  error: null,
};

export const getUserListData = createAsyncThunk<
  TUserListResponse,
  TSearchParams,
  { rejectValue: TApiError }
>('userList/getUserListData', async (params, { rejectWithValue }) => {
  try {
    const res = await server.get('/user/list', { params });
    return res;
  } catch (err) {
    return rejectWithValue(handleApiError(err));
  }
});

const userListAdapter = createEntityAdapter();

const userListSlice = createSlice({
  name: 'userList',
  initialState: userListAdapter.getInitialState<IUserList>(initialState),
  reducers: {
    handleOpenFilter: (state) => {
      state.isOpenFilter = !state.isOpenFilter;
    },
    changeFilter: (state, action: PayloadAction<Partial<TUserListFilter>>) => {
      state.filter = { ...state.filter, ...action.payload };
    },
    changeFilterRole: (state, action: PayloadAction<IEntity[]>) => {
      const filterRole = action.payload.find((item) => item._id === state.filter.role._id);

      if (filterRole) {
        state.filter.role = filterRole;
      } else {
        state.filter.role = { _id: '', name: '' };
      }
    },
    resetFilter: (state) => {
      state.filter = initialUserListFilter;
    },
    resetUserList: (state) => {
      state.data = [];
      state.isOpenFilter = false;
      state.filter = initialUserListFilter;
      state.page = 1;
      state.totalPage = 1;
      state.count = 0;
      state.total = 0;
      state.isLoading = false;
      state.error = null;
    },
    resetUserListItem: (state, action: PayloadAction<string>) => {
      state.data = state.data.filter((item) => item._id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserListData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getUserListData.fulfilled, (state, action) => {
        const { data, page, count, total } = action.payload.data;

        state.isLoading = false;
        state.data = data;
        state.page = page;
        state.count = count;
        state.total = total;
        state.totalPage = Math.ceil(total / count);
      })
      .addCase(getUserListData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload ?? null;
      });
  },
});

export const userListSelector = {
  getState: (state: { userList: IUserList }) => state.userList,
  getData: (state: { userList: IUserList }) => state.userList.data,
  getFilter: (state: { userList: IUserList }) => state.userList.filter,
};

export const {
  handleOpenFilter,
  changeFilter,
  changeFilterRole,
  resetFilter,
  resetUserList,
  resetUserListItem,
} = userListSlice.actions;

export type { TUserListResponse };

export default userListSlice.reducer;
