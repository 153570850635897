import Container from '@entities/notification/container';
import Notification from '@entities/notification/item';

import { Model } from '../model';

export const Notifications = () => {
  const { notificationList } = Model();

  return (
    <Container>
      {notificationList.map((item) => (
        <Notification
          key={item.id}
          id={item.id}
          type={item.type}
          title={item.title}
          description={item.description}
          link={item.link}
        />
      ))}
    </Container>
  );
};
