import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { userSelector } from '@shared/store/slices/userSlice';

const accessListMenu = [
  'contractor.list',
  'contract.list',
  'claim.list',
  'invoice.list',
  'placement.list',
  'appeal.list',
  'sending.list',
];

const accessListGuide = ['appeal.type.list', 'user.list', 'group'];

export const Model = () => {
  const accessList = useSelector(userSelector.getAccess);

  const isAccessMenu = useMemo(() => {
    for (let i = 0; i < accessListMenu.length; i++) {
      if (accessList[accessListMenu[i]]) {
        return true;
      }
    }

    return false;
  }, [accessList]);

  const isAccessGuide = useMemo(() => {
    for (let i = 0; i < accessListGuide.length; i++) {
      if (accessList[accessListGuide[i]]) {
        return true;
      }
    }

    return false;
  }, [accessList]);

  return {
    accessList,
    isAccessMenu,
    isAccessGuide,
  };
};
