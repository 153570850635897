const IconReceipt = () => (
  <svg viewBox='0 0 20 16' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_599_5474)'>
      <path
        d='M0.729167 0.0689121C1.17187 -0.0529629 1.69271 -0.00921285 2.0625 0.181412L4.16667 1.26266L6.27083 0.181412C6.73958 -0.0592128 7.43229 -0.0592128 7.89583 0.181412L10 1.26266L12.1042 0.181412C12.5729 -0.0592128 13.2656 -0.0592128 13.7292 0.181412L15.8333 1.26266L17.9375 0.181412C18.3073 -0.00921285 18.8281 -0.0529629 19.2708 0.0689121C19.7135 0.190787 20 0.456412 20 0.750162V15.2502C20 15.5439 19.7135 15.8095 19.2708 15.9314C18.8281 16.0533 18.3073 16.0095 17.9375 15.8189L15.8333 14.7377L13.7292 15.8189C13.2604 16.0595 12.5677 16.0595 12.1042 15.8189L10 14.7377L7.89583 15.8189C7.42708 16.0595 6.73437 16.0595 6.27083 15.8189L4.16667 14.7377L2.0625 15.8189C1.69271 16.0095 1.17187 16.0533 0.729167 15.9314C0.286458 15.8095 0 15.5439 0 15.2502V0.750162C0 0.456412 0.286458 0.190787 0.729167 0.0689121ZM5 4.50016C4.54167 4.50016 4.16667 4.72516 4.16667 5.00016C4.16667 5.27516 4.54167 5.50016 5 5.50016H15C15.4583 5.50016 15.8333 5.27516 15.8333 5.00016C15.8333 4.72516 15.4583 4.50016 15 4.50016H5ZM4.16667 11.0002C4.16667 11.2752 4.54167 11.5002 5 11.5002H15C15.4583 11.5002 15.8333 11.2752 15.8333 11.0002C15.8333 10.7252 15.4583 10.5002 15 10.5002H5C4.54167 10.5002 4.16667 10.7252 4.16667 11.0002ZM5 7.50016C4.54167 7.50016 4.16667 7.72516 4.16667 8.00016C4.16667 8.27516 4.54167 8.50016 5 8.50016H15C15.4583 8.50016 15.8333 8.27516 15.8333 8.00016C15.8333 7.72516 15.4583 7.50016 15 7.50016H5Z'
        fill='inherit'
      />
    </g>
    <defs>
      <clipPath id='clip0_599_5474'>
        <rect width='20' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconReceipt;
