import { ReactNode } from 'react';

import HiddenContent from '@shared/ui/roleBasedHiddenContent';

import colors from '@shared/assets/colors';

import styles from './index.module.scss';

interface IProps {
  title: string;
  use: number;
  free: number;
  color: string;
  onClick?: () => void;
  children: ReactNode;
}

export const OverviewPlacementCard = ({ title, use, free, color, onClick, children }: IProps) => {
  const total = use + free;

  return (
    <article
      className={`${styles['card']} ${!total && styles['card_blocked']}`}
      onClick={total ? onClick : undefined}
    >
      <div className={styles['card__header']}>
        <div
          className={`${styles['card__figure']} ${!total && styles['card__figure_disabled']}`}
          style={{ backgroundColor: total ? color : colors.gray14 }}
        >
          <div className={styles['card__icon']}>{children}</div>
        </div>
        <p className={`${styles['card__title']} ${!total && styles['card__title_disabled']}`}>
          {title}
        </p>
      </div>
      <div className={styles['card__body']}>
        <div className={styles['card__row']}>
          <p
            className={`${styles['card__subtitle']} ${!total && styles['card__subtitle_disabled']}`}
          >
            Используется
          </p>
          <p className={`${styles['card__number']} ${!total && styles['card__number_disabled']}`}>
            {use}
          </p>
        </div>
        <HiddenContent roleList={['renter']}>
          <div className={styles['card__row']}>
            <p
              className={`${styles['card__subtitle']} ${
                !total && styles['card__subtitle_disabled']
              }`}
            >
              Свободно
            </p>
            <p className={`${styles['card__number']} ${!total && styles['card__number_disabled']}`}>
              {free}
            </p>
          </div>
        </HiddenContent>
      </div>
    </article>
  );
};
