import { useNavigate } from 'react-router-dom';

interface IProps {
  url: string;
}

export const Model = ({ url }: IProps) => {
  const navigate = useNavigate();

  const navigateTo = () => {
    navigate(url);
  };

  return {
    navigateTo: url ? navigateTo : undefined,
  };
};
