import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { userSelector } from '@shared/store/slices/userSlice';

interface IProps {
  resource: string;
  children: ReactNode;
}

const ProtectedRoute = ({ resource, children }: IProps) => {
  const accessList = useSelector(userSelector.getAccess);

  return accessList[resource] ? children : <Navigate to='/' />;
};

export default ProtectedRoute;
