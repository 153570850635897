import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import server from 'shared/utils/server';

import { handleApiError } from '@shared/helpers';

import { AxiosResponse } from 'axios';
import { IStatus, TApiError } from '@shared/types/global';

interface IContractAncillary {
  typeList: IStatus[];
  isLoading: boolean;
  error: TApiError | null;
}

const initialState: IContractAncillary = {
  typeList: [],
  isLoading: false,
  error: null,
};

export const getContractAncillary = createAsyncThunk<
  AxiosResponse<Pick<IContractAncillary, 'typeList'>>,
  undefined,
  { rejectValue: TApiError }
>('contractAncillary/getContractAncillary', async (params, { rejectWithValue }) => {
  try {
    const res = await server.get<Pick<IContractAncillary, 'typeList'>>('/contract/ancillary', {
      params,
    });
    return res;
  } catch (err) {
    return rejectWithValue(handleApiError(err));
  }
});

const contractAncillaryAdapter = createEntityAdapter();

const contractAncillarySlice = createSlice({
  name: 'contractAncillary',
  initialState: contractAncillaryAdapter.getInitialState<IContractAncillary>(initialState),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getContractAncillary.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getContractAncillary.fulfilled, (state, action) => {
        const { typeList } = action.payload.data;

        state.isLoading = false;
        state.typeList = typeList;
      })
      .addCase(getContractAncillary.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload ?? null;
      });
  },
});

export const contractAncillarySelector = {
  getState: (state: { contractAncillary: IContractAncillary }) => state.contractAncillary,
};

export default contractAncillarySlice.reducer;
