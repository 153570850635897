const IconMenu = () => (
  <svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.8337 5.83333C15.8337 5.3731 15.4606 5 15.0003 5H5.00033C4.54009 5 4.16699 5.3731 4.16699 5.83333C4.16699 6.29357 4.54009 6.66667 5.00033 6.66667H15.0003C15.4606 6.66667 15.8337 6.29357 15.8337 5.83333ZM15.8337 10C15.8337 9.53975 15.4606 9.16667 15.0003 9.16667H5.00033C4.54008 9.16667 4.16699 9.53975 4.16699 10C4.16699 10.4602 4.54009 10.8333 5.00033 10.8333H15.0003C15.4606 10.8333 15.8337 10.4602 15.8337 10ZM15.0003 13.3333C15.4606 13.3333 15.8337 13.7064 15.8337 14.1667C15.8337 14.6269 15.4606 15 15.0003 15H5.00033C4.54009 15 4.16699 14.6269 4.16699 14.1667C4.16699 13.7064 4.54009 13.3333 5.00033 13.3333H15.0003Z'
      fill='inherit'
    />
  </svg>
);

export default IconMenu;
