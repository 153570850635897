type TIsDateDiffer = ({ date }: { date: string }) => number | null;

export const getDateDiffer: TIsDateDiffer = ({ date }) => {
  const today = Date.now();
  const targetDate = new Date(date);

  if (Number.isNaN(targetDate.getDate())) {
    return null;
  }

  return targetDate.getTime() - today;
};
