const IconTime = () => (
  <svg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg' fill='none'>
    <path
      d='M50 12.5C29.2969 12.5 12.5 29.2969 12.5 50C12.5 70.7031 29.2969 87.5 50 87.5C70.7031 87.5 87.5 70.7031 87.5 50C87.5 29.2969 70.7031 12.5 50 12.5Z'
      stroke='inherit'
      strokeWidth='4'
      strokeMiterlimit='10'
    />
    <path
      d='M50 25V53.125H68.75'
      stroke='inherit'
      strokeWidth='4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default IconTime;
