import { Helmet } from 'react-helmet-async';

import Section from '@shared/ui/screenSection';
import Form from '@features/authentication-form';

export const Auth = () => (
  <>
    <Helmet title='Авторизация' />
    <Section>
      <Form />
    </Section>
  </>
);
