const IconWarehouse = () => (
  <svg viewBox='0 0 20 16' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_159_47)'>
      <path
        d='M6.5 10H18.5C18.775 10 19 9.775 19 9.5V1.5C19 1.225 18.775 1 18.5 1H14V5L12.5 4L11 5V1H6.5C6.225 1 6 1.225 6 1.5V9.5C6 9.775 6.225 10 6.5 10ZM19.5 12H4V0.5C4 0.225 3.775 0 3.5 0H0.5C0.225 0 0 0.225 0 0.5V1.5C0 1.775 0.225 2 0.5 2H2V13.5C2 13.775 2.225 14 2.5 14H5.09062C5.03437 14.1562 5 14.325 5 14.5C5 15.3281 5.67188 16 6.5 16C7.32812 16 8 15.3281 8 14.5C8 14.325 7.9625 14.1562 7.90938 14H14.0938C14.0375 14.1562 14.0031 14.325 14.0031 14.5C14.0031 15.3281 14.675 16 15.5031 16C16.3312 16 17.0031 15.3281 17.0031 14.5C17.0031 14.325 16.9656 14.1562 16.9125 14H19.5C19.775 14 20 13.775 20 13.5V12.5C20 12.225 19.775 12 19.5 12Z'
        fill='inherit'
      />
    </g>
    <defs>
      <clipPath id='clip0_159_47'>
        <rect width='20' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconWarehouse;
