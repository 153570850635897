import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { AnyAction, Dispatch, Middleware } from '@reduxjs/toolkit';

const isUseLogger =
  process.env.NODE_ENV === 'development' && process.env.USE_REDUX_LOGGER === 'true';

const middlewares: Middleware[] = [];

middlewares.push(thunk);

if (isUseLogger) {
  const logger = createLogger({
    collapsed: true,
    duration: true,
    timestamp: false,
    diff: true,
  }) as Middleware<{}, any, Dispatch<AnyAction>>;

  middlewares.push(logger);
}

export { middlewares };
