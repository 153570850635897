import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getIdFromDate } from '@shared/helpers';

import { INotificationListItem } from '@shared/types/interfaces/notification.interface';

interface INotificationListItemPayload extends Omit<INotificationListItem, 'id'> {
  id?: string;
}

const initialState: INotificationListItem[] = [];

const notificationListSlice = createSlice({
  name: 'notificationList',
  initialState: {
    value: initialState,
  },
  reducers: {
    addNotification: (state, action: PayloadAction<INotificationListItemPayload>) => {
      const { id } = action.payload;

      state.value = [...state.value, { ...action.payload, id: id ?? getIdFromDate() }];
    },
    removeNotification: (state, action: PayloadAction<string>) => {
      state.value = state.value.filter((item) => item.id !== action.payload);
    },
  },
});

export const notificationListSelector = {
  getState: (state: { notificationList: { value: INotificationListItem[] } }) =>
    state.notificationList.value,
};

export const { addNotification, removeNotification } = notificationListSlice.actions;

export default notificationListSlice.reducer;
