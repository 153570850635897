import { Children, ReactElement, cloneElement } from 'react';

import { Model } from '../model';

interface IProps {
  url: string;
  children: ReactElement;
}

export const Link = ({ url, children }: IProps) => {
  const { navigateTo } = Model({ url });

  return cloneElement(Children.only(children), { onClick: navigateTo });
};
