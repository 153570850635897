import { ReactNode } from 'react';

import styles from './index.module.scss';

import { Model } from '../model';

interface IProps {
  isOpen: boolean;
  transitionOff?: boolean;
  children: ReactNode;
  clickOutSide?: ((evt: MouseEvent) => void) | undefined;
}

export const Popup = ({ isOpen, transitionOff, clickOutSide, children }: IProps) => {
  Model({ isOpen, clickOutSide });

  return (
    <div
      className={`${styles['popup']} ${isOpen && styles['popup_opened']} ${
        transitionOff && styles['popup_transition_none']
      }`}
    >
      {children}
    </div>
  );
};
