import { ComponentType, ReactNode } from 'react';

import Container from '@entities/sidebar/navigation';
import Section from '@entities/sidebar/menuSection';
import Link from '@entities/sidebar/menuItem';

import IconContractors from '@shared/assets/icons/icon-contractors';
import IconDocument from '@shared/assets/icons/icon-document';
import IconDocumentAlert from '@shared/assets/icons/icon-document-alert';
import IconInvoice from '@shared/assets/icons/icon-invoice';
import IconRoom from '@shared/assets/icons/icon-room';
import IconAppealTypes from '@shared/assets/icons/icon-appeal-types';
import IconUsers from '@shared/assets/icons/icon-users';
import IconGroup from '@shared/assets/icons/icon-group';
import IconAppeal from '@shared/assets/icons/icon-appeal';
import IconEmail from '@shared/assets/icons/icon-email';

import { Model } from '../model';

interface ILink {
  title: string;
  link: string;
  isAccess: boolean;
  children: ReactNode;
}

interface IProps {
  AppealLink: ComponentType<ILink>;
  ClaimLink: ComponentType<ILink>;
}

export const Navigation = ({ AppealLink, ClaimLink }: IProps) => {
  const { accessList, isAccessMenu, isAccessGuide } = Model();

  return (
    <Container>
      <Section title='Меню' isAccess={isAccessMenu}>
        <Link
          title='Арендаторы и поставщики'
          link='/contractor-list'
          isAccess={accessList['contractor.list']}
        >
          <IconContractors />
        </Link>
        <Link title='Договоры' link='/contract-list' isAccess={accessList['contract.list']}>
          <IconDocument />
        </Link>
        <ClaimLink title='Претензии' link='/claim-list' isAccess={accessList['claim.list']}>
          <IconDocumentAlert />
        </ClaimLink>
        <Link
          title='Платежи и документы'
          link='/invoice-list'
          isAccess={accessList['invoice.list']}
        >
          <IconInvoice />
        </Link>
        <Link title='Помещения' link='/placement-list' isAccess={accessList['placement.list']}>
          <IconRoom />
        </Link>
        <AppealLink title='Обращения' link='/appeal-list' isAccess={accessList['appeal.list']}>
          <IconAppeal />
        </AppealLink>
        <Link title='Рассылка' link='/sending-list' isAccess={accessList['sending.list']}>
          <IconEmail />
        </Link>
      </Section>
      <Section title='Справочники' isAccess={isAccessGuide}>
        <Link
          title='Виды обращений'
          link='/appeal-type-list'
          isAccess={accessList['appeal.type.list']}
        >
          <IconAppealTypes />
        </Link>
        <Link title='Пользователи' link='/user-list' isAccess={accessList['user.list']}>
          <IconUsers />
        </Link>
        <Link title='Группы' link='/group-list' isAccess={accessList['group']}>
          <IconGroup />
        </Link>
      </Section>
    </Container>
  );
};
