import { ReactNode, RefObject } from 'react';

import styles from './index.module.scss';

interface IProps {
  frameRef: RefObject<HTMLDivElement>;
  top?: number;
  bottom?: number;
  children: ReactNode;
}

export const InputFrame = ({ frameRef, top, bottom, children }: IProps) => (
  <div ref={frameRef} className={styles['frame']} style={{ top, bottom }}>
    {children}
  </div>
);
