import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import server from '@shared/utils/server';

import { handleApiError } from '@shared/helpers';

import { AxiosResponse } from 'axios';
import { IQueryPage, TSearchParams, IStatusText, TApiError } from '@shared/types/global';
import {
  IPlacementListItem,
  TPlacementListFilter,
} from '@shared/types/interfaces/placement.interface';

interface IPlacementType extends IStatusText {
  code: string;
}

interface IPlacementList {
  data: IPlacementListItem[];
  isOpenFilter: boolean;
  filter: TPlacementListFilter;
  page: number;
  count: number;
  total: number;
  totalPage: number;
  isLoading: boolean;
  error: TApiError | null;
}

interface IPlacementListResponse extends IQueryPage {
  data: IPlacementListItem[];
  total: number;
}

type TPlacementListResponse = AxiosResponse<IPlacementListResponse>;

export const initialPlacementListFilter: TPlacementListFilter = {
  _id: '',
  type: { _id: '', name: '' },
  isFree: { _id: '', name: '' },
  name: '',
  contractor: { _id: '', name: '' },
  building: { _id: '', name: '' },
  startFloor: '',
  endFloor: '',
  startPrice: '',
  endPrice: '',
  startArea: '',
  endArea: '',
  page: 1,
  count: 30,
};

const initialState: IPlacementList = {
  data: [],
  isOpenFilter: false,
  filter: initialPlacementListFilter,
  page: 1,
  totalPage: 1,
  count: 0,
  total: 0,
  isLoading: true,
  error: null,
};

export const getPlacementListData = createAsyncThunk<
  TPlacementListResponse,
  TSearchParams,
  { rejectValue: TApiError }
>('placementList/getPlacementListData', async (params, { rejectWithValue }) => {
  try {
    const res = await server.get('/placement/list', { params });
    return res;
  } catch (err) {
    return rejectWithValue(handleApiError(err));
  }
});

const placementListAdapter = createEntityAdapter();

const placementListSlice = createSlice({
  name: 'placementList',
  initialState: placementListAdapter.getInitialState<IPlacementList>(initialState),
  reducers: {
    handleOpenFilter: (state) => {
      state.isOpenFilter = !state.isOpenFilter;
    },
    changeFilter: (state, action: PayloadAction<Partial<TPlacementListFilter>>) => {
      state.filter = { ...state.filter, ...action.payload };
    },
    changeFilterType: (state, action: PayloadAction<IPlacementType[]>) => {
      const filterType = action.payload.find((item) => item.id === Number(state.filter.type._id));

      if (filterType) {
        state.filter.type = { _id: filterType.id.toString(), name: filterType.name };
      } else {
        state.filter.type = { _id: '', name: '' };
      }
    },
    resetFilter: (state) => {
      state.filter = initialPlacementListFilter;
    },
    resetPlacementList: (state) => {
      state.data = [];
      state.isOpenFilter = false;
      state.filter = initialPlacementListFilter;
      state.page = 1;
      state.totalPage = 1;
      state.count = 0;
      state.total = 0;
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPlacementListData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getPlacementListData.fulfilled, (state, action) => {
        const { data, page, count, total } = action.payload.data;

        state.isLoading = false;
        state.data = data;
        state.page = page;
        state.count = count;
        state.total = total;
        state.totalPage = Math.ceil(total / count);
      })
      .addCase(getPlacementListData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload ?? null;
      });
  },
});

export const placementListSelector = {
  getState: (state: { placementList: IPlacementList }) => state.placementList,
  getData: (state: { placementList: IPlacementList }) => state.placementList.data,
  getFilter: (state: { placementList: IPlacementList }) => state.placementList.filter,
};

export const { handleOpenFilter, changeFilter, changeFilterType, resetFilter, resetPlacementList } =
  placementListSlice.actions;

export type { TPlacementListResponse };

export default placementListSlice.reducer;
