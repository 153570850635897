import { useSelector } from 'react-redux';

import { configAppSelector } from 'shared/store/slices/configAppSlice';

export const Model = () => {
  const configApp = useSelector(configAppSelector.getConfig);

  return {
    themeColor: configApp.themeColor,
  };
};
