import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import server from 'shared/utils/server';

import { handleApiError } from '@shared/helpers';

import { AxiosResponse } from 'axios';
import { IStatus, TApiError } from '@shared/types/global';

interface IClaimAncillary {
  statusList: IStatus[];
  isLoading: boolean;
  error: TApiError | null;
}

const initialState: IClaimAncillary = {
  statusList: [],
  isLoading: false,
  error: null,
};

export const getClaimAncillary = createAsyncThunk<
  AxiosResponse<Pick<IClaimAncillary, 'statusList'>>,
  undefined,
  { rejectValue: TApiError }
>('claimAncillary/getClaimAncillary', async (params, { rejectWithValue }) => {
  try {
    const res = await server.get<Pick<IClaimAncillary, 'statusList'>>('/claim/ancillary', {
      params,
    });
    return res;
  } catch (err) {
    return rejectWithValue(handleApiError(err));
  }
});

const claimAncillaryAdapter = createEntityAdapter();

const claimAncillarySlice = createSlice({
  name: 'claimAncillary',
  initialState: claimAncillaryAdapter.getInitialState<IClaimAncillary>(initialState),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getClaimAncillary.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getClaimAncillary.fulfilled, (state, action) => {
        const { statusList } = action.payload.data;

        state.isLoading = false;
        state.statusList = statusList;
      })
      .addCase(getClaimAncillary.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload ?? null;
      });
  },
});

export const claimAncillarySelector = {
  getState: (state: { claimAncillary: IClaimAncillary }) => state.claimAncillary,
};

export default claimAncillarySlice.reducer;
