import { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TThunkDispatch } from '@shared/store';

import { userSelector, changeUserAccess } from '@shared/store/slices/userSlice';
import { configAppSelector } from '@shared/store/slices/configAppSlice';

interface IProps {
  children: ReactNode;
}

const AccessApp = ({ children }: IProps) => {
  const dispatch = useDispatch<TThunkDispatch>();

  const resourcesByRole = useSelector(userSelector.getResources);
  const resourcesByApp = useSelector(configAppSelector.getResourses);
  const accessList = useSelector(userSelector.getAccess);

  useEffect(() => {
    const result: Record<string, boolean> = {};

    resourcesByApp.forEach((item) => {
      const isAccess = !!resourcesByRole.find((roleItem) => roleItem === item);

      if (isAccess) {
        result[item] = true;
      } else {
        result[item] = false;
      }
    });

    dispatch(changeUserAccess(result));
  }, [dispatch, resourcesByRole, resourcesByApp]);

  return !Object.keys(accessList).length ? null : children;
};

export default AccessApp;
