import { IFileConvert } from '@shared/types/interfaces/file.interface';

type TConvertFile = (file: File) => IFileConvert;

export const convertFile: TConvertFile = (file) => {
  const url = URL.createObjectURL(file);

  return {
    _id: file.name,
    name: file.name,
    url,
    data: file,
    size: file.size,
  };
};
