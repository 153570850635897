import Btn from '@entities/header/profileBtn';
import ProfileCard from '@entities/header/profileCard';

import { Model } from '../model';

export const HeaderProfile = () => {
  const { isAuth, isOpenCard, profileCardRef, btnRef, handleChangeOpen, handleExit } = Model();

  return (
    isAuth && (
      <>
        <Btn btnRef={btnRef} changeIsOpen={handleChangeOpen} />
        <ProfileCard
          refCard={profileCardRef}
          isOpen={isOpenCard}
          changeIsOpen={handleChangeOpen}
          onExit={handleExit}
        />
      </>
    )
  );
};
