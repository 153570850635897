import { THttpStatusCode } from '@shared/types/interfaces/api.interface';

type TGetDefaultStatusText = (status: THttpStatusCode) => string;

const apiError: Record<number, string> = {
  400: 'Произошла ошибка',
  401: 'Доступ запрещен',
  403: 'Доступ запрещен',
  404: 'Ресурс не найден',
  408: 'Превышено время ожидания',
  413: 'Превышен максимальный размер запроса',
  500: 'Внутренняя ошибка',
  502: 'Сервис недоступен',
};

export const getDefaultStatusText: TGetDefaultStatusText = (status) =>
  status ? apiError[status] || apiError[408] : apiError[408];
