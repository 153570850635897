import { useRef, useState, useCallback } from 'react';
import type { Ref } from 'react';

export const useHover = <T extends HTMLElement>(): [Ref<T>, boolean] => {
  const elRef = useRef<T | null>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const onMouseEnter = () => {
    setIsHovered(true);
  };
  const onMouseLeave = () => {
    setIsHovered(false);
  };

  const setRef = useCallback((node: T) => {
    if (elRef.current) {
      elRef.current.removeEventListener('mouseenter', onMouseEnter);
      elRef.current.removeEventListener('mouseleave', onMouseLeave);
    }

    if (node) {
      elRef.current = node;
      elRef.current.addEventListener('mouseenter', onMouseEnter);
      elRef.current.addEventListener('mouseleave', onMouseLeave);
    }
  }, []);

  return [setRef, isHovered];
};
