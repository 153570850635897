import { ReactNode } from 'react';

import styles from './index.module.scss';

interface IProps {
  title: string;
  color: string;
  onClick?: () => void;
  children: ReactNode;
}

export const OverviewPopularItem = ({ title, color, onClick, children }: IProps) => (
  <div className={styles['item']} onClick={onClick}>
    <div className={styles['item__figure']} style={{ backgroundColor: color }}>
      <div className={styles['item__icon']}>{children}</div>
    </div>
    <p className={styles['item__text']}>{title}</p>
  </div>
);
