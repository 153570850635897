import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import server from 'shared/utils/server';

import { handleApiError } from '@shared/helpers';

import { AxiosResponse } from 'axios';
import { IStatusText, TApiError } from '@shared/types/global';

interface IPlacementType extends IStatusText {
  code: string;
}

interface IPlacementAncillary {
  typeList: IPlacementType[];
  isLoading: boolean;
  error: TApiError | null;
}

const initialState: IPlacementAncillary = {
  typeList: [],
  isLoading: false,
  error: null,
};

export const getPlacementAncillary = createAsyncThunk<
  AxiosResponse<Pick<IPlacementAncillary, 'typeList'>>,
  undefined,
  { rejectValue: TApiError }
>('placementAncillary/getPlacementAncillary', async (params, { rejectWithValue }) => {
  try {
    const res = await server.get<Pick<IPlacementAncillary, 'typeList'>>('/placement/ancillary', {
      params,
    });
    return res;
  } catch (err) {
    return rejectWithValue(handleApiError(err));
  }
});

const placementAncillaryAdapter = createEntityAdapter();

const placementAncillarySlice = createSlice({
  name: 'placementAncillary',
  initialState: placementAncillaryAdapter.getInitialState<IPlacementAncillary>(initialState),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPlacementAncillary.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getPlacementAncillary.fulfilled, (state, action) => {
        const { typeList } = action.payload.data;

        state.isLoading = false;
        state.typeList = typeList;
      })
      .addCase(getPlacementAncillary.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload ?? null;
      });
  },
});

export const placementAncillarySelector = {
  getState: (state: { placementAncillary: IPlacementAncillary }) => state.placementAncillary,
};

export default placementAncillarySlice.reducer;
