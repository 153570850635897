const IconErrorCircle = () => (
  <svg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'>
    <g id='icon-close-circle'>
      <path
        id='Vector'
        d='M87.5 50C87.5 29.2969 70.7031 12.5 50 12.5C29.2969 12.5 12.5 29.2969 12.5 50C12.5 70.7031 29.2969 87.5 50 87.5C70.7031 87.5 87.5 70.7031 87.5 50Z'
        stroke='inherit'
        strokeWidth='3'
        strokeMiterlimit='10'
      />
      <path
        id='Vector_2'
        d='M62.5 62.5L37.5 37.5M37.5 62.5L62.5 37.5'
        stroke='inherit'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>
);

export default IconErrorCircle;
