const IconCalendar = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40' fill='none'>
    <path
      d='M10 16.6667H30M25.5556 21.1127L14.4444 21.1111M18.1481 25.5561L14.4444 25.5556M14.4444 10V12.2222M25.5556 10V12.2222M13.5556 30H26.4444C27.689 30 28.3113 30 28.7867 29.7578C29.2048 29.5448 29.5448 29.2048 29.7578 28.7867C30 28.3113 30 27.689 30 26.4444V15.7778C30 14.5332 30 13.9109 29.7578 13.4356C29.5448 13.0174 29.2048 12.6775 28.7867 12.4644C28.3113 12.2222 27.689 12.2222 26.4444 12.2222H13.5556C12.311 12.2222 11.6887 12.2222 11.2134 12.4644C10.7952 12.6775 10.4553 13.0174 10.2422 13.4356C10 13.9109 10 14.5332 10 15.7778V26.4444C10 27.689 10 28.3113 10.2422 28.7867C10.4553 29.2048 10.7952 29.5448 11.2134 29.7578C11.6887 30 12.311 30 13.5556 30Z'
      stroke='inherit'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default IconCalendar;
