import CircularLoaderMui from '@shared/ui/circularLoaderMui';

import styles from './index.module.scss';

interface IProps {
  isTableElement?: boolean;
}

export const OverviewLoader = ({ isTableElement }: IProps) =>
  isTableElement ? (
    <tr className={styles['loader']}>
      <td className={styles['loader__column']}>
        <CircularLoaderMui size={40} />
      </td>
    </tr>
  ) : (
    <div className={styles['loader']}>
      <div className={styles['loader__column']}>
        <CircularLoaderMui size={40} />
      </div>
    </div>
  );
