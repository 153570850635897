const IconWarning = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
    <path
      d='M25.3333 25.3333H6.66667V6.66667H25.3333M25.3333 4H6.66667C5.95942 4 5.28115 4.28095 4.78105 4.78105C4.28095 5.28115 4 5.95942 4 6.66667V25.3333C4 26.0406 4.28095 26.7189 4.78105 27.219C5.28115 27.719 5.95942 28 6.66667 28H25.3333C26.0406 28 26.7189 27.719 27.219 27.219C27.719 26.7189 28 26.0406 28 25.3333V6.66667C28 5.18667 26.8 4 25.3333 4ZM14.6667 20H17.3333V22.6667H14.6667V20ZM14.6667 9.33333H17.3333V17.3333H14.6667V9.33333Z'
      fill='inherit'
    />
  </svg>
);

export default IconWarning;
