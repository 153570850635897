import { useState } from 'react';
import { useSelector } from 'react-redux';

import { configAppSelector } from 'shared/store/slices/configAppSlice';

export const Model = () => {
  const configApp = useSelector(configAppSelector.getConfig);
  const [isHidden, setIsHidden] = useState<boolean>(true);

  const handleChangeIsHidden = () => {
    setIsHidden(!isHidden);
  };

  return {
    borderColor: configApp.themeColor,
    isHidden,
    handleChangeIsHidden,
  };
};
