import Section from '@entities/overview/section';
import Container from '@entities/overview/popularContainer';
import Link from '@shared/ui/link';
import Item from '@entities/overview/popularItem';
import HiddenContent from '@shared/ui/roleBasedHiddenContent';

import IconHouse from '@shared/assets/icons/icon-house';
import IconGuestPass from '@shared/assets/icons/icon-guest-pass';
import IconReceipt from '@shared/assets/icons/icon-receipt';

import colors from '@shared/assets/colors';

import { Model } from '../model';

export const OverviewPopular = () => {
  const { urlList } = Model();

  return (
    <Section title='Популярное'>
      <Container>
        <HiddenContent roleList={['renter']}>
          <Link url={urlList.placementListUrl}>
            <Item title='Cвободные помещения' color={colors.green}>
              <IconHouse />
            </Item>
          </Link>
        </HiddenContent>
        <HiddenContent roleList={['director', 'employee']}>
          <Link url={urlList.appealAnsweredListUrl}>
            <Item title='Отвеченные обращения' color={colors.purple}>
              <IconGuestPass />
            </Item>
          </Link>
        </HiddenContent>
        <HiddenContent roleList={['renter']}>
          <Link url={urlList.appealUnansweredListUrl}>
            <Item title='Неотвеченные обращения' color={colors.purple}>
              <IconGuestPass />
            </Item>
          </Link>
        </HiddenContent>
        <Link url={urlList.invoiceListUrl}>
          <Item title='Неоплаченные счёта' color={colors.red2}>
            <IconReceipt />
          </Item>
        </Link>
      </Container>
    </Section>
  );
};
