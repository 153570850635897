import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import ConfigApp from '@shared/utils/ConfigApp';
import TokenApp from '@shared/utils/TokenApp';
import SocketApp from '@shared/utils/SocketApp';
import AccessApp from '@shared/utils/AccessApp';
import KeyboardApp from '@shared/utils/KeyboardApp';
import ProtectedApp from '@shared/utils/ProtectedApp';
import ProtectedRoute from '@shared/utils/ProtectedRoute';
import QueryParams from '@shared/utils/QueryParams';

import Header from '@widgets/header';
import HeaderProfile from '@widgets/headerProfile';
import Main from '@widgets/main';
import Sidebar from '@widgets/sidebar';
import SuspenseFallback from '@entities/suspenseFallback';
import ErrorPopup from '@widgets/errorPopup';
import ConfirmActionPopup from '@widgets/confirmActionPopup';
import Notifications from '@widgets/notifications';

import Auth from '@pages/auth';
import Home from '@pages/home';
import NotFound from '@pages/notFound';

const ContractorList = lazy(() => import('./contractorList'));
const Contractor = lazy(() => import('./contractor'));
const ContactCreate = lazy(() => import('./contactCreate'));
const ContactEdit = lazy(() => import('./contactEdit'));
const ContractList = lazy(() => import('./contractList'));
const Contract = lazy(() => import('./contract'));
const ClaimList = lazy(() => import('./claimList'));
const Claim = lazy(() => import('./claim'));
const InvoiceList = lazy(() => import('./invoiceList'));
const Invoice = lazy(() => import('./invoice'));
const PlacementList = lazy(() => import('./placementList'));
const Placement = lazy(() => import('./placement'));
const AppealList = lazy(() => import('./appealList'));
const Appeal = lazy(() => import('./appeal'));
const AppealCreate = lazy(() => import('./appealCreate'));
const SendingList = lazy(() => import('./sendingList'));
const Sending = lazy(() => import('./sending'));
const SendingCreate = lazy(() => import('./sendingCreate'));
const AppealTypeList = lazy(() => import('./appealTypeList'));
const AppealTypeCreate = lazy(() => import('./appealTypeCreate'));
const AppealTypeEdit = lazy(() => import('./appealTypeEdit'));
const UserList = lazy(() => import('./userList'));
const User = lazy(() => import('./user'));
const UserCreate = lazy(() => import('./userCreate'));
const UserEdit = lazy(() => import('./userEdit'));
const Password = lazy(() => import('./password'));
const Profile = lazy(() => import('./profile'));
const GroupList = lazy(() => import('./groupList'));
const GroupCreate = lazy(() => import('./groupCreate'));
const GroupEdit = lazy(() => import('./groupEdit'));

const App = () => (
  <ConfigApp>
    <TokenApp>
      <AccessApp>
        <Notifications />
        <SocketApp>
          <KeyboardApp>
            <Header>
              <HeaderProfile />
            </Header>
            <Sidebar />
            <Main>
              <ProtectedApp>
                <Routes>
                  <Route path='/auth' element={<Auth />} />
                  <Route path='/' element={<Home />} />
                  <Route
                    path='/contractor-list'
                    element={
                      <ProtectedRoute resource='contractor.list'>
                        <Suspense fallback={<SuspenseFallback isBackground />}>
                          <QueryParams
                            params={['_id', 'name', 'inn', 'page', 'count']}
                            paramsArray={['owners']}
                          >
                            <ContractorList queryParams={{}} />
                          </QueryParams>
                        </Suspense>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/contractor/:_id'
                    element={
                      <ProtectedRoute resource='contractor'>
                        <Suspense fallback={<SuspenseFallback isBackground />}>
                          <Contractor />
                        </Suspense>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/contact/create'
                    element={
                      <ProtectedRoute resource='contact.create'>
                        <Suspense fallback={<SuspenseFallback isBackground />}>
                          <ContactCreate />
                        </Suspense>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/contact/edit/:_id'
                    element={
                      <ProtectedRoute resource='contact.edit'>
                        <Suspense fallback={<SuspenseFallback isBackground />}>
                          <ContactEdit />
                        </Suspense>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/contract-list'
                    element={
                      <ProtectedRoute resource='contract.list'>
                        <Suspense fallback={<SuspenseFallback isBackground />}>
                          <QueryParams
                            params={[
                              '_id',
                              'number',
                              'type',
                              'contractor',
                              'building',
                              'startDateAt',
                              'endDateAt',
                              'page',
                              'count',
                            ]}
                          >
                            <ContractList queryParams={{}} />
                          </QueryParams>
                        </Suspense>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/contract/:_id'
                    element={
                      <ProtectedRoute resource='contract'>
                        <Suspense fallback={<SuspenseFallback isBackground />}>
                          <Contract />
                        </Suspense>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/claim-list'
                    element={
                      <ProtectedRoute resource='claim.list'>
                        <Suspense fallback={<SuspenseFallback isBackground />}>
                          <QueryParams
                            params={[
                              '_id',
                              'status',
                              'number',
                              'contractor',
                              'building',
                              'startDateAt',
                              'endDateAt',
                              'minSum',
                              'maxSum',
                              'minSumRepayment',
                              'maxSumRepayment',
                              'isSendingInCourt',
                              'page',
                              'count',
                            ]}
                          >
                            <ClaimList queryParams={{}} />
                          </QueryParams>
                        </Suspense>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/claim/:_id'
                    element={
                      <ProtectedRoute resource='claim'>
                        <Suspense fallback={<SuspenseFallback isBackground />}>
                          <Claim />
                        </Suspense>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/invoice-list'
                    element={
                      <ProtectedRoute resource='invoice.list'>
                        <Suspense fallback={<SuspenseFallback isBackground />}>
                          <QueryParams
                            params={[
                              '_id',
                              'number',
                              'status',
                              'type',
                              'contractor',
                              'contract',
                              'building',
                              'minSum',
                              'maxSum',
                              'startDateAt',
                              'endDateAt',
                              'page',
                              'count',
                            ]}
                          >
                            <InvoiceList queryParams={{}} />
                          </QueryParams>
                        </Suspense>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/invoice/:_id'
                    element={
                      <ProtectedRoute resource='invoice'>
                        <Suspense fallback={<SuspenseFallback isBackground />}>
                          <Invoice />
                        </Suspense>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/placement-list'
                    element={
                      <ProtectedRoute resource='placement.list'>
                        <Suspense fallback={<SuspenseFallback isBackground />}>
                          <QueryParams
                            params={[
                              '_id',
                              'isFree',
                              'name',
                              'contractor',
                              'building',
                              'type',
                              'startFloor',
                              'endFloor',
                              'startPrice',
                              'endPrice',
                              'startArea',
                              'endArea',
                              'page',
                              'count',
                            ]}
                          >
                            <PlacementList queryParams={{}} />
                          </QueryParams>
                        </Suspense>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/placement/:_id'
                    element={
                      <ProtectedRoute resource='placement'>
                        <Suspense fallback={<SuspenseFallback isBackground />}>
                          <Placement />
                        </Suspense>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/appeal-list'
                    element={
                      <ProtectedRoute resource='appeal.list'>
                        <Suspense fallback={<SuspenseFallback isBackground />}>
                          <QueryParams
                            params={[
                              '_id',
                              'number',
                              'status',
                              'contractor',
                              'building',
                              'type',
                              'description',
                              'startDateAt',
                              'endDateAt',
                              'page',
                              'count',
                            ]}
                          >
                            <AppealList queryParams={{}} />
                          </QueryParams>
                        </Suspense>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/appeal/create'
                    element={
                      <ProtectedRoute resource='appeal.create'>
                        <Suspense fallback={<SuspenseFallback isBackground />}>
                          <AppealCreate />
                        </Suspense>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/appeal/:_id'
                    element={
                      <ProtectedRoute resource='appeal'>
                        <Suspense fallback={<SuspenseFallback isBackground />}>
                          <Appeal />
                        </Suspense>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/sending-list'
                    element={
                      <ProtectedRoute resource='sending.list'>
                        <Suspense fallback={<SuspenseFallback isBackground />}>
                          <QueryParams
                            params={[
                              '_id',
                              'type',
                              'method',
                              'status',
                              'creator',
                              'title',
                              'startDateAt',
                              'endDateAt',
                              'page',
                              'count',
                            ]}
                          >
                            <SendingList queryParams={{}} />
                          </QueryParams>
                        </Suspense>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/sending/create'
                    element={
                      <ProtectedRoute resource='sending.create'>
                        <Suspense fallback={<SuspenseFallback isBackground />}>
                          <SendingCreate />
                        </Suspense>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/sending/:_id'
                    element={
                      <ProtectedRoute resource='sending'>
                        <Suspense fallback={<SuspenseFallback isBackground />}>
                          <Sending />
                        </Suspense>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/appeal-type-list'
                    element={
                      <ProtectedRoute resource='appeal.type.list'>
                        <Suspense fallback={<SuspenseFallback isBackground />}>
                          <QueryParams params={['_id', 'name', 'isActive', 'page', 'count']}>
                            <AppealTypeList queryParams={{}} />
                          </QueryParams>
                        </Suspense>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/appeal-type/create'
                    element={
                      <ProtectedRoute resource='appeal.type.create'>
                        <Suspense fallback={<SuspenseFallback isBackground />}>
                          <AppealTypeCreate />
                        </Suspense>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/appeal-type/edit/:_id'
                    element={
                      <ProtectedRoute resource='appeal.type.edit'>
                        <Suspense fallback={<SuspenseFallback isBackground />}>
                          <AppealTypeEdit />
                        </Suspense>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/user-list'
                    element={
                      <ProtectedRoute resource='user.list'>
                        <Suspense fallback={<SuspenseFallback isBackground />}>
                          <QueryParams
                            params={[
                              '_id',
                              'isActive',
                              'name',
                              'position',
                              'role',
                              'contractor',
                              'page',
                              'count',
                            ]}
                          >
                            <UserList queryParams={{}} />
                          </QueryParams>
                        </Suspense>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/user/create'
                    element={
                      <ProtectedRoute resource='user.create'>
                        <Suspense fallback={<SuspenseFallback isBackground />}>
                          <UserCreate />
                        </Suspense>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/user/edit/:_id'
                    element={
                      <ProtectedRoute resource='user.edit'>
                        <Suspense fallback={<SuspenseFallback isBackground />}>
                          <UserEdit />
                        </Suspense>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/password'
                    element={
                      <Suspense fallback={<SuspenseFallback isBackground />}>
                        <Password />
                      </Suspense>
                    }
                  />
                  <Route
                    path='/user/:_id'
                    element={
                      <ProtectedRoute resource='user'>
                        <Suspense fallback={<SuspenseFallback isBackground />}>
                          <User />
                        </Suspense>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/profile'
                    element={
                      <Suspense fallback={<SuspenseFallback isBackground />}>
                        <Profile />
                      </Suspense>
                    }
                  />
                  <Route
                    path='/group-list'
                    element={
                      <ProtectedRoute resource='group'>
                        <Suspense fallback={<SuspenseFallback isBackground />}>
                          <QueryParams params={['_id', 'name', 'isActive', 'page', 'count']}>
                            <GroupList queryParams={{}} />
                          </QueryParams>
                        </Suspense>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/group/create'
                    element={
                      <ProtectedRoute resource='group'>
                        <Suspense fallback={<SuspenseFallback isBackground />}>
                          <GroupCreate />
                        </Suspense>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/group/edit/:_id'
                    element={
                      <ProtectedRoute resource='group'>
                        <Suspense fallback={<SuspenseFallback isBackground />}>
                          <GroupEdit />
                        </Suspense>
                      </ProtectedRoute>
                    }
                  />
                  <Route path='*' element={<NotFound />} />
                </Routes>
              </ProtectedApp>
            </Main>
            <ErrorPopup />
            <ConfirmActionPopup />
          </KeyboardApp>
        </SocketApp>
      </AccessApp>
    </TokenApp>
  </ConfigApp>
);

export default App;
