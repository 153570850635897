import { ReactNode } from 'react';

import HiddenContent from '@shared/ui/roleBasedHiddenContent';

import styles from './index.module.scss';

import { Model } from '../model';

interface IProps {
  children: ReactNode;
}

export const OverviewInvoiceTable = ({ children }: IProps) => {
  const { gridTemplateColumns } = Model();

  return (
    <table className={styles['table']}>
      <thead className={styles['table__header']}>
        <tr className={styles['table__head']} style={{ gridTemplateColumns }}>
          <HiddenContent roleList={['renter']}>
            <th className={styles['table__title-column']}>Контрагент</th>
          </HiddenContent>
          <th className={styles['table__title-column']}>Номер</th>
          <th className={styles['table__title-column']}>Дата</th>
          <th className={styles['table__title-column']}>Сумма</th>
          <th className={styles['table__title-column']}>Статус</th>
        </tr>
      </thead>
      <tbody className={styles['table__body']}>{children}</tbody>
    </table>
  );
};
