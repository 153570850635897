const IconCrossNotification = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
    <path
      d='M17.8441 5.88281C18.4951 5.39453 18.4951 4.60156 17.8441 4.11328C17.193 3.625 16.1357 3.625 15.4847 4.11328L10.0003 8.23047L4.51074 4.11719C3.8597 3.62891 2.80241 3.62891 2.15137 4.11719C1.50033 4.60547 1.50033 5.39844 2.15137 5.88672L7.64095 10L2.15658 14.1172C1.50553 14.6055 1.50553 15.3984 2.15658 15.8867C2.80762 16.375 3.86491 16.375 4.51595 15.8867L10.0003 11.7695L15.4899 15.8828C16.141 16.3711 17.1982 16.3711 17.8493 15.8828C18.5003 15.3945 18.5003 14.6016 17.8493 14.1133L12.3597 10L17.8441 5.88281Z'
      fill='#FF0000'
    />
  </svg>
);

export default IconCrossNotification;
