import Section from '@entities/overview/section';
import Table from '@entities/overview/invoiceTable';
import Item from '@entities/overview/invoiceItem';
import Link from '@shared/ui/link';
import DefaultMessage from '@entities/overview/defaultMessage';
import Loader from '@entities/overview/loader';

import { Model } from '../model';

export const OverviewInvoice = () => {
  const { status, statusText, invoiceList, getStatus } = Model();

  const getContent = () => {
    if (status === undefined) {
      return <Loader isTableElement />;
    }

    if (status === 200) {
      return invoiceList?.length ? (
        invoiceList.map((item) => (
          <Link key={item._id} url={`/invoice/${item._id}`}>
            <Item
              number={item.number}
              date={item.date}
              status={getStatus(item.status)}
              sum={item.sum}
              contractor={item.contractor?.name || '-'}
              onClick={undefined}
            />
          </Link>
        ))
      ) : (
        <DefaultMessage message='Список счетов пуст' isTableElement />
      );
    }

    return <DefaultMessage message={statusText} isTableElement />;
  };

  return (
    <Section title='Последние счета'>
      <Table>{getContent()}</Table>
    </Section>
  );
};
