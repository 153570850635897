import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { userSelector } from '@shared/store/slices/userSlice';
import { configAppSelector } from '@shared/store/slices/configAppSlice';

const nameList: Record<string, string> = {
  admin: 'Администрация',
  director: 'Кабинет руководителя',
  employee: 'Кабинет сотрудника',
  renter: 'Кабинет клиента',
};

export const Model = () => {
  const location = useLocation();

  const { user } = useSelector(userSelector.getState);
  const { menuTextColor, themeColor } = useSelector(configAppSelector.getConfig);

  return {
    role: nameList[user.role.code] || 'Мой кабинет',
    color: location.pathname === '/profile' ? themeColor : menuTextColor,
    colorHover: themeColor,
  };
};
