import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { TThunkDispatch } from '@shared/store';

import { userSelector, login } from '@shared/store/slices/userSlice';

import { IAuthForm } from '@shared/types/interfaces/auth.interface';

export const Model = () => {
  const dispatch = useDispatch<TThunkDispatch>();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IAuthForm>();

  const { isLoading, error } = useSelector(userSelector.getState);

  const onSubmit = (data: IAuthForm) => {
    dispatch(login(data));
  };

  return {
    isLoading,
    errors,
    apiError: error,
    register,
    onSubmit: handleSubmit(onSubmit),
  };
};
