import { IEntity, IEntityWithNumber, TSearchParams } from '@shared/types/global';

type TFilterSearchParams = (
  data: Record<string, string | number | IEntity | IEntityWithNumber | IEntity[]>,
) => TSearchParams;

export const filterSearchParams: TFilterSearchParams = (data) => {
  const result: TSearchParams = {};

  const dataArray = Object.entries(data).filter((item) => {
    const value = item[1];

    if (Array.isArray(value) && !value.length) {
      return false;
    }

    if (typeof value === 'object' && !Array.isArray(value) && !value._id) {
      return false;
    }

    if (typeof value === 'string' && !value) {
      return false;
    }

    return true;
  });

  dataArray.forEach((item) => {
    if (Array.isArray(item[1])) {
      result[item[0]] = item[1].map((itemEntity) => itemEntity._id);
      return;
    }

    if (typeof item[1] === 'object' && !Array.isArray(item[1])) {
      result[item[0]] = item[1]._id;
      return;
    }

    if (typeof item[1] === 'number') {
      result[item[0]] = item[1].toString();
      return;
    }

    // eslint-disable-next-line prefer-destructuring
    result[item[0]] = item[1];
  });

  return result;
};
