import { configureStore, combineReducers, ThunkDispatch, AnyAction } from '@reduxjs/toolkit';

import { middlewares } from './middlewares';

import configAppSlice from './slices/configAppSlice';
import userAncillarySlice from './slices/userAncillarySlice';
import userSlice from './slices/userSlice';
import errorPopupSlice from './slices/errorPopupSlice';
import confirmActionPopupSlice from './slices/confirmActionPopupSlice';
import contractorListSlice from './slices/contractorListSlice';
import claimAncillarySlice from './slices/claimAncillarySlice';
import claimListSlice from './slices/claimListSlice';
import contractAncillarySlice from './slices/contractAncillarySlice';
import contractListSlice from './slices/contractListSlice';
import invoiceAncillarySlice from './slices/invoiceAncillarySlice';
import invoiceListSlice from './slices/invoiceListSlice';
import invoicePeriodListSlice from './slices/invoicePeriodListSlice';
import placementAncillarySlice from './slices/placementAncillarySlice';
import placementListSlice from './slices/placementListSlice';
import appealTypeListSlice from './slices/appealTypeListSlice';
import userListSlice from './slices/userListSlice';
import isButtonDisabledSlice from './slices/isButtonDisabledSlice';
import notificationListSlice from './slices/notificationListSlice';
import groupListSlice from './slices/groupListSlice';
import appealAncillarySlice from './slices/appealAncillarySlice';
import appealCountSlice from './slices/appealCountSlice';
import appealListSlice from './slices/appealListSlice';
import sendingAncillarySlice from './slices/sendingAncillarySlice';
import sendingListSlice from './slices/sendingListSlice';

const rootReducer = combineReducers({
  configApp: configAppSlice,
  userAncillary: userAncillarySlice,
  user: userSlice,
  errorPopup: errorPopupSlice,
  confirmActionPopup: confirmActionPopupSlice,
  contractorList: contractorListSlice,
  claimAncillary: claimAncillarySlice,
  claimList: claimListSlice,
  contractAncillary: contractAncillarySlice,
  contractList: contractListSlice,
  invoiceAncillary: invoiceAncillarySlice,
  invoiceList: invoiceListSlice,
  invoicePeriodList: invoicePeriodListSlice,
  placementAncillary: placementAncillarySlice,
  placementList: placementListSlice,
  appealTypeList: appealTypeListSlice,
  userList: userListSlice,
  isButtonDisabled: isButtonDisabledSlice,
  notificationList: notificationListSlice,
  groupList: groupListSlice,
  appealAncillary: appealAncillarySlice,
  appealCount: appealCountSlice,
  appealList: appealListSlice,
  sendingAncillary: sendingAncillarySlice,
  sendingList: sendingListSlice,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: middlewares,
});

export type TAppDispatch = typeof store.dispatch;
export type TRootState = ReturnType<typeof rootReducer>;
export type TThunkDispatch = ThunkDispatch<TRootState, any, AnyAction>;

export default store;
