import { ReactNode } from 'react';

import Link from '@entities/sidebar/menuItem';

import { Model } from '../model';

interface IProps {
  title: string;
  link: string;
  isAccess: boolean;
  children: ReactNode;
}

export const NavigationLinkAppeal = ({ title, link, isAccess, children }: IProps) => {
  const { count } = Model({ isAccess });

  return (
    <Link title={title} link={link} isAccess={true} count={count}>
      {children}
    </Link>
  );
};
