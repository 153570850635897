import { ReactNode } from 'react';

import { IInvoicePeriodListSlider } from '@shared/store/slices/invoicePeriodListSlice';

import IconLeft from '@shared/assets/icons/icon-nav-arrow-left';
import IconRight from '@shared/assets/icons/icon-nav-arrow-right';

import styles from './index.module.scss';

interface IProps {
  slider: IInvoicePeriodListSlider;
  onChartLeft: () => void;
  onChartRight: () => void;
  children: ReactNode;
}

export const OverviewInvoicePeriodForm = ({
  slider,
  onChartLeft,
  onChartRight,
  children,
}: IProps) => (
  <div className={styles['form']}>
    <div className={styles['form__pagination']}>
      <button
        type='button'
        className={styles['form__btn']}
        disabled={slider.isBlock.left}
        onClick={onChartLeft}
      >
        <IconLeft />
      </button>
      <button
        type='button'
        className={styles['form__btn']}
        disabled={slider.isBlock.right}
        onClick={onChartRight}
      >
        <IconRight />
      </button>
    </div>
    <div className={styles['form__calendar']}>{children}</div>
  </div>
);
