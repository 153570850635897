import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IErrorPopup {
  isOpen: boolean;
  message: string;
  isGoToMain: boolean;
}

const initialState: IErrorPopup = {
  isOpen: false,
  message: '',
  isGoToMain: false,
};

const errorPopupSlice = createSlice({
  name: 'errorPopup',
  initialState: {
    value: initialState,
  },
  reducers: {
    openErrorPopup: (
      state,
      action: PayloadAction<{
        message: IErrorPopup['message'];
        isGoToMain?: IErrorPopup['isGoToMain'];
      }>,
    ) => {
      state.value = {
        isOpen: true,
        message: action.payload.message,
        isGoToMain: action.payload.isGoToMain ? action.payload.isGoToMain : false,
      };
    },
    closeErrorPopup: (state) => {
      state.value.isOpen = false;
    },
  },
});

export const errorPopupSelector = {
  getState: (state: {
    errorPopup: {
      value: IErrorPopup;
    };
  }) => state.errorPopup.value,
};

export const { openErrorPopup, closeErrorPopup } = errorPopupSlice.actions;

export default errorPopupSlice.reducer;
