import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import server from '@shared/utils/server';

import { handleApiError } from '@shared/helpers';

import { AxiosResponse } from 'axios';
import { TApiError } from '@shared/types/global';
import { IConfigApp } from '@shared/types/interfaces/config.interface';

interface IConfigAppState {
  config: IConfigApp;
  isLoading: boolean;
  error: null | TApiError;
}

const initialState: IConfigAppState = {
  config: {
    resources: [],
    logo: null,
    logoBackgroundColor: '',
    headerBackgroundColor: '',
    themeColor: '',
    menuBackgroundColor: '',
    menuTextColor: '',
    copyright: '',
  },
  isLoading: true,
  error: null,
};

export const getConfigAppData = createAsyncThunk<
  AxiosResponse<IConfigApp>,
  void,
  { rejectValue: TApiError }
>('configApp/getConfigAppData', async (data, { rejectWithValue }) => {
  try {
    const res = await server.get('/config');
    return res;
  } catch (err) {
    return rejectWithValue(handleApiError(err));
  }
});

const configAdapter = createEntityAdapter();

const configAppSlice = createSlice({
  name: 'configApp',
  initialState: configAdapter.getInitialState<IConfigAppState>(initialState),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConfigAppData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getConfigAppData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.config = action.payload.data;
      })
      .addCase(getConfigAppData.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const configAppSelector = {
  getConfig: (state: { configApp: IConfigAppState }) => state.configApp.config,
  getResourses: (state: { configApp: IConfigAppState }) => state.configApp.config.resources,
  getState: (state: { configApp: IConfigAppState }) => state.configApp,
};

export default configAppSlice.reducer;
