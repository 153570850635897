import { ReactNode } from 'react';

import styles from './index.module.scss';

interface IProps {
  title: string;
  isAccess: boolean;
  children: ReactNode;
}

export const SidebarMenuSection = ({ title, isAccess, children }: IProps) =>
  isAccess ? (
    <div className={styles['section']}>
      <p className={styles['section__title']}>{title}</p>
      {children}
    </div>
  ) : null;
