import { ReactNode } from 'react';

import styles from './index.module.scss';

interface IProps {
  children: ReactNode;
}

export const OverviewPopularContainer = ({ children }: IProps) => (
  <div className={styles['container']}>
    <div className={styles['container__wrapper']}>{children}</div>
  </div>
);
