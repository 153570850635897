import styles from './index.module.scss';

import { Model } from '../model';

export const AuthenticationSubmitBtn = () => {
  const { themeColor } = Model();

  return (
    <button className={styles['btn']} type='submit' style={{ backgroundColor: themeColor }}>
      Войти
    </button>
  );
};
