import { ReactNode } from 'react';

type TCheckChildren = (children: ReactNode) => boolean;

export const checkChildren: TCheckChildren = (children) => {
  if (!Array.isArray(children)) {
    return !!children;
  }

  for (let i = 0; i < children.length; i++) {
    if (children[i]) {
      return !!children;
    }
  }

  return false;
};
