import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { TThunkDispatch } from '@shared/store';

import { getDefaultStatusText } from '@shared/helpers';

import {
  getInvoiceAncillary,
  invoiceAncillarySelector,
} from '@shared/store/slices/invoiceAncillarySlice';

import { IInvoiceListItemOverview } from '@shared/types/interfaces/invoice.interface';
import { THttpStatusCode } from '@shared/types/interfaces/api.interface';

import api from '../api';

export const Model = () => {
  const dispatch = useDispatch<TThunkDispatch>();

  const { statusList } = useSelector(invoiceAncillarySelector.getState);

  const [status, setStatus] = useState<THttpStatusCode>(undefined);
  const [invoiceList, setInvoiceList] = useState<IInvoiceListItemOverview[] | undefined>(undefined);

  const getStatus = useCallback(
    (id: number) => {
      const status = statusList?.find((item) => item.id === id);

      if (status) {
        return status;
      }

      return { name: '', backgroundColor: '' };
    },
    [statusList],
  );

  useEffect(() => {
    if (!statusList.length) {
      dispatch(getInvoiceAncillary());
    }
  }, [statusList, dispatch]);

  useEffect(() => {
    api
      .getLastInvoiceList()
      .then(({ data }) => {
        setInvoiceList(data.data);
        setStatus(200);
      })
      .catch((res) => {
        if (axios.isAxiosError(res)) {
          setStatus(res.response?.status ? res.response.status : 408);
        }
      });
  }, []);

  return {
    status,
    statusText: getDefaultStatusText(status),
    statusList,
    invoiceList,
    getStatus,
  };
};
