import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useRef } from 'react';

import {
  confirmActionPopupSelector,
  closeConfirmActionPopup,
} from '@shared/store/slices/confirmActionPopupSlice';

export const Model = () => {
  const dispatch = useDispatch();

  const messageRef = useRef<HTMLDivElement | null>(null);

  const { isOpen, message, action } = useSelector(confirmActionPopupSelector.getState);

  const onClick = () => {
    if (action) {
      action();
    }

    dispatch(closeConfirmActionPopup());
  };

  const onClose = () => {
    dispatch(closeConfirmActionPopup());
  };

  const handleClickOutSide = useCallback((evt: MouseEvent) => {
    if (!messageRef.current?.contains(evt.target as Node)) {
      onClose();
    }
  }, []);

  return {
    messageRef,
    isOpen,
    message,
    onClick,
    onClose,
    handleClickOutSide,
  };
};
