import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import server from '@shared/utils/server';

import { handleApiError } from '@shared/helpers';

import { AxiosResponse } from 'axios';
import { IQueryPage, TSearchParams, TApiError } from '@shared/types/global';
import {
  IAppealTypeListItem,
  TAppealTypeListFilter,
} from '@shared/types/interfaces/appeal-type.interface';

interface IAppealTypeList {
  data: IAppealTypeListItem[];
  isOpenFilter: boolean;
  filter: TAppealTypeListFilter;
  page: number;
  count: number;
  total: number;
  totalPage: number;
  isLoading: boolean;
  error: TApiError | null;
}

interface IAppealTypeListResponse extends IQueryPage {
  data: IAppealTypeListItem[];
  total: number;
}

type TAppealTypeListResponse = AxiosResponse<IAppealTypeListResponse>;

export const initialAppealTypeListFilter: TAppealTypeListFilter = {
  _id: '',
  name: '',
  isActive: { _id: '', name: '' },
  page: 1,
  count: 30,
};

const initialState: IAppealTypeList = {
  data: [],
  isOpenFilter: false,
  filter: initialAppealTypeListFilter,
  page: 1,
  totalPage: 1,
  count: 0,
  total: 0,
  isLoading: false,
  error: null,
};

export const getAppealTypeListData = createAsyncThunk<
  TAppealTypeListResponse,
  TSearchParams,
  { rejectValue: TApiError }
>('appealTypeList/getAppealTypeListData', async (params, { rejectWithValue }) => {
  try {
    const res = await server.get('/appeal-type/list', { params });
    return res;
  } catch (err) {
    return rejectWithValue(handleApiError(err));
  }
});

const appealTypeListAdapter = createEntityAdapter();

const appealTypeListSlice = createSlice({
  name: 'appealTypeList',
  initialState: appealTypeListAdapter.getInitialState<IAppealTypeList>(initialState),
  reducers: {
    handleOpenFilter: (state) => {
      state.isOpenFilter = !state.isOpenFilter;
    },
    changeFilter: (state, action: PayloadAction<Partial<TAppealTypeListFilter>>) => {
      state.filter = { ...state.filter, ...action.payload };
    },
    resetFilter: (state) => {
      state.filter = initialAppealTypeListFilter;
    },
    resetAppealTypeList: (state) => {
      state.data = [];
      state.isOpenFilter = false;
      state.filter = initialAppealTypeListFilter;
      state.page = 1;
      state.totalPage = 1;
      state.count = 0;
      state.total = 0;
      state.isLoading = false;
      state.error = null;
    },
    resetAppealTypeListItem: (state, action: PayloadAction<string>) => {
      state.data = state.data.filter((item) => item._id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAppealTypeListData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAppealTypeListData.fulfilled, (state, action) => {
        const { data, page, count, total } = action.payload.data;

        state.isLoading = false;
        state.data = data;
        state.page = page;
        state.count = count;
        state.total = total;
        state.totalPage = Math.ceil(total / count);
      })
      .addCase(getAppealTypeListData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload ?? null;
      });
  },
});

export const appealTypeListSelector = {
  getState: (state: { appealTypeList: IAppealTypeList }) => state.appealTypeList,
  getData: (state: { appealTypeList: IAppealTypeList }) => state.appealTypeList.data,
  getFilter: (state: { appealTypeList: IAppealTypeList }) => state.appealTypeList.filter,
};

export const {
  handleOpenFilter,
  changeFilter,
  resetFilter,
  resetAppealTypeList,
  resetAppealTypeListItem,
} = appealTypeListSlice.actions;

export type { TAppealTypeListResponse };

export default appealTypeListSlice.reducer;
