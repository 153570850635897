const IconOffice = () => (
  <svg viewBox='0 0 20 16' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5 7V9H15V7C15 5.89687 15.8969 5 17 5H18C18 3.34375 16.6562 2 15 2H5C3.34375 2 2 3.34375 2 5H3C4.10313 5 5 5.89687 5 7ZM18 6H17C16.4469 6 16 6.44688 16 7V10H4V7C4 6.44688 3.55312 6 3 6H2C0.896875 6 0 6.89687 0 8C0 8.7375 0.40625 9.375 1 9.72188V13.5C1 13.775 1.225 14 1.5 14H3.5C3.775 14 4 13.775 4 13.5V13H16V13.5C16 13.775 16.225 14 16.5 14H18.5C18.775 14 19 13.775 19 13.5V9.72188C19.5938 9.375 20 8.7375 20 8C20 6.89687 19.1031 6 18 6Z'
      fill='inherit'
    />
  </svg>
);

export default IconOffice;
