const IconUsers = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 22'>
    <path
      d='M4.7 7C4.7 5.93913 5.07928 4.92172 5.75442 4.17157C6.42955 3.42143 7.34522 3 8.3 3C9.25478 3 10.1705 3.42143 10.8456 4.17157C11.5207 4.92172 11.9 5.93913 11.9 7C11.9 8.06087 11.5207 9.07828 10.8456 9.82843C10.1705 10.5786 9.25478 11 8.3 11C7.34522 11 6.42955 10.5786 5.75442 9.82843C5.07928 9.07828 4.7 8.06087 4.7 7ZM2 18.0719C2 14.9937 4.24438 12.5 7.01469 12.5H9.58531C12.3556 12.5 14.6 14.9937 14.6 18.0719C14.6 18.5844 14.2259 19 13.7647 19H2.83531C2.37406 19 2 18.5844 2 18.0719ZM19.1366 19H15.2581C15.41 18.7063 15.5 18.3656 15.5 18V17.75C15.5 15.8531 14.7378 14.15 13.5369 13.0063C13.6044 13.0031 13.6691 13 13.7366 13H15.4634C17.9694 13 20 15.2562 20 18.0406C20 18.5719 19.6119 19 19.1366 19ZM14.15 11C13.2781 11 12.4906 10.6063 11.9197 9.97188C12.4738 9.14063 12.8 8.1125 12.8 7C12.8 6.1625 12.6144 5.37188 12.2853 4.67813C12.8084 4.25313 13.4525 4 14.15 4C15.8909 4 17.3 5.56562 17.3 7.5C17.3 9.43438 15.8909 11 14.15 11Z'
      fill='inherit'
    />
  </svg>
);

export default IconUsers;
