const IconProfieLink = () => (
  <svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17.7778 3.125C18.0833 3.125 18.3333 3.40625 18.3333 3.75V16.25C18.3333 16.5938 18.0833 16.875 17.7778 16.875H2.22222C1.91667 16.875 1.66667 16.5938 1.66667 16.25V3.75C1.66667 3.40625 1.91667 3.125 2.22222 3.125H17.7778ZM2.22222 1.25C0.996528 1.25 0 2.37109 0 3.75V16.25C0 17.6289 0.996528 18.75 2.22222 18.75H17.7778C19.0035 18.75 20 17.6289 20 16.25V3.75C20 2.37109 19.0035 1.25 17.7778 1.25H2.22222ZM7.22222 10C7.81159 10 8.37682 9.73661 8.79357 9.26777C9.21032 8.79893 9.44444 8.16304 9.44444 7.5C9.44444 6.83696 9.21032 6.20107 8.79357 5.73223C8.37682 5.26339 7.81159 5 7.22222 5C6.63285 5 6.06762 5.26339 5.65087 5.73223C5.23413 6.20107 5 6.83696 5 7.5C5 8.16304 5.23413 8.79893 5.65087 9.26777C6.06762 9.73661 6.63285 10 7.22222 10ZM6.11111 11.25C4.57639 11.25 3.33333 12.6484 3.33333 14.375C3.33333 14.7188 3.58333 15 3.88889 15H10.5556C10.8611 15 11.1111 14.7188 11.1111 14.375C11.1111 12.6484 9.86806 11.25 8.33333 11.25H6.11111ZM13.0556 5.625C12.5938 5.625 12.2222 6.04297 12.2222 6.5625C12.2222 7.08203 12.5938 7.5 13.0556 7.5H15.8333C16.2951 7.5 16.6667 7.08203 16.6667 6.5625C16.6667 6.04297 16.2951 5.625 15.8333 5.625H13.0556ZM13.0556 9.375C12.5938 9.375 12.2222 9.79297 12.2222 10.3125C12.2222 10.832 12.5938 11.25 13.0556 11.25H15.8333C16.2951 11.25 16.6667 10.832 16.6667 10.3125C16.6667 9.79297 16.2951 9.375 15.8333 9.375H13.0556Z'
      fill='inherit'
    />
  </svg>
);

export default IconProfieLink;
