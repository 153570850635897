import { Helmet } from 'react-helmet-async';

import Section from '@shared/ui/section';

import Placement from '@widgets/overviewPlacement';
import InvoicePeriod from '@widgets/overviewInvoicePeriod';
import Popular from '@widgets/overviewPopular';
import Invoice from '@widgets/overviewInvoice';

import styles from './index.module.scss';

export const Home = () => (
  <>
    <Helmet title='Личный кабинет' />
    <Section isBackground={false}>
      <div className={styles['home']}>
        <div className={styles['home__top']}>
          <Placement />
        </div>
        <div className={styles['home__middle']}>
          <InvoicePeriod />
        </div>
        <div className={styles['home__bottom']}>
          <Popular />
          <Invoice />
        </div>
      </div>
    </Section>
  </>
);
