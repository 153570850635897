const IconExitProfile = () => (
  <svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_326_3556)'>
      <path
        d='M11.25 1.25C11.25 0.558594 10.6914 0 10 0C9.30859 0 8.75 0.558594 8.75 1.25V10C8.75 10.6914 9.30859 11.25 10 11.25C10.6914 11.25 11.25 10.6914 11.25 10V1.25ZM5.60547 4.71094C6.13672 4.26953 6.20703 3.48047 5.76562 2.94922C5.32422 2.41797 4.53516 2.34766 4.00391 2.78906C1.94141 4.50781 0.625 7.10156 0.625 10C0.625 15.1758 4.82422 19.375 10 19.375C15.1758 19.375 19.375 15.1758 19.375 10C19.375 7.10156 18.0547 4.50781 15.9922 2.78906C15.4609 2.34766 14.6719 2.42188 14.2305 2.94922C13.7891 3.47656 13.8633 4.26953 14.3906 4.71094C15.9102 5.97266 16.8711 7.875 16.8711 10C16.8711 13.7969 13.793 16.875 9.99609 16.875C6.19922 16.875 3.12109 13.7969 3.12109 10C3.12109 7.875 4.08594 5.97266 5.60156 4.71094H5.60547Z'
        fill='inherit'
      />
    </g>
    <defs>
      <clipPath id='clip0_326_3556'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconExitProfile;
