import Popup from '@shared/ui/popup';
import ErrorMessage from '@entities/errorMessage';

import { Model } from '../model';

export const ErrorPopup = () => {
  const { isOpen, message, onClose } = Model();

  return (
    <Popup isOpen={isOpen}>
      <ErrorMessage isOpen={isOpen} message={message} onClick={onClose} />
    </Popup>
  );
};
