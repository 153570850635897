import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IConfirmActionPopup {
  isOpen: boolean;
  message: string;
  action: (() => void) | undefined;
}

const initialState: IConfirmActionPopup = {
  isOpen: false,
  message: '',
  action: undefined,
};

const confirmActionPopup = createSlice({
  name: 'confirmActionPopup',
  initialState: {
    value: initialState,
  },
  reducers: {
    openConfirmActionPopup: (
      state,
      action: PayloadAction<{
        message: IConfirmActionPopup['message'];
        action: IConfirmActionPopup['action'];
      }>,
    ) => {
      state.value = {
        isOpen: true,
        message: action.payload.message,
        action: action.payload.action,
      };
    },
    closeConfirmActionPopup: (state) => {
      state.value.isOpen = false;
    },
  },
});

export const confirmActionPopupSelector = {
  getState: (state: {
    confirmActionPopup: {
      value: IConfirmActionPopup;
    };
  }) => state.confirmActionPopup.value,
};

export const { openConfirmActionPopup, closeConfirmActionPopup } = confirmActionPopup.actions;

export default confirmActionPopup.reducer;
