import { useEffect, useRef, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { TThunkDispatch } from '@shared/store';

import { userSelector, logout } from '@shared/store/slices/userSlice';
import { resetPeriodListData } from '@shared/store/slices/invoicePeriodListSlice';

import socket from '@shared/utils/socket.io';

export const Model = () => {
  const location = useLocation();
  const dispatch = useDispatch<TThunkDispatch>();

  const { isAuth } = useSelector(userSelector.getState);

  const [isOpenCard, setIsOpenCard] = useState<boolean>(false);

  const profileCardRef = useRef<HTMLDivElement | null>(null);
  const btnRef = useRef<HTMLButtonElement | null>(null);

  const handleChangeOpen = () => {
    setIsOpenCard(!isOpenCard);
  };

  const handleCloseOutside = useCallback((evt: MouseEvent) => {
    if (
      !profileCardRef.current?.contains(evt.target as Node) &&
      !btnRef.current?.contains(evt.target as Node)
    ) {
      setIsOpenCard(false);
    }
  }, []);

  const handleExit = () => {
    dispatch(logout()).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        socket.disconnect();

        dispatch(resetPeriodListData());
      }
    });
  };

  useEffect(() => {
    if (isOpenCard) {
      document.addEventListener('click', handleCloseOutside, true);
    } else {
      document.removeEventListener('click', handleCloseOutside, true);
    }
  }, [isOpenCard, handleCloseOutside]);

  useEffect(() => {
    setIsOpenCard(false);
  }, [location.pathname]);

  return {
    isAuth,
    isOpenCard,
    profileCardRef,
    btnRef,
    handleChangeOpen,
    handleExit,
  };
};
