import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import server from '@shared/utils/server';

import { handleApiError } from '@shared/helpers';

import { AxiosResponse } from 'axios';
import { IQueryPage, TSearchParams, TApiError } from '@shared/types/global';
import {
  IContractorListItem,
  TContractorListFilter,
} from '@shared/types/interfaces/contractor.interface';

interface IContractorList {
  data: IContractorListItem[];
  isOpenFilter: boolean;
  filter: TContractorListFilter;
  page: number;
  count: number;
  total: number;
  totalPage: number;
  isLoading: boolean;
  error: TApiError | null;
}

interface IContractorListResponse extends IQueryPage {
  data: IContractorListItem[];
  total: number;
}

type TContractorListResponse = AxiosResponse<IContractorListResponse>;

export const initialContractorListFilter: TContractorListFilter = {
  _id: '',
  name: '',
  inn: '',
  owners: [],
  page: 1,
  count: 30,
};

const initialState: IContractorList = {
  data: [],
  isOpenFilter: false,
  filter: initialContractorListFilter,
  page: 1,
  totalPage: 1,
  count: 0,
  total: 0,
  isLoading: true,
  error: null,
};

export const getContractorListData = createAsyncThunk<
  TContractorListResponse,
  TSearchParams,
  { rejectValue: TApiError }
>('contractorList/getContractorListData', async (params, { rejectWithValue }) => {
  try {
    const res = await server.get('/contractor/list', { params });
    return res;
  } catch (err) {
    return rejectWithValue(handleApiError(err));
  }
});

const contractorListAdapter = createEntityAdapter();

const contractorListSlice = createSlice({
  name: 'contractorList',
  initialState: contractorListAdapter.getInitialState<IContractorList>(initialState),
  reducers: {
    handleOpenFilter: (state) => {
      state.isOpenFilter = !state.isOpenFilter;
    },
    changeFilter: (state, action: PayloadAction<Partial<TContractorListFilter>>) => {
      state.filter = { ...state.filter, ...action.payload };
    },
    resetFilter: (state) => {
      state.filter = initialContractorListFilter;
    },
    resetContractorList: (state) => {
      state.data = [];
      state.isOpenFilter = false;
      state.filter = initialContractorListFilter;
      state.page = 1;
      state.totalPage = 1;
      state.count = 0;
      state.total = 0;
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContractorListData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getContractorListData.fulfilled, (state, action) => {
        const { data, page, count, total } = action.payload.data;

        state.isLoading = false;
        state.data = data;
        state.page = page;
        state.count = count;
        state.total = total;
        state.totalPage = Math.ceil(total / count);
      })
      .addCase(getContractorListData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload ?? null;
      });
  },
});

export const contractorListSelector = {
  getState: (state: { contractorList: IContractorList }) => state.contractorList,
  getData: (state: { contractorList: IContractorList }) => state.contractorList.data,
  getFilter: (state: { contractorList: IContractorList }) => state.contractorList.filter,
};

export const { handleOpenFilter, changeFilter, resetFilter, resetContractorList } =
  contractorListSlice.actions;

export type { TContractorListResponse };

export default contractorListSlice.reducer;
