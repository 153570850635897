import { ReactNode, RefObject, MouseEventHandler } from 'react';

import IconCalendar from '@shared/assets/icons/icon-calendar';
import IconClose from '@shared/assets/icons/icon-close';

import { getDate } from '@shared/helpers';

import styles from './index.module.scss';

interface IProps {
  startId: string;
  endId: string;
  startValue: string;
  endValue: string;
  inputStartRef: RefObject<HTMLDivElement>;
  inputEndRef: RefObject<HTMLDivElement>;
  isColorGray?: boolean;
  isCloseBtnPositionLeft?: boolean;
  onClick: MouseEventHandler<HTMLDivElement>;
  onReset?: () => void;
  children: ReactNode;
}

export const InputPeriod = ({
  startId,
  endId,
  startValue,
  endValue,
  inputStartRef,
  inputEndRef,
  isColorGray,
  isCloseBtnPositionLeft,
  onClick,
  onReset,
  children,
}: IProps) => {
  const startDate = getDate(startValue, true);
  const endDate = getDate(endValue, true);

  return (
    <div className={styles['period']}>
      <div
        ref={inputStartRef}
        className={`${styles['period__field']} ${
          isColorGray && styles['period__field_color_gray']
        }`}
        onClick={onClick}
      >
        <input
          className={`${styles['period__input']} ${
            isColorGray && styles['period__input_color_gray']
          }`}
          id={startId}
          type='text'
          value={startDate || ''}
          placeholder='от'
          autoComplete='off'
          readOnly
        />
        <div className={styles['period__icon']}>
          <IconCalendar />
        </div>
      </div>
      <div
        ref={inputEndRef}
        className={`${styles['period__field']} ${
          isColorGray && styles['period__field_color_gray']
        }`}
        onClick={onClick}
      >
        <input
          className={`${styles['period__input']} ${
            isColorGray && styles['period__input_color_gray']
          }`}
          id={endId}
          type='text'
          value={endDate || ''}
          placeholder='до'
          autoComplete='off'
          readOnly
        />
        <div className={styles['period__icon']}>
          <IconCalendar />
        </div>
      </div>
      {onReset ? (
        <button
          type='button'
          className={`${styles['period__btn-close']} ${
            isCloseBtnPositionLeft && styles['period__btn-close_position_left']
          } ${
            (startDate || endDate) &&
            (isCloseBtnPositionLeft
              ? styles['period__btn-close_visible_left']
              : styles['period__btn-close_visible_right'])
          }`}
          onClick={onReset}
        >
          <IconClose />
        </button>
      ) : null}
      {children}
    </div>
  );
};
