// Source from /app/styles/abstracts/_colors.scss
// Both files should duplicate each other!

const white = '#ffffff';
const white2 = '#FFF8EC';
const white3 = '#F9E7EB';

const black = '#000000';
const black2 = 'rgba(0, 0, 0, 0.7)';
const black3 = 'rgba(0, 0, 0, 0.5)';
const black4 = 'rgba(0, 0, 0, 0.08)';
const black5 = 'rgba(0, 0, 0, 0.25)';
const black6 = '#3f3f3f';
const black7 = '#4d4d4d';

const gray = '#f2f7f8';
const gray2 = 'rgba(0, 0, 0, 0.1)';
const gray3 = '#eaeaea';
const gray4 = '#e6e6e6';
const gray5 = 'rgba(255, 255, 255, 0.1)';
const gray6 = 'rgba(255, 255, 255, 0.5)';
const gray7 = '#999999';
const gray8 = '#d6d6d6';
const gray9 = '#acacac';
const gray10 = '#f3f3f3';
const gray11 = '#f2f7f8';
const gray12 = '#d2d2d2';
const gray13 = '#808080';
const gray14 = '#939599';

const yellow = '#fcd501';
const yellow2 = '#FFD700';

const red = 'red';
const red2 = '#f62d51';

const green = '#55ce63';
const green2 = '#E8FDEB';

const purple = '#7460ee';
const purple2 = '#6C46D8';
const purple3 = '#C039CC';

const brown = '#CD9575';

const blue = '#CFECFE';

const orange = '#FFBC34';

export default {
  // white
  white,
  white2,
  white3,
  // black
  black,
  black2,
  black3,
  black4,
  black5,
  black6,
  black7,
  // gray
  gray,
  gray2,
  gray3,
  gray4,
  gray5,
  gray6,
  gray7,
  gray8,
  gray9,
  gray10,
  gray11,
  gray12,
  gray13,
  gray14,
  // yellow
  yellow,
  yellow2,
  // red
  red,
  red2,
  // green
  green,
  green2,
  // purple
  purple,
  purple2,
  purple3,
  // brown
  brown,
  // blue
  blue,
  // orange
  orange,
};
