import { Children, ReactElement, cloneElement } from 'react';
import { useSearchParams } from 'react-router-dom';

interface IProps {
  params: string[];
  paramsArray?: string[];
  children: ReactElement;
}

const QueryParams = ({ params, paramsArray, children }: IProps) => {
  const [searchParams] = useSearchParams();

  const getParams = () => {
    const result: Record<string, string | null | string[]> = {};

    params.forEach((item) => {
      result[`${item}`] = searchParams.get(item);
    });

    if (paramsArray) {
      paramsArray.forEach((item) => {
        result[`${item}`] = searchParams.getAll(item);
      });
    }

    return result;
  };

  return cloneElement(Children.only(children), { queryParams: getParams() });
};

export default QueryParams;
