const IconRoom = () => (
  <svg viewBox='0 0 22 22' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M2.75 19.25V17.875H4.85833V2.75H13.7958V3.78125H17.1646V17.875H19.25V19.25H15.7896V5.15625H13.7958V19.25H2.75ZM10.3354 11.9167C10.5951 11.9167 10.8128 11.8288 10.9885 11.6531C11.1642 11.4774 11.2521 11.2597 11.2521 11C11.2521 10.7403 11.1642 10.5226 10.9885 10.3469C10.8128 10.1712 10.5951 10.0833 10.3354 10.0833C10.0757 10.0833 9.85799 10.1712 9.68229 10.3469C9.5066 10.5226 9.41875 10.7403 9.41875 11C9.41875 11.2597 9.5066 11.4774 9.68229 11.6531C9.85799 11.8288 10.0757 11.9167 10.3354 11.9167ZM6.23333 17.875H12.4208V4.125H6.23333V17.875Z'
      fill='inherit'
    />
  </svg>
);

export default IconRoom;
