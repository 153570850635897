import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { errorPopupSelector, closeErrorPopup } from '@shared/store/slices/errorPopupSlice';

export const Model = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isOpen, message, isGoToMain } = useSelector(errorPopupSelector.getState);

  const onClose = () => {
    if (isGoToMain) {
      navigate('/');
    }

    dispatch(closeErrorPopup());
  };

  return {
    isOpen,
    message,
    onClose,
  };
};
