import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import server from 'shared/utils/server';

import { handleApiError } from '@shared/helpers';

import { AxiosResponse } from 'axios';
import { IEntity, TApiError } from '@shared/types/global';

interface IUserAncillary {
  roleList: IEntity[];
  isLoading: boolean;
  error: TApiError | null;
}

const initialState: IUserAncillary = {
  roleList: [],
  isLoading: false,
  error: null,
};

export const getUserAncillary = createAsyncThunk<
  AxiosResponse<Pick<IUserAncillary, 'roleList'>>,
  undefined,
  { rejectValue: TApiError }
>('userAncillary/getUserAncillary', async (params, { rejectWithValue }) => {
  try {
    const res = await server.get<Pick<IUserAncillary, 'roleList'>>('/user/ancillary', {
      params,
    });
    return res;
  } catch (err) {
    return rejectWithValue(handleApiError(err));
  }
});

const userAncillaryAdapter = createEntityAdapter();

const userAncillarySlice = createSlice({
  name: 'userAncillary',
  initialState: userAncillaryAdapter.getInitialState<IUserAncillary>(initialState),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserAncillary.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getUserAncillary.fulfilled, (state, action) => {
        const { roleList } = action.payload.data;

        state.isLoading = false;
        state.roleList = roleList;
      })
      .addCase(getUserAncillary.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload ?? null;
      });
  },
});

export const userAncillarySelector = {
  getState: (state: { userAncillary: IUserAncillary }) => state.userAncillary,
};

export default userAncillarySlice.reducer;
