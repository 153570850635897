import Card from '@entities/overview/placementCard';
import Link from '@shared/ui/link';

import IconAuxiliary from '@shared/assets/icons/icon-auxiliary';
import IconShop from '@shared/assets/icons/icon-shop';
import IconWarehouse from '@shared/assets/icons/icon-warehouse';
import IconShelf from '@shared/assets/icons/icon-shelf';
import IconOffice from '@shared/assets/icons/icon-office';

import colors from '@shared/assets/colors';

import styles from './index.module.scss';

import { Model } from '../model';

export const OverviewPlacement = () => {
  const { placementOverviewList } = Model();

  return (
    <div className={styles['overview-placement']}>
      <Link url='/placement-list?&page=1&count=20&type=2'>
        <Card
          title='Арендуемые склады'
          use={placementOverviewList.warehouse.inUse}
          free={placementOverviewList.warehouse.isFree}
          color={colors.green}
        >
          <IconWarehouse />
        </Card>
      </Link>
      <Link url='/placement-list?&page=1&count=20&type=1'>
        <Card
          title='Арендуемые офисы'
          use={placementOverviewList.office.inUse}
          free={placementOverviewList.office.isFree}
          color={colors.yellow2}
        >
          <IconOffice />
        </Card>
      </Link>
      <Link url='/placement-list?&page=1&count=20&type=4'>
        <Card
          title='Мезонин'
          use={placementOverviewList.mezzanine.inUse}
          free={placementOverviewList.mezzanine.isFree}
          color={colors.purple3}
        >
          <IconShelf />
        </Card>
      </Link>
      <Link url='/placement-list?&page=1&count=20&type=3'>
        <Card
          title='Торговые помещения'
          use={placementOverviewList.shop.inUse}
          free={placementOverviewList.shop.isFree}
          color={colors.brown}
        >
          <IconShop />
        </Card>
      </Link>
      <Link url='/placement-list?&page=1&count=20&type=5'>
        <Card
          title='Вспомогательные помещения'
          use={placementOverviewList.auxiliary.inUse}
          free={placementOverviewList.auxiliary.isFree}
          color={colors.purple2}
        >
          <IconAuxiliary />
        </Card>
      </Link>
    </div>
  );
};
