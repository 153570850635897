import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import { userSelector } from '@shared/store/slices/userSlice';

const ProtectedApp = ({ children }: { children: ReactNode }) => {
  const location = useLocation();

  const { isAuth } = useSelector(userSelector.getState);

  if (isAuth === false && location.pathname === '/auth') {
    return children;
  }

  if (isAuth === false) {
    return <Navigate to='/auth' />;
  }

  if (isAuth === true && location.pathname === '/auth') {
    return <Navigate to='/' />;
  }

  return isAuth === true && children;
};

export default ProtectedApp;
