const IconDocument = () => (
  <svg viewBox='0 0 22 22' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8.9165 8.24967H13.9582L8.9165 3.20801V8.24967ZM2.49984 1.83301H9.83317L15.3332 7.33301V18.333C15.3332 18.8192 15.14 19.2856 14.7962 19.6294C14.4524 19.9732 13.9861 20.1663 13.4998 20.1663H2.49984C1.48234 20.1663 0.666504 19.3413 0.666504 18.333V3.66634C0.666504 2.64884 1.48234 1.83301 2.49984 1.83301ZM10.7498 16.4997V14.6663H2.49984V16.4997H10.7498ZM13.4998 12.833V10.9997H2.49984V12.833H13.4998Z'
      fill='inherit'
    />
  </svg>
);

export default IconDocument;
