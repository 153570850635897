import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TThunkDispatch } from '@shared/store';

import { appealCountSelector, getAppealCount } from '@shared/store/slices/appealCountSlice';
import { userSelector } from '@shared/store/slices/userSlice';

interface IProps {
  isAccess: boolean;
}

export const Model = ({ isAccess }: IProps) => {
  const dispatch = useDispatch<TThunkDispatch>();

  const { count } = useSelector(appealCountSelector.getState);
  const { user } = useSelector(userSelector.getState);

  const getCount = useCallback(() => {
    dispatch(getAppealCount());
  }, [dispatch]);

  useEffect(() => {
    if (isAccess) {
      getCount();

      const intervalId = setInterval(() => {
        getCount();
      }, 60000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [user._id, getCount]);

  return {
    count,
  };
};
