import { UseFormRegisterReturn } from 'react-hook-form';

import colors from '@shared/assets/colors';

import styles from './index.module.scss';

import { Model } from '../model';

interface IProps {
  id: string;
  register: UseFormRegisterReturn;
  minLength?: number;
  maxLength?: number;
  isError?: boolean;
}

export const AuthenticationInputText = ({
  id,
  register,
  minLength,
  maxLength,
  isError,
}: IProps) => {
  const { borderColor } = Model();

  return (
    <input
      className={`${styles['input']} ${isError && styles['input_type_error']}`}
      type='text'
      id={id}
      {...register}
      placeholder='Введите логин'
      style={{ borderColor: !isError ? borderColor : colors.red }}
      minLength={minLength}
      maxLength={maxLength}
    ></input>
  );
};
