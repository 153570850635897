import Menu from '@entities/sidebar/menu';
import RoleBtn from '@entities/sidebar/roleBtn';
import Link from '@shared/ui/link';
import Navigation from '@features/navigation';
import AppealLink from '@features/navigation-link-appeal';
import ClaimLink from '@features/navigation-link-claim';

import { Model } from '../model';

export const Sidebar = () => {
  const { isAuth } = Model();

  return (
    <Menu>
      {isAuth && (
        <>
          <Link url='/profile'>
            <RoleBtn onClick={undefined} />
          </Link>
          <Navigation AppealLink={AppealLink} ClaimLink={ClaimLink} />
        </>
      )}
    </Menu>
  );
};
