import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { userSelector } from '@shared/store/slices/userSlice';

interface IProps {
  roleList: ('director' | 'employee' | 'renter')[];
}

export const Model = ({ roleList }: IProps) => {
  const roleCode = useSelector(userSelector.getRoleCode);

  const isHiddenMemo = useMemo(
    () => !!roleList.find((item) => item === roleCode),
    [roleCode, roleList],
  );

  return {
    isHidden: isHiddenMemo,
  };
};
