import { useRef } from 'react';
import { Transition } from 'react-transition-group';

import Button from '@shared/ui/button';
import IconErrorCircle from '@shared/assets/icons/icon-error-circle';

import colors from '@shared/assets/colors';

import styles from './index.module.scss';

interface IProps {
  isOpen: boolean;
  message: string;
  onClick: () => void;
}

export const ErrorMessage = ({ isOpen, message, onClick }: IProps) => {
  const messageRef = useRef<HTMLDivElement | null>(null);

  return (
    <Transition nodeRef={messageRef} in={isOpen} timeout={400} mountOnEnter unmountOnExit>
      {(state) => (
        <div ref={messageRef} className={`${styles['message']} ${styles[`message-${state}`]}`}>
          <div className={styles['message__container']}>
            <div className={styles['message__icon']}>
              <IconErrorCircle />
            </div>
            <p className={styles['message__text']}>{message}</p>
            <Button color={colors.green} text='Ок' type='button' onClick={onClick} minWidth={230} />
          </div>
        </div>
      )}
    </Transition>
  );
};
