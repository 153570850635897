import { useRef } from 'react';
import { Transition } from 'react-transition-group';

import Input from '@shared/ui/inputPeriod';
import Frame from '@shared/ui/inputFrame';
import CalendarMui from '@shared/ui/calendarMui';

import styles from './index.module.scss';

import { Model } from '../model';

interface IProps {
  startId: string;
  endId: string;
  startValue: string;
  endValue: string;
  isFramePositionTop?: boolean;
  isColorGray?: boolean;
  isCloseBtnPositionLeft?: boolean;
  selectStart: (data: string) => void;
  selectEnd: (data: string) => void;
  resetValues?: () => void;
}

export const InputPeriod = ({
  startId,
  endId,
  startValue,
  endValue,
  isFramePositionTop,
  isColorGray,
  isCloseBtnPositionLeft,
  selectStart,
  selectEnd,
  resetValues,
}: IProps) => {
  const {
    isCalendarVisible,
    frameRef,
    inputStartRef,
    inputEndRef,
    type,
    handleChangeVisible,
    onSelect,
  } = Model({ selectStart, selectEnd });

  const calendarRef = useRef<HTMLDivElement | null>(null);

  const getPositionFrame = () => {
    if (isFramePositionTop) {
      return {
        top: undefined,
        bottom: 52,
      };
    }

    return {
      top: 52,
      bottom: undefined,
    };
  };

  return (
    <Input
      startId={startId}
      endId={endId}
      inputStartRef={inputStartRef}
      inputEndRef={inputEndRef}
      startValue={startValue}
      endValue={endValue}
      isColorGray={isColorGray}
      isCloseBtnPositionLeft={isCloseBtnPositionLeft}
      onClick={handleChangeVisible}
      onReset={resetValues}
    >
      {isCalendarVisible && (
        <Frame {...getPositionFrame()} frameRef={frameRef}>
          <Transition nodeRef={calendarRef} appear in timeout={400}>
            {(state) => (
              <div
                ref={calendarRef}
                className={`${styles['calendar']} ${
                  isFramePositionTop
                    ? styles[`calendar_position_bottom-${state}`]
                    : styles[`calendar_position_top-${state}`]
                }`}
              >
                <CalendarMui value={type === 'start' ? startValue : endValue} onClick={onSelect} />
              </div>
            )}
          </Transition>
        </Frame>
      )}
    </Input>
  );
};
