import HiddenContent from '@shared/ui/roleBasedHiddenContent';

import { getDate } from '@shared/helpers';

import styles from './index.module.scss';

import { Model } from '../model';

interface IProps {
  number: string;
  date: string;
  status: { name: string; backgroundColor: string };
  sum: number;
  contractor: string;
  onClick: (() => void) | undefined;
}

export const OverviewInvoiceItem = ({ number, date, status, sum, contractor, onClick }: IProps) => {
  const { currency, gridTemplateColumns } = Model({ sum });

  return (
    <tr className={styles['item']} style={{ gridTemplateColumns }} onClick={onClick}>
      <HiddenContent roleList={['renter']}>
        <td className={styles['item__text']} title={contractor}>
          {contractor}
        </td>
      </HiddenContent>
      <td className={styles['item__text']} title={number}>
        {number}
      </td>
      <td className={styles['item__text']}>{getDate(date, true)}</td>
      <td className={styles['item__text']} title={currency}>
        {currency}
      </td>
      <td className={styles['item__text']}>
        <div className={styles['item__status']} style={{ backgroundColor: status.backgroundColor }}>
          <p className={styles['item__status-text']}>{status.name}</p>
        </div>
      </td>
    </tr>
  );
};
