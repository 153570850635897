import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { configAppSelector } from '@shared/store/slices/configAppSlice';

interface IProps {
  link: string;
}

export const Model = ({ link }: IProps) => {
  const location = useLocation();

  const configApp = useSelector(configAppSelector.getConfig);

  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    if (location.pathname === link) {
      setIsActive(true);
      return;
    }

    setIsActive(false);
  }, [location.pathname, link]);

  return {
    isActive,
    textColor: configApp.menuTextColor,
    activeColor: configApp.themeColor,
  };
};
