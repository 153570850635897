import { useRef } from 'react';
import { Transition } from 'react-transition-group';

import styles from './index.module.scss';

interface IProps {
  message: string | undefined;
}

export const AuthenticationError = ({ message }: IProps) => {
  const errorRef = useRef<HTMLParagraphElement | null>(null);

  return (
    <Transition nodeRef={errorRef} appear in timeout={400} mountOnEnter unmountOnExit>
      {(state) => (
        <p ref={errorRef} className={`${styles['error']} ${styles[`error-${state}`]}`}>
          {message}
        </p>
      )}
    </Transition>
  );
};
