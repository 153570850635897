import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import server from '@shared/utils/server';

import { handleApiError } from '@shared/helpers';

import { AxiosResponse } from 'axios';
import { IQueryPage, TSearchParams, IStatus, TApiError } from '@shared/types/global';
import {
  IContractListItem,
  TContractListFilter,
} from '@shared/types/interfaces/contract.interface';

interface IContractList {
  data: IContractListItem[];
  isOpenFilter: boolean;
  filter: TContractListFilter;
  page: number;
  count: number;
  total: number;
  totalPage: number;
  isLoading: boolean;
  error: TApiError | null;
}

interface IContractListResponse extends IQueryPage {
  data: IContractListItem[];
  total: number;
}

type TContractListResponse = AxiosResponse<IContractListResponse>;

export const initialContractListFilter: TContractListFilter = {
  _id: '',
  number: '',
  type: { _id: '', name: '' },
  contractor: { _id: '', name: '' },
  building: { _id: '', name: '' },
  startDateAt: '',
  endDateAt: '',
  page: 1,
  count: 30,
};

const initialState: IContractList = {
  data: [],
  isOpenFilter: false,
  filter: initialContractListFilter,
  page: 1,
  totalPage: 1,
  count: 0,
  total: 0,
  isLoading: true,
  error: null,
};

export const getContractListData = createAsyncThunk<
  TContractListResponse,
  TSearchParams,
  { rejectValue: TApiError }
>('contractList/getContractListData', async (params, { rejectWithValue }) => {
  try {
    const res = await server.get('/contract/list', { params });
    return res;
  } catch (err) {
    return rejectWithValue(handleApiError(err));
  }
});

const contractListAdapter = createEntityAdapter();

const contractListSlice = createSlice({
  name: 'contractList',
  initialState: contractListAdapter.getInitialState<IContractList>(initialState),
  reducers: {
    handleOpenFilter: (state) => {
      state.isOpenFilter = !state.isOpenFilter;
    },
    changeFilter: (state, action: PayloadAction<Partial<TContractListFilter>>) => {
      state.filter = { ...state.filter, ...action.payload };
    },
    changeFilterType: (state, action: PayloadAction<IStatus[]>) => {
      const filterType = action.payload.find((item) => item.id === Number(state.filter.type._id));

      if (filterType) {
        state.filter.type = { _id: filterType.id.toString(), name: filterType.name };
      } else {
        state.filter.type = { _id: '', name: '' };
      }
    },
    resetFilter: (state) => {
      state.filter = initialContractListFilter;
    },
    resetContractList: (state) => {
      state.data = [];
      state.isOpenFilter = false;
      state.filter = initialContractListFilter;
      state.page = 1;
      state.totalPage = 1;
      state.count = 0;
      state.total = 0;
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContractListData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getContractListData.fulfilled, (state, action) => {
        const { data, page, count, total } = action.payload.data;

        state.isLoading = false;
        state.data = data;
        state.page = page;
        state.count = count;
        state.total = total;
        state.totalPage = Math.ceil(total / count);
      })
      .addCase(getContractListData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload ?? null;
      });
  },
});

export const contractListSelector = {
  getState: (state: { contractList: IContractList }) => state.contractList,
  getData: (state: { contractList: IContractList }) => state.contractList.data,
  getFilter: (state: { contractList: IContractList }) => state.contractList.filter,
};

export const { handleOpenFilter, changeFilter, changeFilterType, resetFilter, resetContractList } =
  contractListSlice.actions;

export type { TContractListResponse };

export default contractListSlice.reducer;
