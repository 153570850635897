import { useSelector } from 'react-redux';

import { userSelector } from '@shared/store/slices/userSlice';
import { configAppSelector } from '@shared/store/slices/configAppSlice';

export const Model = () => {
  const configApp = useSelector(configAppSelector.getConfig);
  const { isAuth } = useSelector(userSelector.getState);

  return {
    color: configApp.menuBackgroundColor,
    isAuth,
  };
};
