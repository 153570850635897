type TDeclineWord = (number: number, wordList: string[]) => string;

// Example: wordList: ['результат', 'результата', 'результатов']

export const declineWord: TDeclineWord = (number, wordList) => {
  const cases = [2, 0, 1, 1, 1, 2];

  return wordList[
    number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]
  ];
};
