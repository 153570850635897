import { RefObject } from 'react';

import IconProfie from '@shared/assets/icons/icon-profile';

import styles from './index.module.scss';

interface IProps {
  btnRef: RefObject<HTMLButtonElement>;
  changeIsOpen: () => void;
}

export const HeaderProfileBtn = ({ btnRef, changeIsOpen }: IProps) => (
  <button ref={btnRef} type='button' className={styles['btn']} onClick={changeIsOpen}>
    {<IconProfie />}
  </button>
);
