import Popup from '@shared/ui/popup';
import ConfirmActionMessage from '@entities/confirmActionMessage';

import { Model } from '../model';

export const ConfirmActionPopup = () => {
  const { messageRef, isOpen, message, onClick, onClose, handleClickOutSide } = Model();

  return (
    <Popup isOpen={isOpen} clickOutSide={handleClickOutSide}>
      <ConfirmActionMessage
        messageRef={messageRef}
        isOpen={isOpen}
        message={message}
        onClick={onClick}
        onClose={onClose}
      />
    </Popup>
  );
};
