import { useEffect, useState } from 'react';

import api from '../api';

interface IProps {
  isAccess: boolean;
}

export const Model = ({ isAccess }: IProps) => {
  const [isExist, setIsExist] = useState<boolean>(false);

  useEffect(() => {
    if (isAccess) {
      api
        .checkExistClaim()
        .then((res) => {
          setIsExist(res.data.isExist);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return {
    isExist,
  };
};
