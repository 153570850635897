import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import server from '@shared/utils/server';

import { handleApiError } from '@shared/helpers';

import { AxiosResponse } from 'axios';
import { IQueryPage, TSearchParams, IStatusText, TApiError, IStatus } from '@shared/types/global';
import { ISendingListItem, TSendingListFilter } from '@shared/types/interfaces/sending.interface';

interface ISendingList {
  data: ISendingListItem[];
  isOpenFilter: boolean;
  filter: TSendingListFilter;
  page: number;
  count: number;
  total: number;
  totalPage: number;
  isLoading: boolean;
  error: TApiError | null;
}

interface ISendingListResponse extends IQueryPage {
  data: ISendingListItem[];
  total: number;
}

type TSendingListResponse = AxiosResponse<ISendingListResponse>;

export const initialSendingListFilter: TSendingListFilter = {
  _id: '',
  type: { _id: '', name: '' },
  method: { _id: '', name: '' },
  status: { _id: '', name: '' },
  title: '',
  creator: { _id: '', name: '' },
  startDateAt: '',
  endDateAt: '',
  page: 1,
  count: 30,
};

const initialState: ISendingList = {
  data: [],
  isOpenFilter: false,
  filter: initialSendingListFilter,
  page: 1,
  totalPage: 1,
  count: 0,
  total: 0,
  isLoading: true,
  error: null,
};

export const getSendingListData = createAsyncThunk<
  TSendingListResponse,
  TSearchParams,
  { rejectValue: TApiError }
>('sendingList/getSendingListData', async (params, { rejectWithValue }) => {
  try {
    const res = await server.get('/sending/list', { params });
    return res;
  } catch (err) {
    return rejectWithValue(handleApiError(err));
  }
});

const sendingListAdapter = createEntityAdapter();

const sendingListSlice = createSlice({
  name: 'sendingList',
  initialState: sendingListAdapter.getInitialState<ISendingList>(initialState),
  reducers: {
    handleOpenFilter: (state) => {
      state.isOpenFilter = !state.isOpenFilter;
    },
    changeFilter: (state, action: PayloadAction<Partial<TSendingListFilter>>) => {
      state.filter = { ...state.filter, ...action.payload };
    },
    changeFilterMethod: (state, action: PayloadAction<IStatusText[]>) => {
      const filterMethod = action.payload.find(
        (item) => item.id === Number(state.filter.method._id),
      );

      if (filterMethod) {
        state.filter.method = { _id: filterMethod.id.toString(), name: filterMethod.name };
      } else {
        state.filter.method = { _id: '', name: '' };
      }
    },
    changeFilterType: (state, action: PayloadAction<IStatusText[]>) => {
      const filterType = action.payload.find((item) => item.id === Number(state.filter.type._id));

      if (filterType) {
        state.filter.type = { _id: filterType.id.toString(), name: filterType.name };
      } else {
        state.filter.type = { _id: '', name: '' };
      }
    },
    changeFilterStatus: (state, action: PayloadAction<IStatus[]>) => {
      const filterStatus = action.payload.find(
        (item) => item.id === Number(state.filter.status._id),
      );

      if (filterStatus) {
        state.filter.status = { _id: filterStatus.id.toString(), name: filterStatus.name };
      } else {
        state.filter.status = { _id: '', name: '' };
      }
    },
    resetFilter: (state) => {
      state.filter = initialSendingListFilter;
    },
    resetSendingList: (state) => {
      state.data = [];
      state.isOpenFilter = false;
      state.filter = initialSendingListFilter;
      state.page = 1;
      state.totalPage = 1;
      state.count = 0;
      state.total = 0;
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSendingListData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getSendingListData.fulfilled, (state, action) => {
        const { data, page, count, total } = action.payload.data;

        state.isLoading = false;
        state.data = data;
        state.page = page;
        state.count = count;
        state.total = total;
        state.totalPage = Math.ceil(total / count);
      })
      .addCase(getSendingListData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload ?? null;
      });
  },
});

export const sendingListSelector = {
  getState: (state: { sendingList: ISendingList }) => state.sendingList,
  getData: (state: { sendingList: ISendingList }) => state.sendingList.data,
  getFilter: (state: { sendingList: ISendingList }) => state.sendingList.filter,
};

export const {
  handleOpenFilter,
  changeFilter,
  changeFilterMethod,
  changeFilterType,
  changeFilterStatus,
  resetFilter,
  resetSendingList,
} = sendingListSlice.actions;

export type { TSendingListResponse };

export default sendingListSlice.reducer;
