const IconInvoice = () => (
  <svg viewBox='0 0 22 22' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17.875 3.2085L16.5 1.8335L15.125 3.2085L13.75 1.8335L12.375 3.2085L11 1.8335L9.625 3.2085L8.25 1.8335L6.875 3.2085L5.5 1.8335L4.125 3.2085L2.75 1.8335V20.1668L4.125 18.7918L5.5 20.1668L6.875 18.7918L8.25 20.1668L9.625 18.7918L11 20.1668L12.375 18.7918L13.75 20.1668L15.125 18.7918L16.5 20.1668L17.875 18.7918L19.25 20.1668V1.8335L17.875 3.2085ZM17.4167 17.4168H4.58333V4.5835H17.4167V17.4168ZM5.5 13.7502H16.5V15.5835H5.5M5.5 10.0835H16.5V11.9168H5.5M5.5 6.41683H16.5V8.25016H5.5V6.41683Z'
      fill='inherit'
    />
  </svg>
);

export default IconInvoice;
