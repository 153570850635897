import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import server from 'shared/utils/server';

import { handleApiError } from '@shared/helpers';

import { AxiosResponse } from 'axios';
import { IStatus, TApiError } from '@shared/types/global';

interface IInvoiceAncillary {
  statusList: IStatus[];
  isLoading: boolean;
  error: TApiError | null;
}

const initialState: IInvoiceAncillary = {
  statusList: [],
  isLoading: false,
  error: null,
};

export const getInvoiceAncillary = createAsyncThunk<
  AxiosResponse<Pick<IInvoiceAncillary, 'statusList'>>,
  undefined,
  { rejectValue: TApiError }
>('invoiceAncillary/getInvoiceAncillary', async (params, { rejectWithValue }) => {
  try {
    const res = await server.get<Pick<IInvoiceAncillary, 'statusList'>>('/invoice/ancillary', {
      params,
    });
    return res;
  } catch (err) {
    return rejectWithValue(handleApiError(err));
  }
});

const invoiceAncillaryAdapter = createEntityAdapter();

const invoiceAncillarySlice = createSlice({
  name: 'invoiceAncillary',
  initialState: invoiceAncillaryAdapter.getInitialState<IInvoiceAncillary>(initialState),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInvoiceAncillary.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getInvoiceAncillary.fulfilled, (state, action) => {
        const { statusList } = action.payload.data;

        state.isLoading = false;
        state.statusList = statusList;
      })
      .addCase(getInvoiceAncillary.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload ?? null;
      });
  },
});

export const invoiceAncillarySelector = {
  getState: (state: { invoiceAncillary: IInvoiceAncillary }) => state.invoiceAncillary,
};

export default invoiceAncillarySlice.reducer;
