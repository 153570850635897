import { useRef } from 'react';
import { Transition } from 'react-transition-group';

import Section from '@shared/ui/section';
import ScreenSection from '@shared/ui/screenSection';
import Button from '@shared/ui/button';
import GoBack from '@shared/ui/goBack';

import colors from '@shared/assets/colors';

import styles from './index.module.scss';

import { Model } from '../model';

export const NotFound = () => {
  const { isAuth } = Model();

  const elementRef = useRef<HTMLDivElement | null>(null);

  const getErrorBlock = ({ isInner }: { isInner?: boolean }) => (
    <Transition nodeRef={elementRef} appear in timeout={1000} mountOnEnter unmountOnExit>
      {(state) => (
        <div
          ref={elementRef}
          className={`${styles['error__container']} ${styles[`error__container-${state}`]} ${
            isInner && styles['error__container_type_inner']
          }`}
        >
          <p className={styles['error__title']}>404</p>
          <p className={styles['error__subtitle']}>Страница не найдена</p>
          <GoBack>
            <Button color={colors.gray12} text='Вернуться' type='button' minWidth={150} />
          </GoBack>
        </div>
      )}
    </Transition>
  );

  return isAuth ? (
    <Section isBackground>{getErrorBlock({ isInner: true })}</Section>
  ) : (
    <ScreenSection>{getErrorBlock({})}</ScreenSection>
  );
};
