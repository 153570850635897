import { useEffect, useRef, useState } from 'react';
import type { Ref } from 'react';

export const useStickyFromTop = <T extends HTMLElement>(): [Ref<T>, boolean] => {
  const elRef = useRef<T | null>(null);
  const [isSticky, setIsSticky] = useState<boolean>(false);

  useEffect(() => {
    const observe = () => {
      if (!elRef.current) return;

      if (window.scrollY > 0) {
        setIsSticky(true);
        return;
      }

      setIsSticky(false);
    };

    document.addEventListener('scroll', observe);

    return () => {
      document.removeEventListener('scroll', observe);
    };
  }, []);

  return [elRef, isSticky];
};
