import { ReactNode } from 'react';

import { Model } from '../model';

interface IProps {
  roleList: ('director' | 'employee' | 'renter')[];
  children: ReactNode;
}

export const RoleBasedHiddenContent = ({ roleList, children }: IProps) => {
  const { isHidden } = Model({ roleList });

  return !isHidden ? children : null;
};
