export const regexValidation = /^[a-zа-яё_\s]+$/i;
export const regexNumber = /^[0-9]+$/i;

export const requestTimeout = {
  default: 30000,
  long: 120000,
};

export const lineBreak = '\n\n';

export const serverLocalUrl = 'http://localhost:3001';

export const acceptFile =
  '.pdf,.doc,.docx,.xml,.xlsx,.ppt,.pptx,.csv,.svg,.png,.jpg,.jpeg,.ico,.webp,.gif,.tif,.tiff,.zip,.fig,.log,.txt,.psd,.ai,.indd,.u3d,.prc';

export const monthList: Record<number, string> = {
  1: 'январь',
  2: 'февраль',
  3: 'март',
  4: 'апрель',
  5: 'май',
  6: 'июнь',
  7: 'июль',
  8: 'август',
  9: 'сентябрь',
  10: 'октябрь',
  11: 'ноябрь',
  12: 'декабрь',
};
