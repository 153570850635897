const IconCheckNotification = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
    <g clipPath='url(#clip0_17_5333)'>
      <path
        d='M19.5798 4.11719C20.1378 4.60547 20.1378 5.39844 19.5798 5.88672L8.15123 15.8867C7.59319 16.375 6.68694 16.375 6.12891 15.8867L0.414621 10.8867C-0.143415 10.3984 -0.143415 9.60547 0.414621 9.11719C0.972656 8.62891 1.87891 8.62891 2.43694 9.11719L7.1423 13.2305L17.5619 4.11719C18.12 3.62891 19.0262 3.62891 19.5843 4.11719H19.5798Z'
        fill='#55CE63'
      />
    </g>
    <defs>
      <clipPath id='clip0_17_5333'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconCheckNotification;
