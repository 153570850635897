import styles from './index.module.scss';

interface IProps {
  isTableElement?: boolean;
  message: string;
}

export const OverviewDefaultMessage = ({ isTableElement, message }: IProps) =>
  !isTableElement ? (
    <p className={styles['message']}>{message}</p>
  ) : (
    <tr className={styles['message-row']}>
      <td className={styles['message']}>{message}</td>
    </tr>
  );
