import styles from './index.module.scss';

interface IProps {
  message: string;
}

export const WidthPlug = ({ message }: IProps) => (
  <div className={styles['plug']}>
    <p className={styles['plug__message']}>{message}</p>
  </div>
);
