import { ReactNode } from 'react';

import styles from './index.module.scss';

interface IProps {
  children: ReactNode;
}

export const ScreenSection = ({ children }: IProps) => (
  <section className={styles['section']}>{children}</section>
);
