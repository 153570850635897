const IconAppealTypes = () => (
  <svg viewBox='0 0 22 22' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M2.75 6.41683V4.5835H4.58333V3.66683C4.58333 2.64933 5.40833 1.8335 6.41667 1.8335H11.9167V8.25016L14.2083 6.87516L16.5 8.25016V1.8335H17.4167C18.3792 1.8335 19.25 2.70433 19.25 3.66683V18.3335C19.25 19.296 18.3792 20.1668 17.4167 20.1668H6.41667C5.45417 20.1668 4.58333 19.296 4.58333 18.3335V17.4168H2.75V15.5835H4.58333V11.9168H2.75V10.0835H4.58333V6.41683H2.75ZM6.41667 10.0835H4.58333V11.9168H6.41667V10.0835ZM6.41667 6.41683V4.5835H4.58333V6.41683H6.41667ZM6.41667 17.4168V15.5835H4.58333V17.4168H6.41667Z'
      fill='inherit'
    />
  </svg>
);

export default IconAppealTypes;
