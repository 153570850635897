import { RefObject } from 'react';
import { useHover } from '@shared/hooks/useHover';

import styles from './index.module.scss';

import { Model } from '../model';

interface IProps {
  onClick: (() => void) | undefined;
}

export const SidebarRoleBtn = ({ onClick }: IProps) => {
  const { role, color, colorHover } = Model();

  const [btnRef, isHovered] = useHover();

  const getStyle = () =>
    isHovered
      ? {
          color: colorHover,
        }
      : {
          color,
        };

  return (
    <button
      ref={btnRef as RefObject<HTMLButtonElement>}
      className={styles['btn']}
      type='button'
      style={getStyle()}
      onClick={onClick}
    >
      {role}
    </button>
  );
};
