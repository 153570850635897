import Form from '@entities/authentication/form';
import Label from '@entities/authentication/label';
import InputText from '@entities/authentication/inputText';
import Password from '@entities/authentication/password';
import SubmitBtn from '@entities/authentication/submitBtn';
import Cover from '@entities/authentication/cover';
import Error from '@entities/authentication/error';
import ApiError from '@entities/authentication/apiError';

import { getInputError } from '@shared/helpers';

import { Model } from '../model';

export const AuthenticationForm = () => {
  const { isLoading, errors, apiError, register, onSubmit } = Model();

  return (
    <Form onSubmit={onSubmit}>
      <Label id='input-auth-login' title='Логин' />
      <InputText
        id='input-auth-login'
        register={register('login', {
          required: {
            value: true,
            message: getInputError({ type: 'required' }),
          },
          minLength: {
            value: 2,
            message: getInputError({ type: 'minLenght', minLenght: 2 }),
          },
          maxLength: {
            value: 100,
            message: getInputError({ type: 'maxLenght', maxLenght: 100 }),
          },
        })}
        minLength={2}
        maxLength={100}
        isError={!!errors.login}
      />
      {errors.login && <Error message={errors.login.message} />}
      <Label id='input-auth-password' title='Пароль' />
      <Password
        id='input-auth-password'
        register={register('password', {
          required: {
            value: true,
            message: getInputError({ type: 'required' }),
          },
          minLength: {
            value: 2,
            message: getInputError({ type: 'minLenght', minLenght: 2 }),
          },
          maxLength: {
            value: 100,
            message: getInputError({ type: 'maxLenght', maxLenght: 100 }),
          },
        })}
        minLength={2}
        maxLength={100}
        isError={!!errors.password}
      />
      {errors.password && <Error message={errors.password.message} />}
      <ApiError error={apiError} />
      <SubmitBtn />
      <Cover isVisible={isLoading} />
    </Form>
  );
};
