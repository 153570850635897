import { useRef } from 'react';
import { useSelector } from 'react-redux';

import { configAppSelector } from 'shared/store/slices/configAppSlice';

export const Model = () => {
  const formRef = useRef<HTMLFormElement | null>(null);

  const configApp = useSelector(configAppSelector.getConfig);

  return {
    formRef,
    themeColor: configApp.themeColor,
  };
};
