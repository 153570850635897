import Loader from '@shared/ui/circularLoaderMui';

import styles from './index.module.scss';

interface IProps {
  isVisible: boolean;
}

export const AuthenticationCover = ({ isVisible }: IProps) => (
  <div className={`${styles['cover']} ${isVisible && styles['cover_visible']}`}>
    <div className={styles['cover__background']}></div>
    <div className={styles['cover__loader']}>
      <Loader size={60} />
    </div>
  </div>
);
