const IconClose = () => (
  <svg viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M35 8.02143L31.9786 5L20 16.9786L8.02143 5L5 8.02143L16.9786 20L5 31.9786L8.02143 35L20 23.0214L31.9786 35L35 31.9786L23.0214 20L35 8.02143Z'
      fill='inherit'
    />
  </svg>
);

export default IconClose;
