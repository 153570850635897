import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import server from '@shared/utils/server';

import { handleApiError } from '@shared/helpers';

import { AxiosResponse } from 'axios';
import { IQueryPage, TSearchParams, TApiError } from '@shared/types/global';
import { IGroupListItem, TGroupListFilter } from '@shared/types/interfaces/group.interface';

interface IGroupList {
  data: IGroupListItem[];
  isOpenFilter: boolean;
  filter: TGroupListFilter;
  page: number;
  count: number;
  total: number;
  totalPage: number;
  isLoading: boolean;
  error: TApiError | null;
}

interface IGroupListResponse extends IQueryPage {
  data: IGroupListItem[];
  total: number;
}

type TGroupListResponse = AxiosResponse<IGroupListResponse>;

export const initialGroupListFilter: TGroupListFilter = {
  _id: '',
  name: '',
  isActive: { _id: '', name: '' },
  page: 1,
  count: 30,
};

const initialState: IGroupList = {
  data: [],
  isOpenFilter: false,
  filter: initialGroupListFilter,
  page: 1,
  totalPage: 1,
  count: 0,
  total: 0,
  isLoading: true,
  error: null,
};

export const getGroupListData = createAsyncThunk<
  TGroupListResponse,
  TSearchParams,
  { rejectValue: TApiError }
>('groupList/getGroupListData', async (params, { rejectWithValue }) => {
  try {
    const res = await server.get('/group/list', { params });
    return res;
  } catch (err) {
    return rejectWithValue(handleApiError(err));
  }
});

const groupListAdapter = createEntityAdapter();

const groupListSlice = createSlice({
  name: 'groupList',
  initialState: groupListAdapter.getInitialState<IGroupList>(initialState),
  reducers: {
    handleOpenFilter: (state) => {
      state.isOpenFilter = !state.isOpenFilter;
    },
    changeFilter: (state, action: PayloadAction<Partial<TGroupListFilter>>) => {
      state.filter = { ...state.filter, ...action.payload };
    },
    resetFilter: (state) => {
      state.filter = initialGroupListFilter;
    },
    resetGroupList: (state) => {
      state.data = [];
      state.isOpenFilter = false;
      state.filter = initialGroupListFilter;
      state.page = 1;
      state.totalPage = 1;
      state.count = 0;
      state.total = 0;
      state.isLoading = false;
      state.error = null;
    },
    resetGroupListItem: (state, action: PayloadAction<string>) => {
      state.data = state.data.filter((item) => item._id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGroupListData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getGroupListData.fulfilled, (state, action) => {
        const { data, page, count, total } = action.payload.data;

        state.isLoading = false;
        state.data = data;
        state.page = page;
        state.count = count;
        state.total = total;
        state.totalPage = Math.ceil(total / count);
      })
      .addCase(getGroupListData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload ?? null;
      });
  },
});

export const groupListSelector = {
  getState: (state: { groupList: IGroupList }) => state.groupList,
  getData: (state: { groupList: IGroupList }) => state.groupList.data,
  getFilter: (state: { groupList: IGroupList }) => state.groupList.filter,
};

export const { handleOpenFilter, changeFilter, resetFilter, resetGroupList, resetGroupListItem } =
  groupListSlice.actions;

export type { TGroupListResponse };

export default groupListSlice.reducer;
