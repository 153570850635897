import { CircularProgress } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { Model } from '../model';

interface IProps {
  size: number;
}

export const CircularLoaderMui = ({ size }: IProps) => {
  const { color } = Model();

  const theme = createTheme({
    palette: {
      success: {
        main: color,
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CircularProgress color='success' size={size} />
    </ThemeProvider>
  );
};
