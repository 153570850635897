type TGetDate = (dateString: Date | string, dataOnly?: boolean) => string | null;

export const getDate: TGetDate = (dateString, dataOnly) => {
  const date = new Date(dateString);

  if (Number.isNaN(date.getDate())) {
    return null;
  }

  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const year = date.getFullYear();

  if (dataOnly) {
    return `${day}.${month}.${year}`;
  }

  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${day}.${month}.${year} ${hours < 10 ? `0${hours}` : hours}:${
    minutes < 10 ? `0${minutes}` : minutes
  }`;
};
