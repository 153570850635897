const IconAlert = () => (
  <svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_2315_57442)'>
      <path
        d='M11.1111 11.1111H8.88889V4.44444H11.1111M8.88889 13.3333H11.1111V15.5556H8.88889M14.1444 0H5.85556L0 5.85556V14.1444L5.85556 20H14.1444L20 14.1444V5.85556L14.1444 0Z'
        fill='inherit'
      />
    </g>
    <defs>
      <clipPath id='clip0_2315_57442'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconAlert;
