import axios from 'axios';

import { requestTimeout, serverLocalUrl } from './contants';

const isProduction = process.env.NODE_ENV === 'production';
const serverProduction = process.env.SERVER_URL ?? serverLocalUrl;

export const serverUrl = isProduction ? serverProduction : serverLocalUrl;

const server = axios.create({
  baseURL: serverUrl,
  timeout: requestTimeout['default'],
  withCredentials: true,
  headers: {
    'content-type': 'application/json',
  },
});

export default server;
