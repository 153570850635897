import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const isButtonDisabledSlice = createSlice({
  name: 'isButtonDisabled',
  initialState: {
    value: false,
  },
  reducers: {
    handleIsButtonDisabled: (state, action: PayloadAction<boolean>) => {
      state.value = action.payload;
    },
  },
});

export const IsButtonDisabledSelector = {
  getState: (state: { isButtonDisabled: { value: boolean } }) => state.isButtonDisabled.value,
};

export const { handleIsButtonDisabled } = isButtonDisabledSlice.actions;

export default isButtonDisabledSlice.reducer;
