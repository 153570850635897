import { MutableRefObject } from 'react';
import { Transition } from 'react-transition-group';

import Button from '@shared/ui/button';
import IconWarning from '@shared/assets/icons/icon-warning';

import colors from '@shared/assets/colors';

import styles from './index.module.scss';

interface IProps {
  messageRef: MutableRefObject<HTMLDivElement | null>;
  isOpen: boolean;
  message: string;
  onClick: () => void;
  onClose: () => void;
}

export const ConfirmActionMessage = ({ messageRef, isOpen, message, onClick, onClose }: IProps) => (
  <Transition nodeRef={messageRef} in={isOpen} timeout={400} mountOnEnter unmountOnExit>
    {(state) => (
      <div ref={messageRef} className={`${styles['message']} ${styles[`message-${state}`]}`}>
        <div className={styles['message__header']}>
          <div className={styles['message__icon']}>
            <IconWarning />
          </div>
          <p className={styles['message__title']}>Внимание</p>
        </div>
        <div className={styles['message__body']}>
          {message ? <p className={styles['message__text']}>{message}</p> : null}
          <p className={styles['message__text']}>Вы хотите продолжить?</p>
          <div className={styles['message__btns']}>
            <Button color={colors.green} text='Да' type='button' onClick={onClick} minWidth={190} />
            <Button
              color={colors.gray12}
              text='Нет'
              type='button'
              onClick={onClose}
              minWidth={190}
            />
          </div>
        </div>
      </div>
    )}
  </Transition>
);
