import { FormEvent, ReactNode } from 'react';
import { Transition } from 'react-transition-group';

import styles from './index.module.scss';

import { Model } from '../model';

interface IProps {
  onSubmit: (evt: FormEvent) => void;
  children: ReactNode;
}

export const AuthenticationForm = ({ onSubmit, children }: IProps) => {
  const { formRef, themeColor } = Model();

  return (
    <Transition nodeRef={formRef} appear in timeout={400} mountOnEnter unmountOnExit>
      {(state) => (
        <form
          className={`${styles['form']} ${styles[`form-${state}`]}`}
          onSubmit={onSubmit}
          noValidate
        >
          <div className={styles['form__container']}>
            <h1 className={styles['form__title']} style={{ borderColor: themeColor }}>
              Авторизация
            </h1>
            {children}
          </div>
        </form>
      )}
    </Transition>
  );
};
