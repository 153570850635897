const IconNavArrowLeft = () => (
  <svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M19.2625 20.725L13.5375 15L19.2625 9.2625L17.5 7.5L10 15L17.5 22.5L19.2625 20.725Z'
      fill='inherit'
    />
  </svg>
);

export default IconNavArrowLeft;
