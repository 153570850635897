import { useDispatch, useSelector } from 'react-redux';
import { ReactNode, useEffect } from 'react';
import { useWindowSize } from '@shared/hooks/useWindowSize';
import { TThunkDispatch } from '@shared/store';

import Plug from '@shared/ui/plug';

import { configAppSelector, getConfigAppData } from '@shared/store/slices/configAppSlice';

interface IProps {
  children: ReactNode;
}

const ConfigApp = ({ children }: IProps) => {
  const dispatch = useDispatch<TThunkDispatch>();

  const { width } = useWindowSize();

  const { config, isLoading } = useSelector(configAppSelector.getState);

  const getContent = () => {
    if (width < 1000) {
      return <Plug message='Извините, но данная ширина экрана не поддерживается' />;
    }

    if (isLoading) {
      return <Plug message='' />;
    }

    if (!config.resources.length) {
      return <Plug message='Cервис недоступен' />;
    }

    return children;
  };

  useEffect(() => {
    dispatch(getConfigAppData());
  }, [dispatch]);

  return getContent();
};

export default ConfigApp;
