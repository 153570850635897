import { IStatus, IEntity, IStatusText } from '@shared/types/global';

type TFitStatusList = (data: IStatus[] | IStatusText[] | undefined) => IEntity[];

export const fitStatusList: TFitStatusList = (data) => {
  if (data === undefined) {
    return [];
  }

  const result: IEntity[] = data.map((item) => Object({ _id: item.id, name: item.name }));

  return result;
};
