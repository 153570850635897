const IconAppeal = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 22'>
    <path
      d='M9.16683 3.66699C10.1393 3.66699 11.0719 4.0533 11.7596 4.74093C12.4472 5.42857 12.8335 6.3612 12.8335 7.33366C12.8335 8.30612 12.4472 9.23875 11.7596 9.92638C11.0719 10.614 10.1393 11.0003 9.16683 11.0003C8.19437 11.0003 7.26174 10.614 6.5741 9.92638C5.88647 9.23875 5.50016 8.30612 5.50016 7.33366C5.50016 6.3612 5.88647 5.42857 6.5741 4.74093C7.26174 4.0533 8.19437 3.66699 9.16683 3.66699ZM9.16683 12.8337C13.2185 12.8337 16.5002 14.4745 16.5002 16.5003V18.3337H1.8335V16.5003C1.8335 14.4745 5.11516 12.8337 9.16683 12.8337ZM18.3335 11.0003V6.41699H20.1668V11.917H18.3335M18.3335 15.5837V13.7503H20.1668V15.5837H18.3335Z'
      fill='inherit'
    />
  </svg>
);

export default IconAppeal;
