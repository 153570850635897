import { useEffect, useState } from 'react';

import { IPlacementOverviewData } from '@shared/types/interfaces/placement.interface';

import api from '../api';

export const Model = () => {
  const [placementOverviewList, setPlacementOverviewList] = useState<
    Record<string, IPlacementOverviewData>
  >({
    warehouse: {
      type: 2,
      inUse: 0,
      isFree: 0,
    },
    office: {
      type: 1,
      inUse: 0,
      isFree: 0,
    },
    mezzanine: {
      type: 4,
      inUse: 0,
      isFree: 0,
    },
    shop: {
      type: 3,
      inUse: 0,
      isFree: 0,
    },
    auxiliary: {
      type: 5,
      inUse: 0,
      isFree: 0,
    },
  });

  const translateData = (data: IPlacementOverviewData[]) => {
    const result: Record<string, IPlacementOverviewData> = {};

    data.forEach((item) => {
      if (item.type === 1) {
        result.office = item;
      }

      if (item.type === 2) {
        result.warehouse = item;
      }

      if (item.type === 3) {
        result.shop = item;
      }

      if (item.type === 4) {
        result.mezzanine = item;
      }

      if (item.type === 5) {
        result.auxiliary = item;
      }
    });

    return result;
  };

  useEffect(() => {
    api
      .getPlacementCounts()
      .then((res) => setPlacementOverviewList(translateData(res.data)))
      .catch((err) => console.log(err));
  }, []);

  return {
    placementOverviewList,
  };
};
