import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { userSelector } from '@shared/store/slices/userSlice';
import { configAppSelector } from '@shared/store/slices/configAppSlice';

interface IProps {
  changeIsOpen: () => void;
}

export const Model = ({ changeIsOpen }: IProps) => {
  const location = useLocation();
  const navagate = useNavigate();

  const { user } = useSelector(userSelector.getState);
  const configApp = useSelector(configAppSelector.getConfig);

  const name = user.name.split(' ').slice(0, 2).join(' ');

  const navigateToProfilePage = () => {
    if (location.pathname !== '/profile') {
      navagate('/profile');
    }

    changeIsOpen();
  };

  const navigateToContractor = () => {
    const { contractor } = user;

    if (contractor) {
      navagate(`/contractor/${contractor._id}`);
    }

    changeIsOpen();
  };

  return {
    name,
    position: user.position,
    iconColor: configApp.themeColor,
    isShowContractorBtn: user.role.code === 'renter',
    navigateToProfilePage,
    navigateToContractor,
  };
};
