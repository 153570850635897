import { useSelector } from 'react-redux';

import { configAppSelector } from '@shared/store/slices/configAppSlice';
import { useEffect, useState } from 'react';

export const Model = () => {
  const configApp = useSelector(configAppSelector.getConfig);

  const [text, setText] = useState<string>('');

  useEffect(() => {
    if (text.length < configApp.copyright.length) {
      const timer = setTimeout(() => {
        setText((text) => text + configApp.copyright.charAt(text.length));
      }, 15);

      return () => clearTimeout(timer);
    }
  }, [configApp.copyright, text.length]);

  return {
    copyright: text,
  };
};
