import { ReactNode, RefObject, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import { useHover } from '@shared/hooks/useHover';

import IconWarning from '@shared/assets/icons/icon-alert';

import styles from './index.module.scss';

import { Model } from '../model';

interface IProps {
  title: string;
  link: string;
  isAccess: boolean;
  count?: number;
  isAlert?: boolean;
  children: ReactNode;
}

export const SidebarMenuItem = ({ title, link, isAccess, count, isAlert, children }: IProps) => {
  const { isActive, textColor, activeColor } = Model({ link });

  const [linkRef, isHovered] = useHover();

  const elementRef = useRef<HTMLParagraphElement | HTMLDivElement | null>(null);

  const getStyleLink = () =>
    isActive || isHovered
      ? {
          borderColor: activeColor,
          fill: activeColor,
          color: activeColor,
        }
      : {
          borderColor: 'transparent',
          fill: textColor,
          color: textColor,
        };

  const countMemo = useMemo(() => {
    if (!count) {
      return 0;
    }

    return count > 999 ? '999+' : count;
  }, [count]);

  return isAccess ? (
    <Link
      to={link}
      ref={linkRef as RefObject<HTMLAnchorElement>}
      className={styles['item']}
      style={getStyleLink()}
    >
      <div className={styles['item__icon']}>{children}</div>
      <p className={styles['item__title']}>{title}</p>
      {count !== undefined ? (
        <Transition nodeRef={elementRef} appear in={!!count} timeout={1000} mountOnEnter>
          {(state) => (
            <p
              ref={elementRef}
              className={`${styles['item__count']} ${styles[`item__count-${state}`]}`}
            >
              {countMemo}
            </p>
          )}
        </Transition>
      ) : null}
      {isAlert !== undefined ? (
        <Transition nodeRef={elementRef} appear in={isAlert} timeout={1000} mountOnEnter>
          {(state) => (
            <div
              ref={elementRef}
              className={`${styles['item__icon-alert']} ${styles[`item__icon-alert-${state}`]}`}
            >
              <IconWarning />
            </div>
          )}
        </Transition>
      ) : null}
    </Link>
  ) : null;
};
