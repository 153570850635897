import { useStickyFromTop } from '@shared/hooks/useSticky';
import { ReactNode } from 'react';

import LinkLogo from '@features/link-logo';

import styles from './index.module.scss';

import { Model } from '../model';

interface IProps {
  children: ReactNode;
}

export const Header = ({ children }: IProps) => {
  const { backgroundColor } = Model();

  const [refHeader, isSticky] = useStickyFromTop();

  return (
    <header
      ref={refHeader}
      className={`${styles['header']} ${isSticky && styles['header_type_sticky']}`}
    >
      <LinkLogo />
      <div className={styles['header__container']} style={{ background: backgroundColor }}>
        {children}
      </div>
    </header>
  );
};
