import { ReactNode } from 'react';

import styles from './index.module.scss';

interface IProps {
  children: ReactNode;
}

export const SidebarNavigation = ({ children }: IProps) => (
  <nav className={styles['navigation']}>
    <div className={styles['navigation__wrapper']}>{children}</div>
  </nav>
);
