import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';
import { TThunkDispatch } from '@shared/store';

import {
  getInvoicePeriodListData,
  invoicePeriodListSelector,
  changeFilter,
} from '@shared/store/slices/invoicePeriodListSlice';
import { openErrorPopup } from '@shared/store/slices/errorPopupSlice';
import { userSelector } from '@shared/store/slices/userSlice';

import { filterSearchParams, getDefaultStatusText } from '@shared/helpers';

import { TApiError } from '@shared/types/global';

export const Model = () => {
  const dispatch = useDispatch<TThunkDispatch>();

  const chartRef = useRef<HTMLDivElement | null>(null);

  const { data, periodList, filter, isLoading, isLoadingPeriod, slider } = useSelector(
    invoicePeriodListSelector.getState,
  );
  const userContractor = useSelector(userSelector.getContractor);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (chartRef.current && isLoading) {
        const maxElementChartVisible = Math.floor(
          chartRef.current.getBoundingClientRect().width / 120,
        );

        const limit = maxElementChartVisible > 12 ? 12 : maxElementChartVisible;

        const params = filterSearchParams({
          ...filter,
          contractor: userContractor?._id || filter.contractor,
          limit,
        });

        dispatch(
          changeFilter({ ...filter, contractor: userContractor || filter.contractor, limit }),
        );

        dispatch(getInvoicePeriodListData(params)).then((res) => {
          if (res.meta.requestStatus === 'rejected') {
            dispatch(
              openErrorPopup({
                message: getDefaultStatusText((res.payload as TApiError).status),
                isGoToMain: true,
              }),
            );
          }
        });
      }
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return {
    chartRef,
    data,
    periodList,
    isLoading,
    isLoadingPeriod,
    slider,
  };
};
