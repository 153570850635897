import { IFileConvert } from '@shared/types/interfaces/file.interface';

export const checkFileSize = (fileList: IFileConvert[][], maxSizeMb: number) => {
  const totalSize =
    fileList.reduce(
      (sum, currentList) => sum + currentList.reduce((prev, current) => prev + current.size, 0),
      0,
    ) /
    1024 /
    1024;

  return new Promise((resolve, reject) => {
    if (totalSize > maxSizeMb) {
      reject(
        new Error(`Превышен общий размер файлов ${totalSize.toFixed(0)} / ${maxSizeMb} мегабайт.`),
      );
    }

    resolve(undefined);
  });
};
