import { Children, ReactElement, cloneElement } from 'react';

import { Model } from '../model';

interface IProps {
  children: ReactElement;
}

export const GoBack = ({ children }: IProps) => {
  const { goBack } = Model();

  return cloneElement(Children.only(children), { onClick: goBack });
};
