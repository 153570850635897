import Form from '@entities/overview/invoicePeriodForm';
import InputPeriod from '@entities/input/period';

import { Model } from '../model';

export const OverviewInvoicePeriodForm = () => {
  const {
    slider,
    startDateAtWatch,
    endDateAtWatch,
    handleChartLeft,
    saveStartDateAt,
    saveEndDateAt,
    resetDates,
    handleChartRight,
  } = Model();

  return (
    <Form slider={slider} onChartLeft={handleChartLeft} onChartRight={handleChartRight}>
      <InputPeriod
        startId='input-overview-invoice-start-date'
        endId='input-overview-invoice-end-date'
        startValue={startDateAtWatch}
        endValue={endDateAtWatch}
        isColorGray
        isCloseBtnPositionLeft
        selectStart={saveStartDateAt}
        selectEnd={saveEndDateAt}
        resetValues={resetDates}
      />
    </Form>
  );
};
