import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Decimal from 'decimal.js';

import { IOverviewInvoiceChartColumnProps } from '@shared/types/global';

import colors from '@shared/assets/colors';

const maxHeight = 244;
const detailHeight = 45;

interface IProps {
  chartRef: MutableRefObject<HTMLDivElement | null>;
  data: IOverviewInvoiceChartColumnProps['data'];
  maxSum: number;
}

export const Model = ({ chartRef, data, maxSum }: IProps) => {
  const columnRef = useRef<HTMLDivElement | null>(null);
  const detailRef = useRef<HTMLDivElement | null>(null);

  const [isDetailVisible, setIsDetailVisible] = useState<boolean>(false);
  const [detailPosition, setDetailPosition] = useState<
    | {
        right: number | undefined;
        bottom: number | undefined;
        left: number | undefined;
      }
    | undefined
  >(undefined);

  const { height, columnPartList } = useMemo(() => {
    let columnSum: Decimal = new Decimal(0);
    const columnPartList: {
      status: number;
      height: number;
      backgroundColor: string;
      sum: string;
    }[] = [];

    data.data.forEach((item) => {
      columnSum = columnSum.plus(item.sum);
    });

    const columnHeight = columnSum.dividedBy(maxSum).mul(maxHeight).toNumber();

    data.data.forEach((item) => {
      if (item.status === 1 || item.status === 2) {
        const statusSum = new Decimal(item.sum).minus(item.sumRepayment);

        columnPartList.push({
          status: item.status,
          height: statusSum.dividedBy(columnSum).mul(columnHeight).toNumber(),
          backgroundColor: item.status === 1 ? colors.red2 : colors.orange,
          sum: new Intl.NumberFormat('ru-RU', {
            style: 'currency',
            minimumFractionDigits: 2,
            currency: 'RUB',
          })
            .format(statusSum.toNumber())
            .toString(),
        });
      }
    });

    return {
      height: columnHeight,
      columnPartList: columnPartList.sort((a, b) => a.status - b.status),
    };
  }, [data, maxSum]);

  const handleDetailVisible = () => {
    let right: number | undefined;
    let bottom: number | undefined;
    let left: number | undefined;

    if (height < detailHeight) {
      bottom = 5;
    } else {
      bottom = height - detailHeight;
    }

    const chartInfo = chartRef.current?.getBoundingClientRect();
    const columnInfo = columnRef.current?.getBoundingClientRect();
    const detailInfo = detailRef.current?.getBoundingClientRect();

    if (!chartInfo || !columnInfo || !detailInfo) {
      return;
    }

    const itemWidth = columnInfo.width + 60;

    const rightChartBorder = chartInfo.x + chartInfo.width;
    const rightDetailBorder = columnInfo.x + itemWidth + detailInfo.width + 10 - 20;

    if (rightDetailBorder > rightChartBorder) {
      right = itemWidth - 20;
    } else {
      left = itemWidth - 20;
    }

    setDetailPosition({ right, bottom, left });
    setIsDetailVisible(true);
  };

  const handleCloseOutside = useCallback((evt: MouseEvent) => {
    if (!detailRef.current?.contains(evt.target as Node)) {
      setIsDetailVisible(false);
    }
  }, []);

  useEffect(() => {
    if (isDetailVisible) {
      document.addEventListener('click', handleCloseOutside, true);
    } else {
      document.removeEventListener('click', handleCloseOutside, true);
    }

    return () => {
      document.removeEventListener('click', handleCloseOutside, true);
    };
  }, [isDetailVisible, handleCloseOutside]);

  return {
    columnRef,
    detailRef,
    isDetailVisible,
    detailPosition,
    height,
    columnPartList,
    handleDetailVisible,
  };
};
