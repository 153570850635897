import server from '@shared/utils/server';

import { AxiosPromise } from 'axios';
import { IInvoiceListItem } from '@shared/types/interfaces/invoice.interface';

const getLastInvoiceList = (): AxiosPromise<{
  data: IInvoiceListItem[];
  page: number;
  count: number;
  total: number;
}> =>
  server.get('/invoice/list', {
    params: {
      page: 1,
      count: 5,
    },
  });

export default { getLastInvoiceList };
