import { useSelector } from 'react-redux';

import { IsButtonDisabledSelector } from '@shared/store/slices/isButtonDisabledSlice';

export const Model = () => {
  const isDisabled = useSelector(IsButtonDisabledSelector.getState);

  return {
    isDisabled,
  };
};
