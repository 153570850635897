import Section from '@entities/overview/invoicePeriodSection';
import Header from '@entities/overview/invoicePeriodHeader';
import Dept from '@entities/overview/invoicePeriodDept';
import Chart from '@entities/overview/invoicePeriodChart';
import ChartColumn from '@entities/overview/invoicePeriodChartColumn';
import Form from '@features/overview-invoice-period-form';

import { Model } from '../model';

export const OverviewInvoicePeriod = () => {
  const { chartRef, data, periodList, isLoading, isLoadingPeriod, slider } = Model();

  return (
    <Section isLoading={isLoading}>
      <Header>
        <>
          <Dept data={data} />
          <Form />
        </>
      </Header>
      <Chart
        chartRef={chartRef}
        isLoading={isLoadingPeriod}
        periodList={periodList}
        translateStep={slider.step}
        ChartColumn={ChartColumn}
      />
    </Section>
  );
};
