import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import server from 'shared/utils/server';

import { handleApiError } from '@shared/helpers';

import { AxiosResponse } from 'axios';
import { IStatus, TApiError } from '@shared/types/global';

interface IAppealAncillary {
  statusList: IStatus[];
  isLoading: boolean;
  error: TApiError | null;
}

const initialState: IAppealAncillary = {
  statusList: [],
  isLoading: false,
  error: null,
};

export const getAppealAncillary = createAsyncThunk<
  AxiosResponse<Pick<IAppealAncillary, 'statusList'>>,
  undefined,
  { rejectValue: TApiError }
>('appealAncillary/getAppealAncillary', async (params, { rejectWithValue }) => {
  try {
    const res = await server.get<Pick<IAppealAncillary, 'statusList'>>('/appeal/ancillary', {
      params,
    });
    return res;
  } catch (err) {
    return rejectWithValue(handleApiError(err));
  }
});

const appealAncillaryAdapter = createEntityAdapter();

const appealAncillarySlice = createSlice({
  name: 'appealAncillary',
  initialState: appealAncillaryAdapter.getInitialState<IAppealAncillary>(initialState),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAppealAncillary.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAppealAncillary.fulfilled, (state, action) => {
        const { statusList } = action.payload.data;

        state.isLoading = false;
        state.statusList = statusList;
      })
      .addCase(getAppealAncillary.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload ?? null;
      });
  },
});

export const appealAncillarySelector = {
  getState: (state: { appealAncillary: IAppealAncillary }) => state.appealAncillary,
};

export default appealAncillarySlice.reducer;
