import { useEffect } from 'react';

interface IProps {
  isOpen: boolean;
  clickOutSide?: ((evt: MouseEvent) => void) | undefined;
}

export const Model = ({ isOpen, clickOutSide }: IProps) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = '8px';

      if (clickOutSide) {
        document.addEventListener('click', clickOutSide, true);
      }
    } else {
      document.body.style.overflow = '';
      document.body.style.paddingRight = '0';

      if (clickOutSide) {
        document.removeEventListener('click', clickOutSide, true);
      }
    }

    return () => {
      document.body.style.overflow = '';
      document.body.style.paddingRight = '0';

      if (clickOutSide) {
        document.removeEventListener('click', clickOutSide, true);
      }
    };
  }, [isOpen]);
};
