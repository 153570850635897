import { ReactNode } from 'react';

import styles from './index.module.scss';

interface IProps {
  isFullScreen: boolean;
  children: ReactNode;
}

export const Main = ({ isFullScreen, children }: IProps) => (
  <main className={`${styles['main']} ${!isFullScreen && styles['main_type_aside']}`}>
    {children}
  </main>
);
